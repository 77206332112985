import { faBars, faCommentSlash, faEnvelope, faExpand, faPrint } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { useArticle, useCategoryTree, useFullscreen, useProjectConfig } from '../ContextProviders/AppContext';
import { useStyle } from '../ContextProviders/Style';
import { Action, Fab } from './Fab';
import { useLocalization } from '../ContextProviders/LocalizationContext';

export const FabButtonCategory = ({ id }: { id: string }): ReactElement => {
  const tree = useCategoryTree(id);

  const printAllArticles = () => {
    let allContent = `<style> 
        * { color: black; } 
        .article-area { max-width: 21cm; margin-inline: auto; }
        a[data-anchor-link] { display: none; }  
        .pagebreak { page-break-before: always; } 
        .heading { text-align: center; padding-bottom: 0.5rem; border-bottom: 2px solid black }
        @page { margin: 1.25cm; }
      </style>`;

    if (!tree) return;

    const allArticlesOrdered = [
      ...tree.sections.reduce((acc, section) => {
        return [...acc, ...section.articles];
      }, []),
      ...tree.defaultSection,
    ];

    allArticlesOrdered.forEach((b, index) => {
      allContent += `<div class="article-area">
      <h1 class="heading">${b.name}</h1>
      ${b.content}
      </div>
      ${index === allArticlesOrdered.length - 1 ? '' : '<div class="pagebreak"></div>'}
      `;
    });
    const theTitle = tree.name;
    const win = window.open();
    if (!win) return;

    win.document.title = theTitle || '';
    win.document.body.innerHTML = allContent;
    void Promise.all(
      Array.from(win.document.images).map((img) => {
        if (img.complete) return Promise.resolve(img.naturalHeight !== 0);
        return new Promise((resolve) => {
          img.addEventListener('load', () => resolve(true));
          img.addEventListener('error', () => resolve(false));
        });
      }),
    ).then(() => {
      win.requestAnimationFrame(() => {
        win.requestAnimationFrame(() => {
          win.print();
        });
      });
    });
  };

  return <FabButton id={id} onPrint={printAllArticles} />;
};

export const FabButtonArticle = ({ id }: { id: string }): ReactElement => {
  const article = useArticle(id);

  const onPrint = (): void => {
    if (!article) return;

    const theTitle = `${document.title} (${window.location.host})`;
    const win = window.open();
    if (!win) return;

    win.document.title = theTitle;
    win.document.body.innerHTML = `<style> 
    * { color: black; } 
    .article-area { max-width: 21cm; margin-inline: auto; }
    a[data-anchor-link] { display: none; } 
    @page { margin: 1.25cm; }
    </style>
    <div class="article-area">${article.content}</div>`;

    void Promise.all(
      Array.from(win.document.images).map((img) => {
        if (img.complete) return Promise.resolve(img.naturalHeight !== 0);
        return new Promise((resolve) => {
          img.addEventListener('load', () => resolve(true));
          img.addEventListener('error', () => resolve(false));
        });
      }),
    ).then(() => {
      win.requestAnimationFrame(() => {
        win.requestAnimationFrame(() => {
          win.print();
        });
      });
    });
  };

  return <FabButton id={id} onPrint={onPrint} />;
};

export const FabButton = ({ id, onPrint }: { id: string; onPrint?: () => void }): ReactElement => {
  const localization = useLocalization();
  const article = useArticle(id);
  const config = useProjectConfig();

  const style = useStyle();
  const [fs, setFs] = useFullscreen();
  if (config.loading || config.error) {
    return <></>;
  }

  const generateMailTo = (): void => {
    const recipients = config.doc.feedbackEmails.join(',');
    const subject =
      config.doc.feedbackEmailData && config.doc.feedbackEmailData.subject
        ? config.doc.feedbackEmailData.subject
        : `${config.doc.name}-${localization.strings.global.feedbackSubject}`;
    const body =
      config.doc.feedbackEmailData && config.doc.feedbackEmailData.body ? config.doc.feedbackEmailData.body : '';
    const extendedBody = article ? `${body ? body + '\n' : ''}${article?.name}\nLink: ${window.location.href}` : body;

    let emailUrl = `mailto:${recipients}`;
    const query = new URLSearchParams({
      subject: subject,
      body: extendedBody,
    }).toString();
    if (query) {
      emailUrl += `?${query}`;
    }
    window.open(emailUrl, '_blank');
  };

  const toggleFullscreen = (): void => {
    setFs(!fs);
  };

  return (
    <div data-toggle="tooltip" data-placement="left" title={localization.strings.global.feedback.feedbackAndPrint}>
      <Fab
        mainButtonStyles={{
          backgroundColor: `${style.primaryLight}`,
          color: style.primaryDark,
        }}
        position={{ bottom: '15px', right: '35px' }}
        icon={<FontAwesomeIcon color={style.primaryDark} icon={faBars} />}
        event="hover"
      >
        {config.doc.feedbackEmails ? (
          <Action
            style={{
              backgroundColor: `${style.primaryLight}`,
              color: style.primaryDark,
            }}
            data-toggle="tooltip"
            data-placement="left"
            title={localization.strings.global.feedback.send}
            onClick={generateMailTo}
          >
            <FontAwesomeIcon color={style.primaryDark} icon={faEnvelope} />
          </Action>
        ) : (
          <Action
            style={{
              backgroundColor: `${style.primaryLight}`,
              color: style.primaryDark,
            }}
            text={localization.strings.global.feedback.noFeedbackEmailExist}
          >
            <FontAwesomeIcon color={style.primaryDark} icon={faCommentSlash} />
          </Action>
        )}

        {onPrint && (
          <Action
            style={{ backgroundColor: `${style.primaryLight}`, color: style.primaryDark }}
            data-toggle="tooltip"
            data-placement="left"
            title={localization.strings.global.print}
            onClick={onPrint}
          >
            <FontAwesomeIcon color={style.primaryDark} icon={faPrint} />
          </Action>
        )}

        <Action
          style={{ backgroundColor: `${style.primaryLight}`, color: style.primaryDark }}
          data-toggle="tooltip"
          data-placement="left"
          title={localization.strings.global.fullscreen}
          onClick={toggleFullscreen}
        >
          <FontAwesomeIcon color={style.primaryDark} icon={faExpand} />
        </Action>
      </Fab>
    </div>
  );
};
