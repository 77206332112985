import { ReactElement, useEffect, useRef, useState } from 'react';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useArticlesDraft, useArticlesValue, useProjectConfig } from '../../ContextProviders/AppContext';
import { Loading } from '../Loading/Loading';
import { IconButton } from '../../Buttons/Buttons';
import { faAngleDown, faAngleUp, faCopy, faEdit, faSearch } from '@fortawesome/pro-solid-svg-icons';
import './ProjectValues.scss';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { useFirestore } from '../../ContextProviders/Firebase';
import { useConcreteProject, useProjectTitle } from '../../ContextProviders/ProjectContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toasts } from '../../../shared';
import { useAuth } from '../../ContextProviders/Auth';
import { articleValueActions, tagActions } from '../../../Hooks/DatabaseActions';
import { Article } from '../../../Types';

interface Tag {
  name: string;
  value: string;
}

type Order = 'asc' | 'desc';
type Attribute = 'fId' | 'name';
type SortOptions = {
  order: Order;
  attribute: Attribute;
};

type SortButtonProps = {
  attribute: Attribute;
  disabled: boolean;
  sortOptions: SortOptions;
  handleClick: (attribute: Attribute) => void;
};

const SortButton = ({ attribute, disabled, sortOptions, handleClick }: SortButtonProps): ReactElement => {
  const localization = useLocalization();
  const { sorting } = localization.strings.settings.projectValues;
  const title = sorting[attribute];

  return (
    <Col onClick={() => handleClick(attribute)} className="sort-button" disabled={disabled}>
      {title}
      {!disabled && (
        <FontAwesomeIcon
          icon={sortOptions.attribute === attribute && sortOptions.order === 'desc' ? faAngleUp : faAngleDown}
          className={` mx-1 sort-indicator ${sortOptions.attribute === attribute && 'active'}`}
        ></FontAwesomeIcon>
      )}
    </Col>
  );
};

export const ProjectValuesManager = (): ReactElement => {
  const localization = useLocalization();
  const projectConfig = useProjectConfig();
  const title = useProjectTitle();
  const [articles, setArticles] = useState(useArticlesDraft().docs);
  const [checkAll, setCheckAll] = useState(true);
  const [checkedArticles, setCheckedArticles] = useState<Article[]>([...articles]);
  const [sortedArticles, setSortedArticles] = useState<Article[]>([...articles]);
  const [modalOpen, setModalOpen] = useState(false);
  const [singleArticle, setSingleArticle] = useState<Article | null>(null);
  const firestore = useFirestore();
  const auth = useAuth();
  const project = useConcreteProject();
  const [hasDuplicateName, setDuplicateName] = useState<boolean>(false);
  const [tags, setTags] = useState<Tag[]>([]);
  const [tempTags, setTempTags] = useState<Tag[]>([]);
  const [checkTags, setCheckTags] = useState<boolean[]>([]);
  const [articleTagValues, setArticleTagValues] = useState<string[]>([]);
  const [filterIsRunning, setFilterIsRunning] = useState<boolean>(false);
  const [filterName, setFilterName] = useState('');
  const [sortOptions, setSortOptions] = useState<SortOptions>({ attribute: 'name', order: 'desc' });
  const aActions = articleValueActions(firestore, project.id);
  const tActions = tagActions(firestore);
  const articleValues = useArticlesValue();
  const sortValue = useRef<HTMLElement>();

  useEffect(() => {
    const subprojectAdminCategories = projectConfig.doc.categoryEditPermissions ?? [];
    if (auth.isSubprojectAdmin) {
      setArticles((prevState) => prevState.filter((article) => subprojectAdminCategories.includes(article.category)));
    }
  }, [auth.isSubprojectAdmin, projectConfig.doc.categoryEditPermissions]);

  const doSort = (options: SortOptions) => {
    const { attribute, order } = options;
    const sortedData = [...articles].sort((a, b) => {
      let valueA = (a[attribute] || '').toString();
      let valueB = (b[attribute] || '').toString();

      if (attribute === 'fId') {
        valueA = (a['fId'] || '').toString();
        valueB = (b['fId'] || '').toString();
      }

      return valueA.localeCompare(valueB) * (order === 'asc' ? 1 : -1);
    });
    setSortedArticles(sortedData);
  };

  const searchArticles = () => {
    setFilterIsRunning(true);

    if (filterName.length === 0) {
      setSortedArticles([...articles]);
      setFilterIsRunning(false);
      return;
    }

    const filter = filterName.toLocaleLowerCase();

    setSortedArticles(
      sortedArticles.filter((article) => {
        if (typeof article.name === 'string') {
          if (article.name.length > 0 || article.fId.length > 0) {
            if (article.name.toLowerCase().includes(filter) || article.fId.toLowerCase().includes(filter)) {
              return true;
            }
          }
        }
        return false;
      }),
    );

    setCheckedArticles(
      checkedArticles.filter((article) => {
        if (typeof article.name === 'string') {
          if (article.name.length > 0) {
            if (article.name.toLowerCase().includes(filterName.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      }),
    );

    setFilterIsRunning(false);
  };

  const saveValue = (elem: string, index: number, value: string) => {
    setTags(
      tags.map((tag, i) => {
        if (i === index) tag.value = value;
        return tag;
      }),
    );

    if (singleArticle !== null) {
      setArticleTagValues(
        articleTagValues.map((tag, i) => {
          if (i === index) tag = value;
          return tag;
        }),
      );
    }
  };

  const getTagValues = () => {
    const docData: { [x: string]: string[] }[] = [];

    tags.forEach(function (tag, index) {
      if (checkTags[index]) if (typeof tag.value === 'string') docData[tag.name] = tag.value;
    });

    const convertArrayObject = (arr: { [x: string]: string[] }[]) => ({ ...arr });
    const docDataObject = convertArrayObject(docData);
    return docDataObject;
  };

  const runArticleValues = () => {
    setModalOpen(false);
    if (!auth.isAdmin || !auth.user?.uid) {
      toasts.error(localization.strings.auth.notAuthorized);
      return false;
    }
    toasts.info(localization.strings.publish.start);
    return true;
  };

  const updateSingleArticleValues = () => {
    if (!runArticleValues || singleArticle === null) return;
    aActions
      .update(singleArticle, getTagValues(), articleValues)
      .then(() => toasts.success(localization.strings.settings.projectValues.toast.setOk))
      .catch((e) => toasts.error(localization.strings.settings.projectValues.toast.setFail));
    setModalOpen(false);
  };

  const updateAllArticleValues = () => {
    if (!runArticleValues) return;
    aActions
      .updateAll(checkedArticles, getTagValues(), articleValues)
      .then(() => toasts.success(localization.strings.settings.projectValues.toast.setOk))
      .catch((e) => toasts.error(localization.strings.settings.projectValues.toast.setFail));
    setModalOpen(false);
  };

  const resetSingleArticleValues = () => {
    if (!runArticleValues || singleArticle === null) return;
    aActions
      .delete(singleArticle)
      .then(() => toasts.success(localization.strings.settings.projectValues.toast.deleteOk))
      .catch((e) => toasts.error(localization.strings.settings.projectValues.toast.deleteFail));
  };

  const resetAllArticleValues = () => {
    if (!runArticleValues) return;
    aActions
      .deleteAll(checkedArticles)
      .then(() => toasts.success(localization.strings.settings.projectValues.toast.deleteOk))
      .catch((e) => toasts.error(localization.strings.settings.projectValues.toast.deleteFail));
  };

  const handleSortClick = (attribute: Attribute) => {
    if (sortOptions.attribute === attribute) {
      setSortOptions({ attribute, order: sortOptions.order === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortOptions({ attribute, order: 'asc' });
    }
  };

  const handleCheckAllClick = () => {
    if (!checkAll) {
      setCheckAll(true);
      setCheckedArticles(articles);
    } else {
      setCheckAll(false);
      setCheckedArticles([]);
    }
  };

  const handleCheckClick = (article: Article) => {
    if (checkedArticles.includes(article)) setCheckedArticles(checkedArticles.filter((id) => id !== article));
    else setCheckedArticles([...checkedArticles, article]);
  };

  const keyboardSearchArticles = (event: { key: string }) => {
    if (event.key === 'Enter') searchArticles();
  };

  const RenderListHeader = ({ articles }: { articles: Article[] }) => {
    return (
      <Row className="header my-3">
        <Col>
          <Input
            className="mx-2"
            type="checkbox"
            readOnly
            title={localization.strings.global.selectAll}
            checked={checkAll}
            onClick={handleCheckAllClick}
            disabled={articles.length === 0}
          />
        </Col>

        <Col>
          <SortButton
            disabled={sortedArticles.length === 0}
            attribute="fId"
            sortOptions={sortOptions}
            handleClick={handleSortClick}
          />
        </Col>

        <Col>
          <SortButton
            disabled={sortedArticles.length === 0}
            attribute="name"
            sortOptions={sortOptions}
            handleClick={handleSortClick}
          />
        </Col>
        {project.tags !== undefined && project.tags.length > 0
          ? project.tags.map((tag) => (
              <Col className="tag-col" key={`header-${tag}`}>
                {tag}
                <IconButton
                  icon={faCopy}
                  size="sm"
                  onClick={async () => {
                    await navigator.clipboard.writeText(`{{${tag}}}`);
                  }}
                />
              </Col>
            ))
          : null}
        <Col />
      </Row>
    );
  };

  const RenderListBody = ({
    tags,
    articles,
    checkedArticles,
    handleCheckClick,
  }: {
    tags: Tag[];
    articles: Article[];
    checkedArticles: Article[];
    handleCheckClick: (article: Article) => void;
  }) => {
    return (
      <div className="article-value-list-body">
        {articles.map((article) => (
          <Row key={article.fId}>
            <Col>
              <Input
                className="mx-2"
                type="checkbox"
                checked={checkedArticles.includes(article)}
                onChange={() => handleCheckClick(article)}
              />
            </Col>
            <Col>{article.fId}</Col>
            <Col>{article.name}</Col>
            {tags.map((tag) => (
              <Col className="tag-col" key={`${tag.name}-${article.fId}`}>
                {showArticleTag(article.fId, tag.name)}
              </Col>
            ))}
            <Col>
              <IconButton
                icon={faEdit}
                onClick={() => {
                  setSingleArticle(article);

                  const tagValues = new Array<string>(tags.length);
                  for (let i = 0; i < articleValues.docs.length; i++) {
                    if (article.fId === articleValues.docs[i].fId) {
                      for (let j = 0; j < tags.length; j++) {
                        tagValues[j] = articleValues.docs[i][tags[j].name];
                      }
                      break;
                    }
                  }

                  setArticleTagValues(tagValues);
                  setModalOpen(true);
                }}
              ></IconButton>
            </Col>
          </Row>
        ))}
      </div>
    );
  };

  const showArticleTag = (id: string, tag: string) => {
    const articleValue = articleValues.docs.find((a) => a.fId === id);
    if (articleValue !== undefined) {
      const value = articleValue[tag];
      if (value !== undefined) return value;
    }
    return '';
  };

  const renameTag = (index: number, name: string) => {
    setTempTags(
      tempTags.map((e, i) => {
        if (index === i) e.name = name;
        return e;
      }),
    );
  };

  const deleteTag = (index: number) => {
    setTempTags(tempTags.slice(0, index).concat(tempTags.slice(index + 1)));
  };

  const insertTag = () => {
    setTempTags([
      ...tempTags,
      { name: `${localization.strings.settings.projectValues.newTag} ${tempTags.length + 1}`, value: '' },
    ]);
  };

  const saveTags = () => {
    tActions.set(tempTags.map((e) => e.name)).catch((e) => console.error(`Error inserting tag: ${e}`));
  };

  const sortedArticlesByTag = (tag?: Tag) => {
    if (sortValue.current) {
      sortValue.current.innerText = tag ? tag.name : localization.strings.settings.projectValues.showAllTags;
    }

    if (tag) {
      setSortedArticles(articles.filter((article) => showArticleTag(article.fId, tag.name) !== ''));
    } else {
      setSortedArticles([...articles]);
    }
  };

  const RenderModal = () => {
    return (
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader toggle={() => setModalOpen(false)}>{localization.strings.global.edit}</ModalHeader>
        <ModalBody>
          {/* Modal content goes here */}
          <Form>
            {tempTags.map((tag, index) => (
              <div key={`modal-${tag.name}-${index}`} className="my-2">
                <div className="tag-name-div my-3">
                  <Input
                    type="checkbox"
                    className="m-2"
                    defaultChecked={false}
                    onChange={(e) => {
                      checkTags[index] = e.target.checked;
                    }}
                  ></Input>
                  <Input
                    className="tag-name-input"
                    defaultValue={tag.name}
                    invalid={hasDuplicateName}
                    onBlur={(e) => renameTag(index, e.target.value)}
                  ></Input>
                  <Button className="mx-5" color="danger" onClick={() => deleteTag(index)}>
                    {localization.strings.global.delete}
                  </Button>
                </div>
                <Input
                  value={articleTagValues[index]}
                  onChange={(e) => saveValue(tag.name, index, e.target.value)}
                ></Input>
              </div>
            ))}
          </Form>
          <Button color="success" onClick={insertTag}>
            {localization.strings.global.add}
          </Button>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            {localization.strings.global.cancel}
          </Button>
          <Button
            color="success"
            disabled={hasDuplicateName}
            onClick={(e) => {
              saveTags();
              singleArticle === null ? updateAllArticleValues() : updateSingleArticleValues();
            }}
          >
            {localization.strings.global.save}
          </Button>
          <Button
            color="danger"
            onClick={() => {
              singleArticle === null ? resetAllArticleValues() : resetSingleArticleValues();
            }}
          >
            {localization.strings.global.reset}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  useEffect(() => {
    if (sortValue.current) {
      sortValue.current.innerText = localization.strings.settings.projectValues.showAllTags;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${localization.strings.settings.projectValues.title} | ${title}`;
  }, [title, localization.strings.settings.projectValues.title, articleValues]);

  useEffect(() => {
    if (project.tags !== undefined && project.tags.length > 0) {
      setTags(
        project.tags.map((e) => {
          return { name: e, value: '' };
        }),
      );
    } else setTags(new Array<Tag>(0));
  }, [project.tags]);

  useEffect(() => {
    const tagNames: string[] = tempTags.map((e) => e.name);
    setDuplicateName(tagNames.filter((item, index) => tagNames.indexOf(item) !== index).length === 0 ? false : true);
  }, [tempTags]);

  useEffect(() => {
    if (modalOpen) {
      setCheckTags(new Array(tags.length).fill(false));
      setTempTags([...tags]);
      tags.map((tag, index) => {
        return saveValue(tag.name, index, articleTagValues[index]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  useEffect(() => {
    if (articles.length !== sortedArticles.length) setCheckAll(false);
    doSort(sortOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articles.length, sortOptions]);

  if (projectConfig.loading) return <Loading waitingFor="Project Config" />;

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.projectValues.title}</h1>
          <hr />
          <div className="button-holder">
            <Input
              className="mx-2"
              type="text"
              onChange={(e) => {
                setFilterName(e.target.value);
              }}
              onKeyDown={keyboardSearchArticles}
              disabled={filterIsRunning}
              placeholder={localization.strings.publish.titles.name}
              style={{ maxWidth: '15rem' }}
            />
            <IconButton
              className="mx-2"
              disabled={filterIsRunning}
              icon={faSearch}
              text={localization.strings.global.search}
              onClick={searchArticles}
            />
            <IconButton
              className="mx-2"
              icon={faEdit}
              text={localization.strings.settings.projectValues.edit}
              onClick={() => {
                setSingleArticle(null);
                setArticleTagValues(new Array<string>(project.tags !== undefined ? project.tags.length : 0));
                setModalOpen(true);
              }}
            ></IconButton>

            <UncontrolledDropdown>
              <DropdownToggle caret innerRef={sortValue}></DropdownToggle>
              <DropdownMenu>
                <DropdownItem style={{ margin: 0 }} key={0} onClick={() => sortedArticlesByTag()}>
                  {localization.strings.settings.projectValues.showAllTags}
                </DropdownItem>
                {tags.map((tag, index) => (
                  <DropdownItem style={{ margin: 0 }} key={index} onClick={() => sortedArticlesByTag(tag)}>
                    {tag.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

          {tags.length > 0 ? (
            <div className="article-value-list">
              <RenderListHeader articles={sortedArticles} />
              {sortedArticles.length > 0 ? (
                <RenderListBody
                  tags={tags}
                  articles={sortedArticles}
                  checkedArticles={checkedArticles}
                  handleCheckClick={handleCheckClick}
                />
              ) : (
                <h2 className="noArticles">{localization.strings.settings.projectValues.noArticles}</h2>
              )}
            </div>
          ) : (
            <h2 className="noTags">{localization.strings.settings.projectValues.noTags}</h2>
          )}
        </div>
      </main>
      {RenderModal()}
    </PageWithSidebar>
  );
};
