import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import {
  getAuth,
  User,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  signInWithCustomToken,
} from 'firebase/auth';
import ReactDOM from 'react-dom';
import { useConcreteProject } from './ProjectContext';
import { toasts } from '../../shared';
import { useFirebase, useMonofunction, useValidateCustomerSubscriptionFunction } from './Firebase';
import { useIsDraft } from './AppContext';
import { useDocument } from '../../Hooks';
import { CustomClaims, StripeUser } from '../../Types';
import { CustomClaimsData, UserRole } from '@eir/core';
import { useLocalization } from './LocalizationContext';
import useLocalStorage from 'react-use-localstorage';

export interface AppUser {
  user: User | null;
  isAdmin: boolean;
  isStaff: boolean;
  isSubprojectAdmin: boolean;
  signIn: (email: string, password: string) => void;
  signInStaff: (password: string) => void;
  signOut: () => void;
  status: Status;
  message: string;
  resetStatus: () => void;
  categoryPermissions: string[];
  displayName: string | undefined;
  loading: boolean;
  getIdToken: () => Promise<string | undefined>;
  accessWO: string;
  accessChangePass: string;
}

export enum Status {
  IDLE,
  IN_FLIGHT,
  ERROR,
}

export const useAuth = (): AppUser => useContext(AuthContext);
function useProvideAuth(): AppUser {
  const localization = useLocalization();
  const firebase = useFirebase();
  const functions = useMonofunction();
  const [user, setUser] = useState<User | null>(() => {
    return getAuth(firebase).currentUser;
  });
  const [, setDraft] = useIsDraft();
  const [userType, setUserType] = useState<UserRole | string>(UserRole.NONE);
  const [status, setStatus] = useState(Status.IDLE);
  const [message, setMessage] = useState('');
  const [categoryPermissions, setCategoryPermissions] = useState<string[]>([]);
  const project = useConcreteProject();
  const claimsNotifier = useDocument<CustomClaims>(user?.uid ? `/tokenMetaData/${user?.uid}` : undefined, {});
  const { doc: stripeUser } = useDocument<StripeUser>(user?.uid ? `/stripe/data/users/${user.uid}` : undefined, {});
  const [loading, setLoading] = useState<boolean>(true);
  const [accessWO, setAccessWO] = useLocalStorage('access', 'false');
  const [accessChangePass, setaccessChangePass] = useLocalStorage('true', 'false');

  const resetStatus = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setStatus(Status.IDLE);
      setMessage('');
    });
  };
  const invalidLoginStatus = () => {
    ReactDOM.unstable_batchedUpdates(() => {
      setStatus(Status.ERROR);
      setMessage('Login failed: Please try again.');
    });
  };

  const signIn = (email: string, password: string) => {
    setStatus(Status.IN_FLIGHT);
    signInWithEmailAndPassword(getAuth(firebase), email, password)
      .then(({ user }) => {
        const userIdentifier = project.uniformAuthentication
          ? user.displayName
          : localization.strings.auth.adminAccount.toLowerCase();
        toasts.success(`${localization.strings.auth.authenticated} ${userIdentifier}`);
        setAccessWO('true');
        project.uniformAuthentication ? setaccessChangePass('false') : setaccessChangePass('true');
      })
      .catch(({ reason }) => {
        console.log(reason);
        toasts.error(localization.strings.auth.error.invalidCredentials);
        invalidLoginStatus();
      });
  };
  const signOut = () => {
    setDraft(false);
    void firebaseSignOut(getAuth(firebase)).then(() => {
      toasts.success(localization.strings.auth.loggedout);
      setAccessWO('false');
      setaccessChangePass('false');
    });
  };
  const signInStaff = (password: string) => {
    setStatus(Status.IN_FLIGHT);
    void functions
      .PasswordOnlyLogin({ password })
      .then((token: string) => {
        signInWithCustomToken(getAuth(firebase), token)
          .then(() => {
            toasts.success(
              `${localization.strings.auth.authenticated} ${localization.strings.auth.staffAccount.toLowerCase()}`,
            );
            setAccessWO('false');
            setaccessChangePass('false');
          })
          .catch(({ reason }) => {
            console.log(reason);
            toasts.error(localization.strings.auth.error.invalidStaffPassword);
            invalidLoginStatus();
          });
      })
      .catch((reason) => {
        console.log(reason);
        toasts.error(localization.strings.auth.error.invalidStaffPassword);
        invalidLoginStatus();
      });
  };
  const handleClaims = async (user: User | null) => {
    if (user === null) {
      ReactDOM.unstable_batchedUpdates(() => {
        setUser(null);
        setUserType(UserRole.NONE);
        setStatus(Status.IDLE);
        setCategoryPermissions([]);
      });
      return;
    }

    const {
      emailVerified,
      role,
      categoryPermissions: cp,
      ...rest
    } = (await user.getIdTokenResult(true)).claims as CustomClaimsData;

    if (role === undefined && !emailVerified) return;

    if (
      role === UserRole.SUPER_ADMIN ||
      role === UserRole.ADMIN ||
      role === UserRole.STAFF ||
      role === UserRole.SUBPROJECT_ADMIN
    ) {
      ReactDOM.unstable_batchedUpdates(() => {
        setUser(user);
        setStatus(Status.IDLE);
        setUserType(role);
        if (rest.subscription) {
          setCategoryPermissions(rest.subscriptionCategoryPermissions ?? []);
        } else setCategoryPermissions(cp ?? []);
      });
    } else {
      toasts.error('Invalid user type');
      console.log(`Invalid user type: ${role}`);
      ReactDOM.unstable_batchedUpdates(() => {
        setUser(null);
        setStatus(Status.IN_FLIGHT);
        setUserType(UserRole.NONE);
        setCategoryPermissions([]);
      });
      signOut();
      return;
    }
  };

  useEffect(() => {
    const unsubscribe = getAuth(firebase).onAuthStateChanged((user) => {
      void handleClaims(user).then(() => setLoading(false));
    });
    return unsubscribe;
    // eslint-disable-next-line
  }, [project, firebase]);

  useEffect(() => {
    if (claimsNotifier.doc.claimsLastUpdated && user) {
      setLoading(true);
      void handleClaims(user).then(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [claimsNotifier.doc]);

  const validateSubscription = useValidateCustomerSubscriptionFunction();
  useEffect(() => {
    if (user && stripeUser && stripeUser.stripeSubscriptionId) {
      validateSubscription({ subscriptionId: stripeUser.stripeSubscriptionId })
        .then(() => {
          console.log('Subscription validated');
        })
        .catch((error) => console.log('subscription validation failed:', error));
    }
    // eslint-disable-next-line
  }, [stripeUser]);

  const getIdToken = async (): Promise<string | undefined> => {
    return await user?.getIdToken();
  };

  return {
    user,
    status,
    message,
    signIn,
    signOut,
    resetStatus,
    signInStaff,
    get isAdmin() {
      return userType === UserRole.SUPER_ADMIN || userType === UserRole.ADMIN || userType === UserRole.SUBPROJECT_ADMIN;
    },
    get isStaff() {
      return userType === UserRole.STAFF;
    },
    get isSubprojectAdmin() {
      return userType === UserRole.SUBPROJECT_ADMIN;
    },
    categoryPermissions,
    displayName: user?.displayName || undefined,
    loading,
    getIdToken,
    accessWO,
    accessChangePass,
  };
}

const AuthContext = createContext<AppUser>({} as AppUser);
export function AuthProvider({ children }: { children?: ReactNode }): ReactElement {
  return <AuthContext.Provider value={useProvideAuth()}>{children}</AuthContext.Provider>;
}
