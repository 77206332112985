import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './bootstrap.scss';
import { App } from './components/App/App';
import * as serviceWorker from './serviceWorkerRegistration';
import { ErrorBoundary } from './components/Error/BugsnagErrorBoundary';
import { ErrorView } from './components/Error/DefaultErrorView';
import { reportError } from './components/Error/Bugsnag';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <ErrorBoundary FallbackComponent={ErrorView} onError={reportError}>
    <App />
  </ErrorBoundary>,
);

serviceWorker.register({
  onUpdate: (registration: ServiceWorkerRegistration) => {
    console.log('serviceWorkerRegistration is waiting?', registration && registration.waiting);
    if (registration && registration.waiting) {
      // Send a message to the waiting Service Worker to skip waiting
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });

      // Listen for the state change of the Service Worker
      registration.waiting.addEventListener('statechange', (event) => {
        if ((event.target as ServiceWorker).state === 'activated') {
          // Reload the page to use the new Service Worker
          window.location.reload();
        }
      });
    }
  },
});
