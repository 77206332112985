import { LanguageCode } from '@eir/core';

export type CategoryTypes = 'articleCategory' | 'url' | 'html' | 'workorder' | 'changelog' | 'nativeModule';
export interface LocalizedStrings {
  auth: {
    signInButton: string;
    signOutButton: string;
    registerButton: string;
    firstNameTitle: string;
    firstNamePlaceholder: string;
    lastNameTitle: string;
    lastNamePlaceholder;
    emailTitle: string;
    emailPlaceholder: string;
    emailPlaceholder2: string;
    invite: string;
    phoneTitle: string;
    phonePlaceHolder: string;
    phoneErrorDisplay: string;
    phoneAlreadyExists: string;
    logout: string;
    error: {
      unknown: string;
      invalidCredentials: string;
      invalidStaffPassword: string;
      invalidCreateAccount: string;
      invalidEmailVerification: string;
    };
    wrongProject: string;
    staffAccount: string;
    adminAccount: string;
    adminAccountAddCategory: string;
    authenticated: string;
    loggedout: string;
    notAuthenticated: string;
    notAuthorized: string;
    resetEmailSent: string;
    resetEmailFail: string;
    passwordTitle: string;
    passwordConfirmTitle: string;
    passwordPlaceholder: string;
    passwordPlaceholderRetype: string;
    passwordChangeSuccess: string;
    passwordChangeFailure: string;
    staffPasswordNotUnique: string;
    passwordRules: {
      adminHeader: string;
      staffHeader: string;
      lowercase: string;
      uppercase: string;
      special: string;
      number: string;
      whitespace: string;
      adminMin: string;
      staffMin: string;
      staffMax: string;
    };
    invalidPasswordFormat: string;
    invalidPasswordLenght: string;
    invalidPasswordRetype: string;
    welcomeBack: string;
    loginToGetAccess: string;
    registerToGetAccess: string;
    forgotPasswordClick: string;
    noAccountYetClick: string;
    here: string;
    recoveryEmailInfo: string;
    resetPassword: string;
    loginToSee: string;
    emailVerification: {
      emailSent: string;
      verify: string;
      expired: string;
      verified: string;
      error: string;
      invalidToken: string;
      emailNotVerified: string;
      send: string;
    };
    oldPasswordPlaceholder: string;
    invalidOldPassword: string;
    editAccountInfo: string;
    submit: string;
    welcomeUser: string;
  };
  error: {
    error: string;
    unknown: string;
    404: string;
  };
  external: {
    externalContent: string;
  };
  adminViewMode: {
    enterPublishMode: string;
    onlyPublishedMaterialIsShown: string;
    publishTip: string;
    editTip: string;
  };
  global: {
    only: string;
    mobile: string;
    website: string;
    icon: string;
    name: string;
    url: string;
    save: string;
    doNotSave: string;
    cancel: string;
    reset: string;
    preview: string;
    view: string;
    edit: string;
    delete: string;
    loading: string;
    startApplication: string;
    searchResult: string;
    search: string;
    add: string;
    allResults: string;
    feedbackSubject: string;
    privacyPolicy: string;
    termsOfUse: string;
    fileFromatError: string;
    print: string;
    create: string;
    clear: string;
    ok: string;
    confirm: string;
    send: string;
    online: string;
    offline: string;
    clickToReload: string;
    clickToReloadFull: string;
    availableOn: string;
    linkTo: string;
    linkCopied: string;
    insertAnchorlink: string;
    homePage: string;
    feedback: {
      feedback: string;
      feedbackAndPrint: string;
      send: string;
      noFeedbackEmailExist: string;
    };
    fullscreen: string;
    addurl: string;
    iframTooltip: string;
    youAreIn: string;
    selectAll: string;
  };
  publish: {
    publish: string;
    publishing: string;
    start: string;
    publishOk: string;
    publishFail: string;
    publishMode: string;
    editMode: string;
    defaultPublishMessage: string;
    setPublishMessage: string;
    publishWithNotification: string;
    help: {
      title: string;
      whatIsIncludedWhenPublishing: {
        title: string;
        articles: string;
        alwaysIncludedList: string[];
        lastUpdatedBy: string;
      };
      sidebarSettings: {
        title: string;
        publishMessage: string;
        sendNotification: string;
      };
      changelog: {
        title: string;
        content: string;
      };
    };
    titles: {
      lastUpdated: string;
      lastUpdatedBy: string;
      lastUpdatedDate: string;
      name: string;
      state: string;
    };
    editStates: {
      created: string;
      deleted: string;
      updated: string;
    };
    noArticlesHaveBeenEdited: string;
    noStaffAccountsExist: string;
    noRoleExist: string;
    atClock: string;
    publishMessagePlaceholder: string;
    errorLoadingAccounts: string;
    linksWithIssues: string;
    imagesWithIssues: string;
    notificationSettings: string;
    notifyAll: string;
    chooseTargets: string;
  };
  settings: {
    transferArticle: {
      moveSection: string;
      moveArticle: string;
      articleTransfer: string;
      transferArticleHeaderDesc: string;
      transferArticleDescription: string;
      publishing: string;
      selectCategoryOne: string;
      selectCategoryTwo: string;
    };
    roles: {
      settingItemTitle: string;
      hint: string;
      noRoleExists: string;
      roleName: string;
      createRole: string;
      createRoleSuccess: string;
      createRoleError: string;
      editRole: string;
      changeAccess: string;
      save: string;
      roleNameError: string;
      categoriesTitle: string;
      workOrdersTitle: string;
      workOrdersSubTitle: string;
      roleNameFormat: {
        line1: string;
        line2: string;
        line3: string;
      };
      delete: {
        modalHeader: string;
        modalBody: string;
        error: string;
      };
    };
    multiStaffAccount: {
      displayName: string;
      projectId: string;
      createdAt: string;
      status: string;
      email: string;
      role: string;
      addAccount: string;
      errorEmailRole: string;
      invitationSuccess: string;
      importedMailsCount: string;
      inviteAll: string;
      inviteOne: string;
      reinvite: string;
      reinviteTooltip: string;
      delete: string;
      deleteTooltip: string;
      exportCSV: string;
      exportCSVTooltip: string;
      changeRole: string;
      editRole: string;
      editRoleTooltip: string;
      forgotPassword: string;
      confirmDeleteAccounts: string;
      deleteAccounts: string;
      deleteAccountSuccess: string;
      deleteAccountFailure: string;
      deleteAccountsSuccess: string;
      deleteAccountsFailure: string;
      sendInvitationSuccess: string;
      sendInvitationFailure: string;
      sendInvitationDuplicate: string;
      sendInvitationsSuccess: string;
      sendInvitationsFailure: string;
      sendInvitationsDuplicate: string;
    };
    settings: string;
    updateOk: string;
    updateFailed: string;
    email: string;
    emailSettings: string;
    addEmailsHint: string;
    categoryAccessHint: string;
    emailAlreadyExist: string;
    staffAccountMng: string;
    staffAccountCategories: string;
    staffAccountCategoriesPlaceholder: string;
    staffAccountHasOngoingSubscription: string;
    accountMng: string;
    selectAccount: string;
    confirmDeleteAccount: string;
    deleteAccount: string;
    deleteAccountSuccess: string;
    deleteAccountFailure: string;
    emptyAccountSearch: string;
    changeAdminPass: string;
    changeStaffPass: string;
    passChangeSuccess: string;
    passChangeFail: string;
    emailError: string;
    projectSettings: string;
    projectNameHint: string;
    projectTitleHint: string;
    projectOrganizationHint: string;
    projectPublisherHint: string;
    handbook: string;
    categories: string;
    handleCategories: string;
    accessRoles: string;
    accountRole: string;
    access: string;
    selectLanguage: string;
    export: {
      create: string;
      delete: string;
      deleteFinished: string;
      download: string;
      exportStarted: string;
      exportFinished: string;
      generating: string;
      hint: string;
      title: string;
      notFound: string;
      failedToDownload: string;
      exportAll: string;
    };
    userProfile: {
      title: string;
      account: {
        title: string;
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
      };
      statistics: {
        title: string;
        name: string;
        benefactor: string;
        polygons: string;
        hectares: string;
        hectaresUsed: string;
        hectaresAvailable: string;
        acres: string;
        emptyPolygons: string;
      };
      subscription: {
        title: string;
        level: string;
        noSubscription: string;
        perks: {
          title: string;
          unlimited: string;
          total: string;
        };
        cost: string;
        month: string;
        year: string;
      };
    };
    agroScan: {
      staffAccountAccess: string;
      readPolygon: string;
      createPolygon: string;
      deletePolygon: string;
      createPrescriptionFile: string;
      readPrescriptionFile: string;
      readRequestsHistory: string;
      readCrudeProtein: string;
      processCrudeProtein: string;
      readDryMatter: string;
      processDryMatter: string;
      readPlantHealth: string;
      processPlantHealth: string;
      readSoilMoisture: string;
      processSoilMoisture: string;
      readSoilOrganicCarbon: string;
      processSoilOrganicCarbon: string;
      readVegetationIndices: string;
      processVegetationIndices: string;
      readWaterStress: string;
      processWaterStress: string;
      crudeProtein: string;
      dryMatter: string;
      plantHealth: string;
      soilMoisture: string;
      soilOrganicCarbon: string;
      vegetationIndices: string;
      waterStress: string;
      potatoAnalysis: string;
    };
    subscriptions: {
      noSubscription: string;
      manageBillingInformation: string;
      subscriptionUntil: string;
      stripeProductId: string;
      hectaresLimit: string;
      mostPopular: string;
      noSubscriptionsExist: string;
      getSubscription: string;
      upgradeSubscription: string;
      subscribeSuccess: string;
      subscribeError: string;
      cancelSubscriptionSuccess: string;
      cancelSubscriptionError: string;
      downgradeStubscriptionHeader: string;
      downgradeStubscription: string;
      loadingPayWall: string;
      processSession: string;
      sort: string;
      custom: {
        title: string;
        description: string;
        contactUs: string;
      };
      configuration: {
        title: string;
        hint: string;
        add: string;
      };
      create: {
        title: string;
        success: string;
        error: string;
        stripeProductIdError: string;
        alreadyExists: string;
      };
      payment: {
        title: string;
      };
      list: {
        title: string;
        monthly: string;
        yearly: string;
      };
      edit: {
        title: string;
        success: string;
        error: string;
      };
      delete: {
        modalHeader: string;
        modalBody: string;
        success: string;
        error: string;
      };
      cancel: {
        modalHeader: string;
        modalBody: string;
        yes: string;
        no: string;
      };
      tax: {
        modalHeader: string;
        id: string;
        example: string;
        feedback: string;
        accept: string;
        termsOfUse: string;
        register: string;
      };
    };
    import: {
      title: string;
      upload: {
        started: string;
        success: string;
        failed: string;
        noError: string;
        failedBookmark: string;
        loadingImportingDocument: string;
      };
      csvImport: string;
      csvFormatError: string;
      exceedingMaxUsers: string;
    };
    subprojects: {
      title: string;
      placeholder: string;
      empty: string;
      add: string;
      edit: string;
      delete: string;
      sorting: {
        index: string;
        id: string;
        name: string;
        logo: string;
        allowedUsers: string;
        creationDate: string;
        lastUpdate: string;
        admin: string;
        style: {
          primary: string;
          primaryLight: string;
          primaryDark: string;
          accent: string;
        };
      };
      tooltips: {
        id: string;
        name: string;
        logo: string;
        admin: string;
        style: {
          primary: string;
          primaryLight: string;
          primaryDark: string;
          accent: string;
        };
      };
      contactInfosynk: string;
    };
    projectValues: {
      title: string;
      edit: string;
      newTag: string;
      noArticles: string;
      noTags: string;
      sorting: {
        fId: string;
        name: string;
        category: string;
      };
      toast: {
        setOk: string;
        setFail: string;
        deleteOk: string;
        deleteFail: string;
      };
      showAllTags: string;
    };
    transfer: {
      title: string;
      next: string;
      goBack: string;
      confirm: string;
      projectFrom: string;
      projectTo: string;
      showTable: string;
      hideTable: string;
      started: string;
      finished: string;
    };
    subprojectAdmin: {
      title: string;
      hint: string;
    };
    typeOfCategory: string;
    categoryNameWrongFormat: string;
    provideUrl: string;
    idChangelogCantBeUsed: string;
    categoryNameAndIconNeeded: string;
    categoryNewNameOrNewIcon: string;
    noChangesMade: string;
  };
  sorting: {
    articleSort: string;
    sectionSort: string;
    articleEdit: string;
    sectionEdit: string;
    titleForm: string;
    cancelSorting: string;
    saveSorting: string;
    sortingArticlesHasChanged: string;
    sortingSectionsHasChanged: string;
    unsavedChanges: string;
    sortingHasChanged: string;
  };
  article: {
    article: string;
    draftModalHeader: string;
    draftModalBody: string;
    articleSavedOk: string;
    deleteModalHeader: string;
    deleteModalBody: string;
    deleteModalBodyPerma: string;
    deleteOk: string;
    addOk: string;
    sortingSaved: string;
    addNewArticle: string;
    wrongArticleName: string;
    notFoundWarning: string;
    updateOk: string;
    updateFailed: string;
    fileTooLarge: string;
    protectedArticle: {
      errorMessage: string;
      errorMessageStaff: string;
    };
  };
  category: {
    addCategory: string;
    uploadCategoryIcon: string;
    category: string;
    lock: string;
    lockCategoryInputHint: string;
    lockOk: string;
    protectOk: string;
    lockFail: string;
    protectFail: string;
    login: string;
    password: string;
    pinCheckNotValid: string;
    pinAddNotValid: string;
    notFoundWarning: string;
    updated: string;
    updateError: string;
    protectedCategory: {
      chiefErrorMessage: string;
      defaultErrorMessage: string;
    };
    categoryNames: Record<CategoryTypes, string>;
    categoryDescription: Record<CategoryTypes, string>;
    termsOfUseAddCategory: string;
    chiefLoading: string;
    sortingStarted: string;
    sortingCompleted: string;
    sortingError: string;
    editCategoryStarted: string;
    editCategorySucces: string;
    editCategoryFailed: string;
    startedCreatingCategory: string;
    categoryIdExists: string;
    failedCreatingCategory: string;
    successCreatingCategory: string;
  };
  section: {
    section: string;
    defaultSection: string;
    deleteModalHeader: string;
    deleteModalBody: string;
    deleteModalBodyPerma: string;
    sectionDeleted: string;
    sortingSaved: string;
    addOk: string;
    addError: string;
    deleteOk: string;
    addNewSection: string;
    wrongSectionName: string;
    deletingAdditionalArticles: string;
  };
  project: {
    notLoaded: string;
    noStaffAccount: string;
  };
  forms: {
    placeHolders: {
      createNewSection: string;
      sectionName: string;
      articleName: string;
    };
    messages: {
      valueToShort: string;
    };
    uploadImage: string;
    gallerySelect: string;
    saveSortingButton: string;
  };
  search: {
    relevance: string;
    alphabetical: string;
    up: string;
    down: string;
    allResults: string;
    seeAll: string;
    searchSuggest: string;
    searchError: string;
    pageNumberError: string;
    resultError: string;
  };
  versionOutdated: {
    title: string;
    body: string;
    button: string;
  };
}

export const en: LocalizedStrings = {
  auth: {
    signInButton: 'Sign in',
    signOutButton: 'Sign out',
    registerButton: 'Register',
    firstNameTitle: 'First Name',
    firstNamePlaceholder: 'Your first name',
    lastNameTitle: 'Last Name',
    lastNamePlaceholder: 'Your last name',
    emailTitle: 'Email',
    emailPlaceholder: 'your@email.com',
    phoneTitle: 'Phone',
    phonePlaceHolder: '(NN)NNNN-NNNN',
    phoneErrorDisplay: 'Format Example: 1133333333 or 11999999999',
    phoneAlreadyExists: 'The provided phone number is already in use by an existing user.',
    passwordTitle: 'Password',
    passwordConfirmTitle: 'Confirm Password',
    passwordPlaceholder: 'Password',
    passwordPlaceholderRetype: 'Retype password',
    logout: 'Logout',
    emailPlaceholder2: 'email to invite',
    invite: 'Invite this user',
    error: {
      unknown: 'Unknown error',
      invalidCredentials: 'Invalid email/password',
      invalidStaffPassword: 'Invalid staff account password',
      invalidCreateAccount: 'Could not register new user account',
      invalidEmailVerification: 'Could not send verification email',
    },
    wrongProject: 'You cannot login into this project!',
    staffAccount: 'Code',
    adminAccount: 'Email/Password',
    adminAccountAddCategory: 'Administrator',
    authenticated: 'You are logged in as',
    loggedout: 'You have been logged out',
    notAuthenticated: 'You are not logged in',
    notAuthorized: 'You are not authorized',
    passwordChangeFailure: 'Failed to update password',
    passwordChangeSuccess: 'Password updated',
    invalidPasswordFormat:
      'Password must contain a combination of lowercase and uppercase letters, numbers, and special characters.',
    invalidPasswordLenght: 'Password must contain between 8 and 20 characters.',
    invalidPasswordRetype: 'Passwords do not match!',
    staffPasswordNotUnique: 'Password for staff account must be unique',
    welcomeBack: 'Welcome back',
    loginToGetAccess: 'Log in to access the platform',
    registerToGetAccess: 'Register to access the platform',
    forgotPasswordClick: 'Forgot password? Click',
    noAccountYetClick: "Don't have an account yet? Click",
    here: 'here',
    resetEmailSent: 'Email sent. Check your inbox',
    resetEmailFail: 'Unknown problem. Email not sent, contact support.',
    recoveryEmailInfo:
      'Input the e-mail you use to sign in to the platform to get a password recovery e-mail. If the e-mail does not arrive or if you have other questions, contact Softwerk support',
    resetPassword: 'Reset password',
    loginToSee: 'Log in to access the content',
    passwordRules: {
      adminHeader: 'Password Admin account',
      staffHeader: 'Password Staff account',
      lowercase: 'At least one lowercase letter',
      uppercase: 'At least one uppercase letter',
      special: 'At least one special character [?,!,$,...]',
      number: 'At least one number',
      whitespace: 'No whitespace',
      adminMin: 'Minimum 8 characters long',
      staffMin: 'Minimum 6 characters',
      staffMax: 'Maximum 20 characters',
    },
    emailVerification: {
      verify: 'Verify Email',
      expired: 'Your email verification has expired',
      verified: 'Your email is now verified',
      error: 'Error trying to verify email',
      emailSent: 'Verification email has been sent.',
      invalidToken: 'Email verification token is invalid or expired.',
      emailNotVerified:
        'Your email is not verified. You can verify your account through the verification link sent to your email, or if the link has expired you can resend it here.',
      send: 'Send verification email',
    },
    oldPasswordPlaceholder: 'Old password',
    invalidOldPassword: 'Password is incorrect.',
    editAccountInfo: 'Edit account info',
    submit: 'Submit',
    welcomeUser: 'Welcome {{user}}. Please fill the form to complete your account creation.',
  },
  error: {
    error: 'Error',
    unknown: 'Something went wrong!',
    404: 'Content not found',
  },
  external: {
    externalContent: 'External Content',
  },
  adminViewMode: {
    enterPublishMode: 'View published content',
    onlyPublishedMaterialIsShown: 'only published content is shown',
    editTip:
      'Changes made in this mode can only be viewed by Admins. Only after you have published will the new changes be visible to non-Admins.',
    publishTip:
      'In this mode you can only view material that has already been published. The version categories, sections, and articles you see is the same as what a non-Admin would see. You can not make any changes in this mode.',
  },
  global: {
    only: 'only',
    mobile: 'Mobile',
    website: 'Website',
    add: 'Add',
    icon: 'Icon',
    name: 'Name',
    url: 'URL',
    save: 'Save',
    doNotSave: 'Do not save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    reset: 'Reset',
    edit: 'Edit',
    preview: 'Preview',
    view: 'View',
    delete: 'Delete',
    loading: 'Loading...',
    startApplication: 'Start Application...',
    searchResult: 'Search Result',
    search: 'Search',
    allResults: 'All results for',
    feedbackSubject: 'Feedback',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of use',
    fileFromatError: 'File extention is not supported',
    availableOn: 'Available on',
    print: 'Print',
    create: 'Create',
    clear: 'Close',
    ok: 'OK',
    send: 'Send',
    online: 'You are online',
    offline: 'You are offline. Some functionality is limited.',
    clickToReload: 'Reload component',
    clickToReloadFull: 'Reload the page',
    linkTo: 'Link to',
    linkCopied: 'The link has been copied to your clipboard',
    insertAnchorlink: 'Insert anchor link',
    homePage: 'Home page',
    fullscreen: 'Fullscreen',
    feedback: {
      feedback: 'Feedback',
      feedbackAndPrint: 'Feedback & Print',
      send: 'Send feedback',
      noFeedbackEmailExist: 'No feedback email has been registered',
    },
    addurl: 'Embed external content',
    iframTooltip:
      'You can enter your value in pixels or percentages.Please write % or px after the value. Default value:',
    youAreIn: 'You are in',
    selectAll: 'Select all',
  },
  publish: {
    publish: 'Publish',
    publishing: 'Publishing',
    start: 'Publishing process started',
    publishOk: 'Publishing done',
    publishFail: 'Publishing failed',
    publishMode: 'Published mode',
    editMode: 'Edit mode',
    defaultPublishMessage: 'New content has been published.',
    setPublishMessage: 'Set publish message',
    publishWithNotification: 'Publish with notification',
    linksWithIssues: 'Links with issues',
    imagesWithIssues: 'Images with issues',

    help: {
      title: 'Help',
      whatIsIncludedWhenPublishing: {
        title: 'What is included in a publish?',
        articles:
          'The list on the page shows all articles which have changes compared to the currently published material. Only articles whos checkbox is checked will be included when publishing. Note that regardless of which articles are included, if any, changes to the following things will ALWAYS be included when publishing:',
        alwaysIncludedList: [
          'Locked/unlocked status or regular categories',
          'Project configuration',
          'Sections',
          'Article ordering in sektions',
        ],
        lastUpdatedBy: 'The name of the Admin who last edited an article is never visible to non-Admins.',
      },
      sidebarSettings: {
        title: 'Publish settings',
        publishMessage:
          'The publish message is included in the changelog entry, and is the message shown in the push notification.',
        sendNotification:
          '"Publish with notification" means that a push notification will be sent to all mobile devices that have the app installed.',
      },
      changelog: {
        title: 'Changelog',
        content:
          'If your application has a changelog, an article is created with information about the changes at the time of publishing. This article displays, among other things, the publish message and which articles have been created, updated, or removed since last publish.',
      },
    },
    titles: {
      lastUpdatedDate: 'Date',
      lastUpdatedBy: 'Last updated by',
      lastUpdated: 'Last updated',
      name: 'Article',
      state: 'Status',
    },
    editStates: {
      created: 'Added',
      deleted: 'Deleted',
      updated: 'Updated',
    },
    noArticlesHaveBeenEdited: 'No articles have been edited, added, or deleted since last publish!',
    noStaffAccountsExist: 'There are no staff accounts to notify!',
    noRoleExist: 'There are no roles to notify!',
    atClock: 'at',
    publishMessagePlaceholder: 'Publish messsage',
    errorLoadingAccounts: 'Could not load accounts!',
    notificationSettings: 'Notification settings',
    notifyAll: 'Notify all users',
    chooseTargets: 'Choose accounts to notify',
  },
  settings: {
    transferArticle: {
      moveSection: 'Move Section',
      moveArticle: 'Move Article',
      articleTransfer: 'Transfer between categories',
      transferArticleHeaderDesc: 'Select the two categories between which you want to move sections or articles.',
      transferArticleDescription:
        'Transfering a Section moves all corresponding articles to that section, whilst moving a single article would only move that article into the ',
      publishing: 'Moving articles or sections requires a publish and will go into the changelog.',
      selectCategoryOne: 'Select Category One',
      selectCategoryTwo: 'Select Category Two',
    },
    roles: {
      settingItemTitle: 'Role configuration',
      hint: 'Below you can adjust the roles',
      noRoleExists: 'There is no role',
      changeAccess: 'Change access to categories',
      createRole: 'Create role',
      createRoleSuccess: 'Role was created',
      createRoleError: 'Something went wrong creating role, please try again!',
      editRole: 'Roles was edited',
      roleName: 'Role name',
      save: 'Save',
      roleNameError: 'Invalid role name',
      categoriesTitle: 'Manage access to categories',
      workOrdersSubTitle: 'Allow access to all started and closed forms ',
      workOrdersTitle: 'Access to work order system',
      roleNameFormat: {
        line1: 'Role name can only contain english letters, numbers, - , _',
        line2: 'Role name cannot have special characters',
        line3: 'Role name cannot start or end with white space ',
      },
      delete: {
        modalHeader: 'Delete the role',
        modalBody: 'Are you sure you wat to delete this role?',
        error: 'Failed to delete the role',
      },
    },
    multiStaffAccount: {
      displayName: 'Name',
      projectId: 'Project',
      createdAt: 'Created at',
      status: 'Status',
      email: 'Email',
      addAccount: 'Add one account by email address',
      role: 'Role',
      errorEmailRole: 'Please check the email and role',
      invitationSuccess: 'User is invited',
      importedMailsCount: 'Emails imported',
      inviteAll: 'Invite all',
      inviteOne: 'Invite a user',
      reinvite: 'Resend invitation',
      reinviteTooltip: 'Resend invitation to the pending users',
      delete: 'Delete users',
      deleteTooltip: 'Delete the selected users',
      exportCSV: 'Export as CSV',
      exportCSVTooltip: 'Export selected users into a CSV file',
      changeRole: 'Change role to:',
      editRole: 'Edit roles',
      editRoleTooltip: 'Change role to the selected users',
      forgotPassword: 'Forgot your password? Click here to reset your password!',
      confirmDeleteAccounts: 'Are you sure you want to delete all these accounts',
      deleteAccounts: 'Delete accounts',
      deleteAccountSuccess: 'successful account deletion',
      deleteAccountFailure: 'failed account deletion',
      deleteAccountsSuccess: 'successful account deletions',
      deleteAccountsFailure: 'failed account deletions',
      sendInvitationSuccess: 'successful invitation sent',
      sendInvitationFailure: 'failed invitation',
      sendInvitationDuplicate: 'duplicated email',
      sendInvitationsSuccess: 'successful invitations sent',
      sendInvitationsFailure: 'failed invitations',
      sendInvitationsDuplicate: 'duplicated emails',
    },
    noChangesMade: 'No changes is made',
    provideUrl: 'Please provide an URL',
    accountRole: 'Select the role',
    settings: 'Settings',
    email: 'Feedback Email Addresses',
    emailSettings: 'Email Settings',
    addEmailsHint: 'Add emails to feedback emails.',
    categoryAccessHint:
      'Access to locked categories must be given on a per-account basis. Unlocked categories can be accessd by anyone.',
    updateOk: 'Changes are saved',
    updateFailed: 'Saving changes failed',
    emailAlreadyExist: 'Email Already exist',
    staffAccountMng: 'Staff accounts',
    staffAccountCategories: 'Change access to locked categories',
    staffAccountCategoriesPlaceholder: 'All categories are unlocked!',
    staffAccountHasOngoingSubscription: 'Account has an ongoing subscription.',
    accountMng: 'Account Management',
    selectAccount: 'Select an account to edit',
    confirmDeleteAccount: 'Are you sure you want to delete this account',
    deleteAccount: 'Delete account',
    deleteAccountSuccess: 'The account was deleted',
    deleteAccountFailure: 'The account was not deleted',
    emptyAccountSearch: 'No results!',
    changeAdminPass: 'Change admin account password',
    changeStaffPass: 'Change account password',
    passChangeSuccess: 'Password change succeeded!',
    passChangeFail: 'Changing password failed',
    emailError: 'An error in email!',
    export: {
      create: 'Create new export',
      delete: 'Delete export',
      deleteFinished: 'Export deleted',
      download: 'Download export',
      exportFinished: 'Export generated!',
      exportStarted: 'Initiating export...',
      generating: 'Generating export...',
      hint: 'Export an offline-only version of web app containing the latest published content. The latest export is stored and can be downloaded multiple times.',
      title: 'Export',
      notFound: 'No export has been made yet',
      failedToDownload: 'Failed to download the zip file!',
      exportAll: 'Export all',
    },
    userProfile: {
      title: 'User Profile',
      account: {
        title: 'Account',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        phone: 'Phone',
      },
      statistics: {
        title: 'Statistics',
        name: 'Name',
        benefactor: 'Benefactor',
        polygons: 'Polygons',
        hectares: 'Hectares',
        hectaresUsed: 'Used hectares',
        hectaresAvailable: 'Available hectares',
        acres: 'Acres',
        emptyPolygons: 'No polygons found.',
      },
      subscription: {
        title: 'Subscription',
        level: 'Subscription level is',
        noSubscription: 'No subscription',
        perks: {
          title: 'Perks',
          unlimited: 'Unlimited analysis',
          total: 'Total of',
        },
        cost: 'Cost',
        month: 'Month',
        year: 'Year',
      },
    },
    subscriptions: {
      noSubscription: 'You have no subscription.',
      manageBillingInformation: 'Click here to manage payment method, billing information and invoice history.',
      subscriptionUntil: 'You have a subscription until',

      stripeProductId: 'Stripe product ID',
      hectaresLimit: 'Hectares limit',
      mostPopular: 'Most popular',
      noSubscriptionsExist: 'There are no subscriptions.',
      getSubscription: 'Get subscription',
      upgradeSubscription: 'Upgrade subscription',
      subscribeSuccess: 'You are now subscribed.',
      subscribeError: 'Processing your subscription failed.',
      cancelSubscriptionSuccess: 'Subscription was canceled.',
      cancelSubscriptionError: 'Canceling subscription failed.',
      downgradeStubscriptionHeader: 'Dowgrade subscription',
      downgradeStubscription:
        'You have used {{usedHectares}} hectares and the limit of your chosen subscription is {{newLimit}} hectares. To downgrade your subscription please make sure that you delete polygons with area of {{difference}} hectares.',
      loadingPayWall: 'Loading the payment form.',
      processSession: 'Processing payment. Please do not close/refresh the window',
      sort: 'Sort subscriptions',
      custom: {
        contactUs: 'Contact Us',
        title: 'Custom',
        description:
          'You have the power to customize your access and limitations according to your specific needs. To craft your personalized plan, simply reach out to us at',
      },
      configuration: {
        title: 'Subscriptions Configuration',
        hint: 'Below you can adjust the subscriptions',
        add: 'Add new subscription',
      },
      create: {
        title: 'Create subscription',
        success: 'Subscription was added.',
        error: 'Adding subscription failed.',
        stripeProductIdError: 'A product with the specified ID does not exist.',
        alreadyExists: 'A subscription with the specified Stripe product ID already exists.',
      },
      payment: {
        title: 'Purchase subscription',
      },
      list: {
        title: 'Subscriptions',
        monthly: 'Monthly',
        yearly: 'Yearly',
      },
      edit: {
        title: 'Edit subscription',
        success: 'Subscription was edited.',
        error: 'Editing subscription failed.',
      },
      delete: {
        modalHeader: 'Delete subscription',
        modalBody: 'Are you sure you want to delete the subscription ?',
        success: 'Subscription was deleted.',
        error: 'Deleting subscription failed.',
      },
      cancel: {
        modalHeader: 'Cancel subscription plan',
        modalBody: 'Are you sure you want to cancel your subscription?',
        yes: 'Yes, cancel anyway',
        no: 'No',
      },
      tax: {
        modalHeader: 'Tax ID',
        id: 'Insert your Tax ID number here',
        example: 'Example: Brazilian CNPJ number 01.234.456/5432-10 / CPF number 123.456.789-87.',
        feedback: 'The Tax ID does not follow the CPF/CNPJ format!',
        accept: 'Please accept',
        termsOfUse: 'terms and conditions',
        register: 'Register Tax ID',
      },
    },
    typeOfCategory: 'Type of category',
    categoryNameWrongFormat: 'Name must contain only letters, numbers, and spaces.',
    idChangelogCantBeUsed: 'The name changelog cant be used if category is not a changelog.',
    categoryNameAndIconNeeded: 'Name and icon are required.',
    categoryNewNameOrNewIcon: 'New Name or a New Icon is needed.',
    projectSettings: 'Project Settings',
    projectNameHint: 'Name',
    projectTitleHint: 'Title',
    projectOrganizationHint: 'Organization',
    projectPublisherHint: 'Publisher',
    handbook: 'Handbook',
    categories: 'Categories',
    handleCategories: 'Handle categories',
    accessRoles: 'Access Roles',
    access: 'Manage access to categories',
    selectLanguage: 'Select language',
    agroScan: {
      staffAccountAccess: 'Access to Agro Scan content',
      readPolygon: 'List polygons',
      createPolygon: 'Create a new polygon',
      deletePolygon: 'Delete existing polygon',
      createPrescriptionFile: 'Generate prescription files',
      readPrescriptionFile: 'Download prescription files',
      readRequestsHistory: 'View request history',
      readCrudeProtein: 'View Crude Protein analysis results',
      processCrudeProtein: 'Initiate Crude Protein analysis',
      readDryMatter: 'View Dry Matter analysis results',
      processDryMatter: 'Initiate Dry Matter analysis',
      readPlantHealth: 'View Plant Health analysis results',
      processPlantHealth: 'Initiate Plant Health analysis',
      readSoilMoisture: 'View Soil Moisture analysis results',
      processSoilMoisture: 'Initiate Soil Moisture analysis',
      readSoilOrganicCarbon: 'View Soil Organic Carbon analysis results',
      processSoilOrganicCarbon: 'Initiate Soil Organic Carbon analysis',
      readVegetationIndices: 'View Vegetation Indices analysis results',
      processVegetationIndices: 'Initiate Vegetation Indices analysis',
      readWaterStress: 'View Water Stress analysis results',
      processWaterStress: 'Initiate Water Stress analysis',
      crudeProtein: 'Crude Protein',
      dryMatter: 'Dry Matter',
      plantHealth: 'Plant Health',
      soilMoisture: 'Soil Moisture',
      soilOrganicCarbon: 'Soil Organic Carbon',
      vegetationIndices: 'Vegetation Indices',
      waterStress: 'Water Stress',
      potatoAnalysis: 'Potato Analysis',
    },
    import: {
      title: 'Import a document',
      upload: {
        started: 'Started document upload.',
        success: 'Uploaded document successfully',
        failed: 'Document upload failed',
        noError: 'All articles was imported successfully',
        failedBookmark: 'The document import failed with following bookmark: {{error}}',
        loadingImportingDocument: ' | Processing document: {{document}}',
      },
      csvImport: 'Import a CSV file',
      csvFormatError: 'CSV file is not correctly formatted. Each row must contain both an email and a role.',
      exceedingMaxUsers: 'Imported users is more than maximum allowed users.',
    },
    subprojects: {
      title: 'Manage subprojects',
      placeholder: 'Subproject',
      empty: 'No subprojects exist',
      add: 'Add new subproject',
      edit: 'Edit subproject',
      delete: 'Are you sure you want to delete the subproject',
      sorting: {
        index: 'Index',
        id: 'ID',
        name: 'Name',
        logo: 'Logo',
        allowedUsers: 'Allowed User Accounts',
        creationDate: 'Creation Date',
        lastUpdate: 'Last Update',
        admin: 'Admin',
        style: {
          primary: 'Primary colour of projects',
          primaryLight: 'Main menu text colour',
          primaryDark: 'Colour of item menu text',
          accent: 'Style: Accent',
        },
      },
      tooltips: {
        id: 'ID of the subprojects\n\n- Lowercase letters, numbers and underscores\n- No symbols and spaces\n- Must be unique',
        name: 'The name of the subprojects\n\n- Letters and numbers\n- Common symbols\n- Must be unique',
        logo: "Upload an image that serves as the subproject's logo",
        admin: 'Name of the person who owns the subproject',
        style: {
          primary:
            'Change here to change the primary colour of the project, changing this will change the colour of the main menu. The colour is written in hexadecimal(#292f6f).',
          primaryLight:
            'Change here to change the colour of the text in the main menu. The colour is written in hexadecimal(#292f6f).',
          primaryDark:
            'Change here to change the colour of the text in the item selection menu. The colour is written in hexadecimal(#292f6f).',
          accent:
            'The accent color style of the subprojects\n\nThe value is hexadecimal: #xxxxxx\nEach x is a number from 0 to 9 or a letter from A to F',
        },
      },
      contactInfosynk: 'Do you want to increase the license agreement of total users? Contact Infosynk support!',
    },
    projectValues: {
      title: 'Manage values',
      edit: 'Edit',
      newTag: 'New Tag',
      noArticles: 'No articles were found',
      noTags: 'No tags are defined',
      sorting: {
        fId: 'ID',
        name: 'Name',
        category: 'Category',
      },
      toast: {
        setOk: 'Done setting values',
        setFail: 'Failed setting values',
        deleteOk: 'Done resetting values',
        deleteFail: 'Failed resetting values',
      },
      showAllTags: 'Show all tags',
    },
    subprojectAdmin: {
      title: 'Manage Subadministrator',
      hint: 'Specify which categories a subadministrator can handle',
    },
    transfer: {
      title: 'Transfer between projects',
      next: 'NEXT',
      goBack: 'GO BACK',
      confirm: 'CONFIRM',
      projectFrom: 'Select articles from project:',
      projectTo: 'Transfer articles to project:',
      showTable: 'Expand',
      hideTable: 'Collapse',
      started: 'Started transferring articles...',
      finished: 'Finished transferring articles',
    },
  },
  sorting: {
    articleSort: 'Articles',
    sectionSort: 'Sections',
    titleForm: 'Article & Section sorting',
    articleEdit: 'Edit Articles',
    sectionEdit: 'Edit Sections',
    cancelSorting: 'Cancel Sorting',
    saveSorting: 'Save Sorting',
    sortingArticlesHasChanged: ' Sorting has changed',
    sortingSectionsHasChanged: 'Sorting has changed',
    unsavedChanges: 'You have unsaved changes',
    sortingHasChanged: 'Sorting  has changed',
  },
  article: {
    article: 'Article',
    draftModalHeader: 'Not saved content',
    draftModalBody: 'Edited content is not saved.',
    articleSavedOk: 'Article is saved',
    deleteModalHeader: 'Delete article',
    deleteModalBody: 'Are you sure you want to delete this article?',
    deleteModalBodyPerma: 'Are you sure you want to delete this article permanently?',
    deleteOk: 'Article deleted!',
    addOk: 'Article added!',
    sortingSaved: 'Article sorting Saved',
    addNewArticle: 'Add new article',
    wrongArticleName: 'Wrong article name',
    notFoundWarning: 'Article not found!',
    updateOk: 'The article has been updated',
    updateFailed: 'The article update failed',
    fileTooLarge: 'The file is too large. Max 10MB',
    protectedArticle: {
      errorMessage: 'You must be signed in to view this article.',
      errorMessageStaff: 'You must be granted access to view this article.',
    },
  },
  category: {
    addCategory: 'Add category',
    uploadCategoryIcon:
      'Drag an image or pressing "Upload Image" to choose your own image, or select one from our gallery by pressing "Choose from Gallery".',
    category: 'Category',
    lock: 'Pin lock',
    lockCategoryInputHint:
      'Set pin code to protect the category, pin should be numbers between 4 and 6 long.' +
      'to remove the pin provide empty value',
    lockOk: 'Category pin has been set.',
    lockFail: 'Error while setting category pin.',
    login: 'View Content',
    password: 'Enter pin code',
    pinCheckNotValid: 'Pin code is not valid!',
    pinAddNotValid:
      'Provided pin is not valid should be between 4 -6 and only numbers. To remove the pin provide empty value',
    protectOk: 'Category protection changed',
    protectFail: 'Changing Category Protection Failed',
    notFoundWarning: 'Category not found!',
    updated: 'Category updated',
    updateError: 'Error when updating category',
    protectedCategory: {
      chiefErrorMessage: 'You must be an admin to view these articles.',
      defaultErrorMessage: 'You must be granted access to view these articles.',
    },
    categoryNames: {
      articleCategory: 'Standard category',
      html: 'Calculator',
      changelog: 'Changelog',
      url: 'External link',
      workorder: 'Forms',
      nativeModule: 'Mobile module',
    },
    categoryDescription: {
      articleCategory: 'A normal category used for articles and sections',
      url: 'A category that only works on mobile, here the user gets forwarded to a specific URL.',
      html: 'A calculator category, used for medicine calculation, if chosen Softwerk AB will contact you to setup the calculator',
      workorder:
        'In the form module, forms can be created and then filled in by users. Great for checklists or common forms that need to be filled in frequently.',
      changelog: 'In the changelog changes to articles gets stored, good to use together with the notification system.',
      nativeModule: '',
    },
    termsOfUseAddCategory: `Conditions for adding a new category\n\nBy adding a new category to our platform, you acknowledge and agree to the following terms:\n\nPrice and billing:\n\nAn additional charge of SEK {{value}} excluding VAT per year is added for each new category that is added. This price is based on our current price model according to agreement. The price of the new category is discounted for the first year based on the number of days remaining in your subscription period from the date the category is added. The discount is calculated on a daily basis and applied proportionally during the first year to ensure that pricing is properly adjusted for the next subscription period.\n\nBy clicking "Agree" you confirm that you have read, understood and agree to add a new category in accordance with the above terms.`,
    chiefLoading: 'Chief categories loading',
    sortingStarted: 'Saving sorted categories',
    sortingCompleted: 'Categories successfully sorted',
    sortingError: 'Something went wrong in the sorting, please try again',
    editCategoryStarted: 'Saving edited category',
    editCategorySucces: 'Successfully edited category',
    editCategoryFailed: 'Failed to edit category, please try again',
    startedCreatingCategory: 'Category creation started',
    categoryIdExists: 'Category ID already exists, please try again with another name',
    failedCreatingCategory: 'Failed creating category, please try again',
    successCreatingCategory: 'Successfully created category',
  },
  section: {
    section: 'Section',
    defaultSection: 'No section',
    deleteModalHeader: 'Delete section',
    deleteModalBody: 'Deleting this section will also delete the articles listed below:',
    deleteModalBodyPerma: 'Are you sure do you want to delete this section permanently?',
    sectionDeleted: 'Section is deleted!',
    sortingSaved: 'Section Sorting saved',
    addOk: 'Section added',
    addError: 'Adding section failed',
    deleteOk: 'Section deleted',
    wrongSectionName: 'Invalid section name',
    addNewSection: 'Add new section',
    deletingAdditionalArticles: 'ATTENTION! The following articles will be removed:',
  },
  project: {
    notLoaded: 'The project not loaded correctly  or not found.',
    noStaffAccount: 'This project has NO staff account',
  },
  forms: {
    placeHolders: { createNewSection: 'Create new section', sectionName: 'Section Name', articleName: 'Article Name' },
    messages: { valueToShort: 'The value you have entered is to short' },
    uploadImage: 'Upload Image',
    gallerySelect: 'Choose from Gallery',
    saveSortingButton: 'Save sorting',
  },
  search: {
    relevance: 'Relevans',
    alphabetical: 'Alphabetical',
    up: 'Rising',
    down: 'Falling',
    allResults: 'All results',
    seeAll: 'See all results for',
    searchSuggest: 'Search suggestions',
    searchError: 'You did not enter any search terms',
    pageNumberError: 'The number entered is incorrect',
    resultError: 'No article were found matching your selection.',
  },
  versionOutdated: {
    title: 'A new version is available',
    body: 'Some functionlity may break until the new version has been loaded',
    button: 'Load new version',
  },
};

export const sv: LocalizedStrings = {
  auth: {
    signInButton: 'Logga in',
    signOutButton: 'Logga ut',
    registerButton: 'Registera',
    firstNameTitle: 'Förnamn',
    firstNamePlaceholder: 'Ditt förnamn',
    lastNameTitle: 'Efternamn',
    lastNamePlaceholder: 'Ditt efternamn',
    emailTitle: 'E-post',
    emailPlaceholder: 'example@domain.com',
    phoneTitle: 'Telefon',
    phonePlaceHolder: '(NN)NNNN-NNNN',
    phoneErrorDisplay: 'Formatexempel: 1133333333 eller 11999999999',
    phoneAlreadyExists: 'Det angivna telefonnumret används redan av en befintlig användare.',
    passwordTitle: 'Lösenord',
    passwordConfirmTitle: 'Bekräfta Lösenord',
    passwordPlaceholder: 'Lösenord',
    passwordPlaceholderRetype: 'Upprepa lösenord',
    logout: 'Logga ut',
    emailPlaceholder2: 'mejla för att bjuda in',
    invite: 'Bjud in',
    error: {
      unknown: 'Okänt fel',
      invalidCredentials: 'Ogiltigt användarnamn eller lösenord',
      invalidStaffPassword: 'Ogiltigt lösenord för personalkonto',
      invalidCreateAccount: 'Det gick inte att registrera ett ny användarkonto',
      invalidEmailVerification: 'Det gick inte att skickar verifieringsmail',
    },
    wrongProject: 'Du har inte behörighet till det här projektet.',
    staffAccount: 'Kod',
    adminAccount: 'E-post/lösenord',
    adminAccountAddCategory: 'Administratör',
    authenticated: 'Du är inloggad som',
    loggedout: 'Du har loggats ut',
    notAuthenticated: 'Du är inte inloggad',
    notAuthorized: 'Du har inte rättigheter till det här',
    passwordChangeSuccess: 'Lösenordet uppdaterat',
    passwordChangeFailure: 'Lösenordsuppdateringen misslyckades!',
    invalidPasswordFormat:
      'Lösenordet måste innehålla en kombination av små och stora bokstäver, siffror och specialtecken.',
    invalidPasswordLenght: 'Lösenordet måste innehålla mellan 8 och 20 tecken.',
    invalidPasswordRetype: 'Lösenorden matchar inte',
    staffPasswordNotUnique: 'Lösenord för personalkonton måste vara unika',
    welcomeBack: 'Välkommen tillbaka',
    loginToGetAccess: 'Logga in för att få tillgång till plattformen',
    registerToGetAccess: 'Registera för att få tillgång till plattformen',
    forgotPasswordClick: 'Glömt ditt lösenord? Klicka',
    noAccountYetClick: 'Har du inget konto? Klicka',
    here: 'här',
    resetEmailSent: 'Email skickat!',
    resetEmailFail: 'Fel vid emailutskick. Kontakta support.',
    recoveryEmailInfo:
      'Skriv in e-postadressen du använder för att logga in på plattformen. Därefter får du ett mail för att återställa ditt lösenord. Om mailet inte kommer eller du har andra frågor, kontakta Softwerks support',
    resetPassword: 'Återställ lösenord',
    loginToSee: 'Logga in för att få tillgång till innehållet',
    passwordRules: {
      adminHeader: 'Lösenord Administratörskonto',
      staffHeader: 'Lösenord Personalkonto',
      lowercase: 'Minst en liten bokstav',
      uppercase: 'Minst en stor bokstav',
      special: 'Minst ett specialtecken [?,!,$,...]',
      number: 'Minst ett nummer',
      whitespace: 'Inget vitt utrymme',
      adminMin: 'Minst 8 tecken långt',
      staffMin: 'Minst 6 tecken',
      staffMax: 'Max 20 tecken',
    },
    emailVerification: {
      verify: 'Verifiera Email',
      expired: 'Din e-postverifiering har upphört att gälla',
      verified: 'Din e-post är nu verifierad',
      error: 'Fel vid försök att verifiera e-post',
      emailSent: 'Verifieringslänk har skickats via mail.',
      invalidToken: 'Verifieringslänk för e-postadress är ogiltigt eller har löpt ut.',
      emailNotVerified:
        'Din e-postadress är inte verifierad. Du kan verifiera ditt konto genom den verifieringslänk som skickats till din e-postadress, eller om länken har löpt ut kan du få ny länk genom att klicka här.',
      send: 'Skicka verifieringslänk',
    },
    oldPasswordPlaceholder: 'Gammalt lösenord',
    invalidOldPassword: 'Lösenordet är felaktigt.',
    editAccountInfo: 'Redigera kontoinformation',
    submit: 'Skapa',
    welcomeUser: 'Välkommen {{user}}. Vänligen fyll i fälten nedan för att skapa ditt konto.',
  },
  error: {
    error: 'Fel',
    unknown: 'Något gick snett!',
    404: 'Innehållet hittas inte',
  },
  external: {
    externalContent: 'Externt Innehåll',
  },
  adminViewMode: {
    enterPublishMode: 'Se publicerat material',
    onlyPublishedMaterialIsShown: 'endast material som publicerats visas',
    editTip:
      'Ändringar ni sparat i redigeringsläget kan bara ses av administratörer. Endast efter att ni publicerat så kommer era senaste ändringar att bli synliga för icke-administratörer.',
    publishTip:
      'I publiceringsläget så kan ni se det som redan har blivit publicerat. Versionen av kategorier, sektioner, och artiklar är densamma som vad en vanlig användare kan se. Ni kan inte göra ändringar i detta visningsläge.',
  },
  global: {
    only: 'enbart',
    mobile: 'Mobilen',
    website: 'Hemsidan',
    add: 'Lägg till',
    icon: 'Ikon',
    name: 'Namn',
    url: 'URL',
    save: 'Spara',
    doNotSave: 'Spara inte',
    confirm: 'Bekräfta',
    cancel: 'Avbryt',
    reset: 'Återställ',
    edit: 'Redigera',
    view: 'Visa',
    preview: 'Förhandsvisa',
    delete: 'Ta bort',
    loading: 'Laddar...',
    startApplication: 'Startar Applikation...',
    searchResult: 'sökresultat',
    search: 'Sök',
    allResults: 'Alla resultat för',
    feedbackSubject: 'Feedback',
    privacyPolicy: 'Integritetspolicy',
    termsOfUse: 'Villkor',
    fileFromatError: 'Filformat stöds inte',
    availableOn: 'Tillgänglig på',
    print: 'Skriv ut',
    create: 'Skapa',
    clear: 'Stäng',
    ok: 'OK',
    send: 'Skicka',
    online: 'Du är online',
    offline: 'Du är offline. Viss funktionalitet är begränsad.',
    clickToReload: 'Ladda om komponent',
    clickToReloadFull: 'Ladda om sidan',
    linkTo: 'Länk till',
    linkCopied: 'Länken har kopierats!',
    insertAnchorlink: 'Infoga ankarlänk',
    homePage: 'Hemsida',
    fullscreen: 'Fullskärm',
    feedback: {
      feedback: 'Feedback',
      feedbackAndPrint: 'Feedback & Skriv ut',
      send: 'Skicka feedback',
      noFeedbackEmailExist: 'Ingen feedback-e-post är registrerad',
    },
    addurl: 'Bädda in externt innehåll',
    iframTooltip: 'Du kan ange ditt värde i pixlar eller procent. Skriv % eller px efter värdet. Standardvärde:',
    youAreIn: 'Du är i',
    selectAll: 'Välj alla',
  },
  publish: {
    publish: 'Publicera',
    publishing: 'Publicerar',
    start: 'Publicering påbörjad!',
    publishOk: 'Publicering klar!',
    publishFail: 'Publicering misslyckades',
    publishMode: 'Publiceringsläge',
    editMode: 'Redigeringsläge',
    defaultPublishMessage: 'Nytt innehåll har publicerats',
    setPublishMessage: 'Publiceringsmeddelande',
    publishWithNotification: 'Publicera med notis',
    linksWithIssues: 'Länkar med problem',
    imagesWithIssues: 'Bilder med problem',
    titles: {
      lastUpdatedDate: 'Datum',
      lastUpdated: 'Senast ändrad',
      lastUpdatedBy: 'Senast ändrad av',
      name: 'Artikel',
      state: 'Status',
    },
    editStates: {
      created: 'Tillagd',
      deleted: 'Borttagen',
      updated: 'Uppdaterad',
    },
    help: {
      title: 'Hjälp',
      whatIsIncludedWhenPublishing: {
        title: 'Vad finns med i en publicering?',
        articles:
          'I listan på denna sida syns alla artiklar som innehåller ändringar jämfört med det som redan är publicerat. Endast de artiklar som blivit avbockade i listan kommer att publiceras. Oavsett vilka artiklar som är valda (och även om inga artiklar är valda) så kommer ändringar av följande ALLTID finnas med i publiceringen:',
        alwaysIncludedList: [
          'Låst/upplåst-status av vanliga kategorier',
          'Projektkonfiguration',
          'Sektioner',
          'Artikelordning i sektioner',
        ],
        lastUpdatedBy: 'Namnet på Admin som senast redigerat en artikel är aldrig synligt för icke-Admins.',
      },
      sidebarSettings: {
        title: 'Publiceringsinställningar',
        publishMessage: 'Publiceringsmeddelandet finns med i ändringsloggen, och är den text som visas i pushnotisen.',
        sendNotification:
          '"Publicera med notis" innebär att en pushnotis skickas till alla mobiler och surfplattor som har appen installerad.',
      },
      changelog: {
        title: 'Ändringslogg',
        content:
          'Om er applikation har en ändringslogg så skapas det en artikel med information om ändringarna i och med en publicering. I denna artikel visas bland annat publiceringsmeddelandet och vilka artiklar som har skapats, uppdaterats eller tagits bort sedan senaste publiceringen.',
      },
    },

    noArticlesHaveBeenEdited: 'Inga artiklar har redigerats, lagts till, eller tagits bort sedan senaste publicering!',
    noStaffAccountsExist: 'Det finns inga personalkonton att notifiera!',
    noRoleExist: 'Det finns inga roller att meddela!',
    atClock: 'kl',
    publishMessagePlaceholder: 'Publiceringsmeddelande',
    errorLoadingAccounts: 'Kunde inte ladda användarkonton!',
    notificationSettings: 'Notis-inställningar',
    notifyAll: 'Meddela alla användare',
    chooseTargets: 'Välj användare att meddela',
  },
  settings: {
    transferArticle: {
      moveSection: 'Flytta Sektion',
      moveArticle: 'Flytta Artikel',
      articleTransfer: 'Förflytta mellan kategorier',
      transferArticleHeaderDesc: 'Välj de två kategorierna mellan vilka du vill flytta sektioner eller artiklar.',
      transferArticleDescription:
        'Att överföra en sektion flyttar alla motsvarande artiklar till den sektionen, flyttas en enskild artikel flyttas endast den artikeln till ',
      publishing: 'Att flytta artiklar eller sektioner kräver en publicering och kommer att hamna i ändringsloggen.',
      selectCategoryOne: 'Välj kategori ett',
      selectCategoryTwo: 'Välj kategori två',
    },
    roles: {
      settingItemTitle: 'Rollkonfiguration',
      hint: 'Nedan kan du justera rollerna',
      noRoleExists: 'Det finns ingen roll',
      changeAccess: 'Ändra åtkomst till kategorier',
      createRole: 'Skapa roll',
      createRoleSuccess: 'Roll skapades',
      createRoleError: 'Något gick fel vid skapandet av roll, vänligen försök igen!',
      editRole: 'Rollen blev redigerad',
      roleName: 'Rollnamn',
      save: 'Spara',
      roleNameError: 'Ogiltigt rollnamn',
      categoriesTitle: 'Hantera åtkomst till kategorier',
      workOrdersSubTitle: 'Tillåt åtkomst till alla startade och stängda formulär',
      workOrdersTitle: 'Tillgång till arbetsordersystemet',
      roleNameFormat: {
        line1: 'Rollnamnet får endast innehålla engelska bokstäver, siffror, -, _',
        line2: 'Rollnamnet får inte ha specialtecken',
        line3: 'Rollnamnet får inte börja eller sluta med blanksteg',
      },
      delete: {
        modalHeader: 'Ta bort rollen',
        modalBody: 'Är du säker på att du vill ta bort den här rollen?',
        error: 'Det gick inte att ta bort rollen',
      },
    },
    multiStaffAccount: {
      displayName: 'Namn',
      projectId: 'Projekt',
      createdAt: 'Skapad vid',
      status: 'Status',
      email: 'E-post',
      addAccount: 'Lägg till ett konto via e-postadress',
      role: 'Roll',
      errorEmailRole: 'Kontrollera e-post och roll',
      invitationSuccess: 'Användaren är nu inbjuden',
      importedMailsCount: 'E-postadresser importerade',
      inviteAll: 'Bjud in alla',
      inviteOne: 'Bjud in en användare',
      reinvite: 'Skicka inbjudan igen',
      reinviteTooltip: 'Skicka inbjudningar igen till väntande användare',
      delete: 'Ta bort användare',
      deleteTooltip: 'Ta bort de valda användarna',
      exportCSV: 'Exportera som CSV',
      exportCSVTooltip: 'Exportera valda användare till en CSV-fil',
      changeRole: 'byta roll till:',
      editRole: 'Redigera roller',
      editRoleTooltip: 'Ändra roll för de valda användarna',
      forgotPassword: 'Glömt ditt lösenord? Klicka här för att återställa ditt lösenord!',
      confirmDeleteAccounts: 'Är du säker på att du vill ta bort alla dessa konton',
      deleteAccounts: 'Ta bort konton',
      deleteAccountSuccess: 'lyckades radera konto',
      deleteAccountFailure: 'misslyckade att radera konto',
      deleteAccountsSuccess: 'lyckades radera konton',
      deleteAccountsFailure: 'misslyckade att radera konton',
      sendInvitationSuccess: 'lyckades skicka inbjudan',
      sendInvitationFailure: 'misslyckade att skicka inbjudan',
      sendInvitationDuplicate: 'duplicerad e-post',
      sendInvitationsSuccess: 'lyckades att skicka inbjudningar',
      sendInvitationsFailure: 'misslyckade att skicka inbjudningar',
      sendInvitationsDuplicate: 'duplicerad e-post',
    },
    provideUrl: 'Vänligen tillgodose en URL',
    accountRole: 'Välj roll',
    settings: 'Inställningar',
    email: 'E-post-adresser för feedback',
    emailSettings: 'E-post inställningar',
    addEmailsHint: 'Lägg till e-post som feedback-mail',
    categoryAccessHint: 'Åtkomst för låsta kategorier måste ges per konto. Upplåsta kategorier kan ses av allmänheten.',
    updateOk: 'Ändringen har sparats',
    updateFailed: 'Ändringen misslyckades',
    emailAlreadyExist: 'E-postadressen finns redan',
    staffAccountMng: 'Personalkonton',
    staffAccountCategories: 'Ändra åtkomst till låsta kategorier',
    staffAccountCategoriesPlaceholder: 'Alla kategorier är upplåsta!',
    staffAccountHasOngoingSubscription: 'Kontot har redan en aktiv prenumeration.',
    accountMng: 'Ditt konto',
    selectAccount: 'Välj ett konto att redigera',
    confirmDeleteAccount: 'Är du säker på att du vill ta bort kontot',
    deleteAccount: 'Ta bort konto',
    deleteAccountSuccess: 'Kontot har tagits bort',
    deleteAccountFailure: 'Kontot har inte tagits bort',
    emptyAccountSearch: 'Inga resultat!',
    changeAdminPass: 'Ändra lösenord för administratörkonto',
    changeStaffPass: 'Ändra lösenord för personalkonto',
    passChangeSuccess: 'Lösenordet har ändrats!',
    passChangeFail: 'Byte av lösenord misslyckades',
    emailError: 'Fel i e-postadressen',
    export: {
      create: 'Skapa ny export',
      download: 'Ladda ner export',
      delete: 'Radera export',
      deleteFinished: 'Export raderad',
      exportStarted: 'Påbörjar export...',
      exportFinished: 'Exporten har skapats!',
      generating: 'Skapar export...',
      hint: 'Exportera den senaste publicerade versionen av din webb-app som går att använda helt offline. Senaste exporten lagras och kan laddas ner flera gånger.',
      title: 'Exportera',
      notFound: 'Ingen export har skapats ännu',
      failedToDownload: 'Nedladdningen av zip-filen misslyckades!',
      exportAll: 'Exportera allt',
    },
    userProfile: {
      title: 'Användarprofil',
      account: {
        title: 'Konto',
        firstName: 'Förnamn',
        lastName: 'Efternamn',
        email: 'E-post',
        phone: 'Telefon',
      },
      statistics: {
        title: 'Statistik',
        name: 'Namn',
        benefactor: 'Välgörare',
        polygons: 'Polygoner',
        hectares: 'Hektar',
        hectaresUsed: 'Använda hektar',
        hectaresAvailable: 'Tillgängliga hektar',
        acres: 'Acres',
        emptyPolygons: 'Inga polygoner hittades.',
      },
      subscription: {
        title: 'Prenumeration',
        level: 'Prenumerationsnivå är',
        noSubscription: 'Inget abonnemang',
        perks: {
          title: 'Förmåner',
          unlimited: 'Obegränsad analys',
          total: 'Totalt',
        },
        cost: 'Kostnad',
        month: 'Månad',
        year: 'År',
      },
    },
    subscriptions: {
      noSubscription: 'Du har ingen prenumeration.',
      manageBillingInformation:
        'Klicka här för att hantera betalningsmetod, faktureringsinformation och fakturahistorik.',
      subscriptionUntil: 'Du har en prenumeration fram till',
      stripeProductId: 'Stripe produktID',
      hectaresLimit: 'Begränsning på hektar',
      mostPopular: 'Mest populär',
      noSubscriptionsExist: 'Det finns inga prenumerationer.',
      getSubscription: 'Välj prenumeration',
      upgradeSubscription: 'Uppgradera prenumeration',
      subscribeSuccess: 'Prenumerationen lyckades.',
      subscribeError: 'Misslyckades med att behandla din prenumeration.',
      cancelSubscriptionSuccess: 'Prenumerationen avbröts.',
      cancelSubscriptionError: 'Misslyckades med att avbryta prenumerationen.',
      downgradeStubscriptionHeader: 'Nedgradera abonnemang',
      downgradeStubscription:
        'Du har använt {{usedHectares}} hektar och gränsen för ditt valda abonnemang är {{newLimit}} hektar. För att nedgradera din prenumeration, se till att du tar bort polygoner med en yta på {{difference}} hektar',
      loadingPayWall: 'Laddar betalningsformuläret.',
      processSession: 'Bearbetar betalning. Stäng/uppdatera inte fönstret',
      //TODO: Validate translation
      sort: 'Sortera prenumerationer',
      custom: {
        contactUs: 'Kontakta oss',
        title: 'Anpassad',
        description:
          'Du har möjlighet att anpassa din åtkomst och begränsningar enligt dina specifika behov. För att skapa din personliga plan, kontakta oss enkelt på',
      },
      configuration: {
        title: 'Konfiguration av prenumeration',
        hint: 'Här kan du justera prenumerationerna',
        add: 'Lägg till ny prenumeration',
      },
      create: {
        title: 'Skapa prenumeration',
        success: 'Prenumerationen lades till.',
        error: 'Misslyckades med att lägga till prenumerationen.',
        stripeProductIdError: 'En Stripe-produkt med det angivna ID:t existerar inte.',
        alreadyExists: 'En prenumeration med det angivna Stripe-produkt-ID:t finns redan.',
      },
      payment: {
        title: 'Köp prenumeration',
      },
      list: {
        title: 'Prenumerationer',
        monthly: 'Månadsvis',
        yearly: 'Årlig',
      },
      edit: {
        title: 'Redigera prenumeration',
        success: 'Prenumerationen redigerades.',
        error: 'Misslyckades med att redigera prenumerationen.',
      },
      delete: {
        modalHeader: 'Ta bort prenumeration',
        modalBody: 'Är du säker på att du vill ta bort prenumerationen?',
        success: 'Prenumerationen togs bort.',
        error: 'Misslyckades med att ta bort prenumerationen.',
      },
      cancel: {
        modalHeader: 'Avbryt prenumerationsplan',
        modalBody: 'Är du säker på att du vill avsluta din prenumeration?',
        yes: 'Ja, avbryt ändå',
        no: 'Nej',
      },
      tax: {
        modalHeader: 'Skatte-ID',
        id: 'Ange ditt skatte-ID-nummer här',
        example: 'Exempel: brasilianskt CNPJ-nummer 01.234.456/5432-10 / CPF-nummer 123.456.789-87.',
        feedback: 'Skatte-ID följer inte CPF/CNPJ-formatet!',
        accept: 'Gärna acceptera',
        termsOfUse: 'villkor och betingelser',
        register: 'Registrera skatte-ID',
      },
    },
    typeOfCategory: 'Typ av kategori',
    categoryNameWrongFormat: 'Namnet får endast innehålla bokstäver, siffror och mellanslag.',
    idChangelogCantBeUsed: 'Namnet "changelog" kan inte användas om kategorin inte är en ändringslogg.',
    categoryNameAndIconNeeded: 'Namn och ikon krävs',
    categoryNewNameOrNewIcon: ' Ett nytt namn eller ikon krävs.',
    projectSettings: 'Namnval',
    projectNameHint: 'Applikationens namn',
    projectTitleHint: 'Visningsnamn bredvid logga',
    projectPublisherHint: 'Innehålls- och publiceringsansvarig',
    projectOrganizationHint: 'Organisation',
    handbook: 'Handbok',
    categories: 'Kategorier',
    handleCategories: 'Hantera kategorier',
    accessRoles: 'Åtkomstroller',
    access: 'Hantera åtkomst till kategorier',
    selectLanguage: 'Välj språk',
    noChangesMade: 'Inga ändringar gjorda',
    agroScan: {
      staffAccountAccess: 'Åtkomst till Agro Scan-innehåll',
      readPolygon: 'Lista polygoner',
      createPolygon: 'Skapa en ny polygon',
      deletePolygon: 'Ta bort befintlig polygon',
      createPrescriptionFile: 'Generera precisionsfil',
      readPrescriptionFile: 'Ladda ner precisionsfil',
      readRequestsHistory: 'Visa historik',
      readCrudeProtein: 'Visa resultat analys av råprotein',
      processCrudeProtein: 'Initiera analys av råprotein',
      readDryMatter: 'Visa resultat analys av torrsubstans',
      processDryMatter: 'Initiera analys av torrsubstans',
      readPlantHealth: 'Visa resultat analys av växters hälsa',
      processPlantHealth: 'Initiera analys av växters hälsa',
      readSoilMoisture: 'Visa resultat analys av markfuktighet',
      processSoilMoisture: 'Initiera analys av markfuktighet',
      readSoilOrganicCarbon: 'Visa resultat analys av organiskt kol i marken',
      processSoilOrganicCarbon: 'Initiera analys av organiskt kol i marken',
      readVegetationIndices: 'Visa resultat analys av vegetationindex',
      processVegetationIndices: 'Initiera analys av vegetationindex',
      readWaterStress: 'Visa resultat analys av vattenstress',
      processWaterStress: 'Initiera analys av vattenstress',
      crudeProtein: 'crudeProtein',
      dryMatter: 'dryMatter',
      plantHealth: 'plantHealth',
      soilMoisture: 'soilMoisture',
      soilOrganicCarbon: 'soilOrganicCarbon',
      vegetationIndices: 'vegetationIndices',
      waterStress: 'waterStress',
      potatoAnalysis: 'potatoAnalysis',
    },
    import: {
      title: 'Importera ett dokument',
      upload: {
        started: 'Påbörjade dokumentuppladdning',
        success: 'Dokumentet har laddats upp',
        failed: 'Dokumentuppladdning misslyckades',
        noError: 'Alla artiklar importerades framgångsrikt',
        failedBookmark: 'Importen av dokumentet misslyckades med följande bokmärken: {{error}}',
        loadingImportingDocument: ' | Bearbetar dokument: {{document}}',
      },
      csvImport: 'Importera en CSV-fil',
      csvFormatError:
        'CSV-filen är inte korrekt formaterad. Varje rad måste innehålla både ett e-postmeddelande och en roll.',
      exceedingMaxUsers: 'Importerade användare är fler än det högsta tillåtna antalet användare.',
    },
    subprojects: {
      title: 'Hantera projekt',
      placeholder: 'Underproject',
      empty: 'Inga underprojekter finns',
      add: 'Lägg till nytt subprojekt',
      edit: 'Redigera projekt',
      delete: 'Är du säker på att du vill ta bort subprojektet',
      sorting: {
        index: 'Index',
        id: 'ID',
        name: 'Namn',
        logo: 'Logo',
        allowedUsers: 'Tillåtna användarkonton',
        creationDate: 'Skapad',
        lastUpdate: 'Senast Uppdaterad',
        admin: 'Ägare',
        style: {
          primary: 'Primärfärg av projekt',
          primaryLight: 'Huvudmeny text färg',
          primaryDark: 'Färg av artikel meny text',
          accent: 'Stil: Accent',
        },
      },
      tooltips: {
        id: 'ID på subprojekten\n\n- Småa bokstaver, siffror och understryka\n- Inga symboler och mellansslag\n- Ska vara unik',
        name: 'Namnet på subprojekten\n\n- Bokstaver och siffror\n- Allmänna symboler\n- Ska vara unik',
        logo: 'Uppladda ett bild som fungerar som subprojektens logot',
        admin: 'Namn på personen som äger subprojektet',
        style: {
          primary:
            'Ändra här för att byta den primära färgen för projektet, ändring av denna kommer att ändra huvudmenyns färg. Färgen skrivs i hexadecimal(#292f6f).',
          primaryLight: 'Ändra här för att byta färg på texten i huvudmenyn. Färgen skrivs i hexadecimal(#292f6f).',
          primaryDark:
            'Ändra här för att byta färg på texten i menyn av val av artikel. Färgen skrivs i hexadecimal(#292f6f).',
          accent:
            'Den accent färgstilen till subprojekten\n\nVärdet är hexadecimal: #xxxxxx\nVarje x är ett siffra från 0 till 9 eller en bokstav från A till F',
        },
      },
      contactInfosynk: 'Vill du utöka licensavtalet på antal användare? Kontakta Infosynk support!',
    },
    projectValues: {
      title: 'Hantera värden',
      edit: 'Redigera',
      newTag: 'Ny Tag',
      noArticles: 'Inga artiklar hittades',
      noTags: 'Inga taggar är definierade',
      sorting: {
        fId: 'ID',
        name: 'Namn',
        category: 'Kategori',
      },
      toast: {
        setOk: 'Värden är uppdaterade',
        setFail: 'Misslyckades sätta värden',
        deleteOk: 'Färdig återställa värden',
        deleteFail: 'Misslyckades återställa värden',
      },
      showAllTags: 'Visa alla taggar',
    },
    subprojectAdmin: {
      title: 'Hantera Underadministratör',
      hint: 'Specificera vilka kategorier en underadministratör kan hantera',
    },
    transfer: {
      title: 'Överföra mellan projekt',
      next: 'NÄSTA',
      goBack: 'GÅ TILLBAKA',
      confirm: 'BEKRÄFTA',
      projectFrom: 'Välj artiklar från projekt:',
      projectTo: 'Överföra artiklar till projekt:',
      showTable: 'Utöka',
      hideTable: 'Dölj',
      started: 'Artiklar överförs...',
      finished: 'Artiklar har nu överförts',
    },
  },
  sorting: {
    articleSort: 'Artiklar',
    sectionSort: 'Sektioner',
    titleForm: 'Artiklar&Sektioner sortering',
    articleEdit: 'Redigera artiklar',
    sectionEdit: 'Redigera sektioner',
    cancelSorting: 'Avbryt',
    saveSorting: 'Spara',
    sortingArticlesHasChanged: 'Sortering har ändrats!',
    sortingSectionsHasChanged: 'Sortering har ändrats!',
    unsavedChanges: 'Du har osparade ändringar i sorteringen',
    sortingHasChanged: 'Sortering har ändrats',
  },
  article: {
    article: 'Artikel',
    draftModalHeader: 'Innehåller osparat innehåll',
    draftModalBody: 'Redigerat innehåll har inte sparats.',
    articleSavedOk: 'Artikeln sparad',
    deleteModalHeader: 'Radera artikel',
    deleteModalBody: 'Vill du verkligen radera artikeln?',
    deleteModalBodyPerma: 'Vill du verkligen radera artikeln permanent?',
    deleteOk: 'Artikel raderad',
    addOk: 'Artikel har lagts till',
    sortingSaved: 'Artikelsortering sparad',
    addNewArticle: ' Artikel',
    wrongArticleName: 'Artikelnamnet är inte giltigt',
    notFoundWarning: 'Artikeln hittades inte!',
    updateOk: 'Artikeln har uppdaterats',
    updateFailed: 'Artikeluppdateringen misslyckades',
    fileTooLarge: 'Filen är för stor. Max 10MB',
    protectedArticle: {
      errorMessage: 'För att kunna se denna sida måste du vara inloggad.',
      errorMessageStaff: 'För att kunna se denna sida måste du ha tillstånd.',
    },
  },
  category: {
    addCategory: 'Lägg till kategori',
    uploadCategoryIcon:
      'Dra en bild eller tryck på "Ladda upp Bild" för att välja din egen bild, eller välj en från vårt galleri genom att trycka på "Välj från Galleri"',
    category: 'Kategori',
    lock: 'Pin lock',
    lockCategoryInputHint:
      'Set pin code to protect the category, pin should be numbers between 4 and 6 long.' +
      'to remove the pin provide empty value',
    lockOk: 'Category pin has been set.',
    lockFail: 'Error while setting category pin.',
    login: 'View Content',
    password: 'Enter pin code',
    pinCheckNotValid: 'Pin code is not valid!',
    pinAddNotValid:
      'Provided pin is not valid should be between 4 -6 and only numbers. To remove the pin provide empty value',
    protectOk: 'Behörighet för kategori uppdaterad',
    protectFail: 'uppdatering av kategoribehörighet, misslyckades',
    notFoundWarning: 'Kategori hittades inte!',
    updated: 'Kategori uppdaterad',
    updateError: 'Fel i kategoriuppdatering',
    protectedCategory: {
      chiefErrorMessage: 'För att kunna se dessa artiklar måste du vara administratör.',
      defaultErrorMessage: 'För att kunna se dessa artiklar måste du ha tillstånd.',
    },
    categoryNames: {
      html: 'Kalkylator',
      changelog: 'Ändringslogg',
      url: 'Extern länk',
      workorder: 'Formulärsmodul',
      articleCategory: 'Standardkategori',
      nativeModule: 'Mobil modul',
    },
    categoryDescription: {
      articleCategory: 'En vanlig kategori som används för ert innehåll.',
      url: 'En kategori som endast fungerar på mobil, här omdirigeras användaren till en specifik URL.',
      html: 'En kalkylatorkategori, används för beräkning av medicin, om vald kommer Softwerk AB att kontakta dig för att konfigurera er kalkylator.',
      workorder:
        'I formulärsmodulen kan formulär skapas och sedan fyllas i av användare. Bra för checklistor eller vanliga formulär som måste fyllas i ofta.',
      changelog:
        'I ändringsloggen lagras ändringar av artiklar, bra att använda tillsammans med notifikationssystemet.',
      nativeModule: '',
    },
    termsOfUseAddCategory: `Villkor för att lägga till en ny kategori\n\nGenom att lägga till en ny kategori på vår plattform bekräftar och godkänner du följande villkor:\n\nPris och debitering:\n\nEn extra debitering på {{value}} kr exklusive moms per år tillkommer för varje ny kategori som läggs till. Detta pris baseras på vår aktuella prismodell enligt avtal. Priset för den nya kategorin rabatteras för det första året baserat på antalet dagar som återstår av din prenumerationsperiod från och med datumet då kategorin läggs till. Rabatten beräknas på en daglig basis och tillämpas proportionellt under det första året för att säkerställa att prissättningen är korrekt justerad inför nästa prenumerationsperiod.\n\nGenom att klicka på "Godkänn" bekräftar du att du har läst, förstått och godkänt att lägga till en ny kategori i enlighet med ovanstående villkor.`,
    chiefLoading: 'Admin kategorier laddar',
    sortingStarted: 'Sparar sortering',
    sortingCompleted: 'Sortering lyckades',
    sortingError: 'Något gick fel, vänligen försök igen',
    editCategoryStarted: 'Sparar redigerad kategori',
    editCategorySucces: 'Redigering av kategori lyckades',
    editCategoryFailed: 'Misslyckades att uppdatera kategori, vänligen försök igen',
    startedCreatingCategory: 'Sparar ny kategori',
    categoryIdExists: 'Kategori ID finns redan, vänligen försök med ett annat namn',
    failedCreatingCategory: 'Misslyckades att skapa kategori, vänligen försök igen',
    successCreatingCategory: 'Skapande av kategori lyckades',
  },
  section: {
    section: 'Sektion',
    defaultSection: 'Ingen sektion',
    deleteModalHeader: 'Radera Sektion',
    deleteModalBody: 'Radering av detta avsnitt kommer också att radera de artiklar som anges nedan:',
    deleteModalBodyPerma: 'Vill du verkligen radera sektionen permanent?',
    sectionDeleted: 'Sektionen raderad',
    sortingSaved: 'Sektionssortering sparad',
    addOk: 'Sektion har lagts till',
    addError: 'Kunde inte lägga till sektion',
    deleteOk: 'Sektion raderad',
    addNewSection: ' Sektion',
    wrongSectionName: 'Sektionsnamnet är inte giltigt',
    deletingAdditionalArticles: 'ATTENTION! The following articles will be removed:',
  },
  project: {
    notLoaded: 'Projektet laddades inte in korrekt eller hittades inte',
    noStaffAccount: 'Projektet här ingen personalkonto',
  },
  forms: {
    placeHolders: { createNewSection: 'Lägg till ny sektion', sectionName: 'Sektionsnamn', articleName: 'Artikelnamn' },
    messages: { valueToShort: 'The value you have entered is to short' },
    uploadImage: 'Ladda upp Bild',
    gallerySelect: 'Välj från Galleri',
    saveSortingButton: 'Spara sortering',
  },
  search: {
    relevance: 'Relevans',
    alphabetical: 'Alfabetiskt',
    up: 'Stigande',
    down: 'Fallande',
    allResults: 'Alla resultat',
    seeAll: 'Se alla resultat för',
    searchSuggest: 'Sökförslag',
    searchError: 'Du har inte angett några söktermer',
    pageNumberError: 'Det angivna numret är felaktigt',
    resultError: 'Ingen artikel hittades som matchade ditt val.',
  },
  versionOutdated: {
    title: 'En ny version av är tillgänglig.',
    body: 'Viss funktionalitet kan sluta fungera tills den nya versionen laddats in.',
    button: 'Ladda ny version',
  },
};

export const lv: LocalizedStrings = {
  auth: {
    signInButton: 'Pierakstīties',
    signOutButton: 'Izrakstīties',
    registerButton: 'Reģistrēties',
    firstNameTitle: 'Vārds',
    firstNamePlaceholder: 'Jūsu vārds',
    lastNameTitle: 'Uzvārds',
    lastNamePlaceholder: 'Tavs uzvārds',
    emailTitle: 'E-pasts',
    emailPlaceholder: 'jūsu@epasts.lv',
    phoneTitle: 'Tālrunis',
    phonePlaceHolder: '(NN)NNNN-NNNN',
    phoneErrorDisplay: 'Formāta piemērs: 1133333333 vai 11999999999',
    phoneAlreadyExists: 'Piedāvātais tālruņa numurs jau tiek izmantots esoša lietotāja.',
    passwordTitle: 'Parole',
    passwordConfirmTitle: 'Apstipriniet Paroli',
    passwordPlaceholder: 'Parole',
    passwordPlaceholderRetype: 'Atkārtoti ievadiet paroli',
    logout: 'Iziet',
    emailPlaceholder2: 'e-pastu uzaicināt',
    invite: 'Uzaiciniet šo lietotāju',
    error: {
      unknown: 'Nezināma kļūda',
      invalidCredentials: 'Nederīgs e-pasts/parole',
      invalidStaffPassword: 'Nederīga darbinieka konta parole',
      invalidCreateAccount: 'Nevarēja reģistrēt jaunu lietotāja kontu',
      invalidEmailVerification: 'Nevarēja nosūtīt verifikācijas e-pastu',
    },
    wrongProject: 'Jūs nevarat pieslēgties šim projektam!',
    staffAccount: 'Kods',
    adminAccount: 'E-pasts/parole',
    adminAccountAddCategory: 'Administrators',
    authenticated: 'Jūs esat pieslēdzies kā',
    loggedout: 'Jūs esat izgājis',
    notAuthenticated: 'Jūs neesat pieslēdzies',
    notAuthorized: 'Jūs neesat pilnvarots',
    passwordChangeFailure: 'Neizdevās atjaunināt paroli',
    passwordChangeSuccess: 'Parole atjaunināta',
    invalidPasswordFormat: 'Parolei jāsatur mazo un lielo burtu, ciparu un speciālo rakstzīmju kombinācija.',
    invalidPasswordLenght: 'Parolei jāsatur no 8 līdz 20 rakstzīmēm.',
    invalidPasswordRetype: 'Paroles nesakrīt!',
    staffPasswordNotUnique: 'Personāla konta parolei jābūt unikālai',
    welcomeBack: 'Laipni lūdzam atpakaļ',
    loginToGetAccess: 'Pieslēdzieties, lai piekļūtu platformai',
    registerToGetAccess: 'Reģistrējieties, lai piekļūtu platformai',
    forgotPasswordClick: 'Aizmirsāt paroli?” Klikšķiniet',
    noAccountYetClick: 'Vai jums vēl nav konta? Noklikšķiniet',
    here: 'šeit',
    resetEmailSent: 'E-pasta vēstule ir nosūtīta. Pārbaudiet savu iesūtni',
    resetEmailFail: 'Nezināma problēma. E-pasts nav nosūtīts, sazinieties ar atbalsta dienestu.',
    recoveryEmailInfo:
      'Ievadiet savu e-pasta adresi, kuru izmantojat, lai pierakstītos platformā, un saņemiet paroles atjaunošanas e-pastu. Ja nesaņemat e-pasta vēstuli vai ja jums ir citi jautājumi, sazinieties ar Softwerk atbalsta dienestu',
    resetPassword: 'Atjaunot paroli',
    loginToSee: 'Pieslēdzieties, lai piekļūtu saturam',
    passwordRules: {
      adminHeader: 'Administratora konta parole',
      staffHeader: 'Personāla konta parole',
      lowercase: 'Vismaz viens mazais burts',
      uppercase: 'Vismaz viens lielais burts',
      special: 'Vismaz viens īpašais simbols [?,!,$,...]',
      number: 'Vismaz viens skaitlis',
      whitespace: 'Bez atstarpēm',
      adminMin: 'Vismaz 8 rakstzīmes',
      staffMin: 'Vismaz 6 rakstzīmes',
      staffMax: 'Ne vairāk kā 20 rakstzīmju',
    },
    emailVerification: {
      verify: 'Apstipriniet e-pastu',
      expired: 'Jūsu e-pasta verifikācijas termiņš ir beidzies',
      verified: 'Jūsu e-pasts tagad ir pārbaudīts',
      error: 'Mēģinot verificēt e-pastu, radās kļūda',
      emailSent: 'Nosūtīts verifikācijas e-pasts.',
      invalidToken: 'E-pasta verifikācijas pilns ir nederīgs vai beidzies.',
      emailNotVerified:
        'Jūsu e-pasts nav verificēts. Jūs varat verificēt savu kontu, izmantojot saņemto verifikācijas saiti savā e-pastā, vai, ja saite ir beigusies, varat to atkārtoti nosūtīt šeit.',
      send: 'Nosūtīt verifikācijas e-pastu',
    },
    oldPasswordPlaceholder: 'Vecā parole',
    invalidOldPassword: 'Parole ir nepareiza.',
    editAccountInfo: 'Rediģēt konta informāciju',
    submit: 'Iesniegt',
    welcomeUser: 'Laipni lūgti vietnē {{user}}. Lūdzu, aizpildiet tālāk norādītos laukus, lai izveidotu savu kontu.',
  },
  error: {
    error: 'Kļūda',
    unknown: 'Kaut kas notika nepareizi!!',
    404: 'Saturs nav atrasts',
  },
  external: {
    externalContent: 'Ārējais saturs',
  },
  adminViewMode: {
    enterPublishMode: 'Skatīt publicēto saturu',
    onlyPublishedMaterialIsShown: 'tiek rādīts tikai publicētais saturs',
    editTip:
      'Šajā režīmā veiktās izmaiņas var skatīt tikai administratori. Jaunās izmaiņas lietotājiem, kas nav administratori, būs redzamas tikai pēc publicēšanas.',
    publishTip:
      'Šajā režīmā varat skatīt tikai publicētus materiālus. Redzamās versiju kategorijas, sadaļas un raksti ir tādi paši, kādus redzētu lietotājs, kas nav administrators. Šajā režīmā nevarat veikt nekādas izmaiņas.',
  },
  global: {
    only: 'tikai',
    mobile: 'Mobilais',
    website: 'Vietne',
    add: 'Pievienot',
    icon: 'Ikona',
    name: 'Vārds',
    url: 'URL',
    save: 'Saglabāt',
    doNotSave: 'Nesaglabāt',
    confirm: '  Apstiprināt',
    cancel: 'Atcelt',
    reset: 'Atiestatīt',
    edit: 'Rediģēt',
    preview: 'Priekšskatīt',
    view: 'Skatīt',
    delete: 'Dzēst',
    loading: 'Notiek ielāde...',
    startApplication: 'Sākt lietojumprogrammu…',
    searchResult: 'Meklēšanas rezultāts',
    search: 'Meklēt',
    allResults: 'Visi rezultāti par',
    feedbackSubject: 'Atsauksmju',
    privacyPolicy: 'Privātuma politika',
    termsOfUse: 'Juridiskie dokumenti',
    fileFromatError: 'Datnes paplašinājums nav atbalstīts',
    print: 'Drukāt',
    create: 'Izveidot',
    clear: 'Notīrīt',
    availableOn: 'Pieejams',
    ok: 'Labi',
    send: 'Sūtīt',
    online: 'Jūs esat tiešsaistē',
    offline: 'Jūs esat bezsaistē. Dažas funkcijas ir ierobežotas.',
    clickToReload: 'Pārlādēt sadaļu',
    clickToReloadFull: 'Pārlādēt lapu',
    linkTo: 'Saite uz',
    linkCopied: 'Saite ir nokopēta jūsu starpliktuvē',
    insertAnchorlink: 'Ievietot enkura saiti',
    homePage: 'Sākuma lapa',
    fullscreen: 'Pilna ekrāna',
    feedback: {
      feedback: 'Atsauksmes',
      feedbackAndPrint: 'Atsauksmes un drukāšana',
      send: 'Sūtīt atsauksmes',
      noFeedbackEmailExist: 'Nav reģistrēts atgriezeniskās saites e-pasts',
    },
    addurl: 'Iekļaut ārējo saturu',
    iframTooltip: 'Vērtību var ievadīt pikseļos vai procentuāli. Lūdzu, aiz vērtības ierakstiet % vai px.',
    youAreIn: 'Jūs esat pieslēdzies',
    selectAll: 'Atlasīt visus',
  },
  publish: {
    publish: 'Publicēt',
    publishing: 'Publicēšana',
    start: 'Publicēšanas process ir sākts',
    publishOk: 'Publicēšana pabeigta',
    publishFail: 'Publicēšana neizdevās',
    publishMode: 'Atbrīvots',
    editMode: 'Rediģēšanas režīms',
    defaultPublishMessage: 'Ir publicēts jauns saturs.',
    setPublishMessage: 'Iestatīt publicēšanas ziņojumu',
    publishWithNotification: 'Publicēt ar paziņojumu',
    linksWithIssues: 'Saites ar problēmām',
    imagesWithIssues: 'Attēli ar problēmām',
    help: {
      title: 'Palīdzība',
      whatIsIncludedWhenPublishing: {
        title: 'Kas ir iekļauts publikācijā?',
        articles:
          'Sarakstā pa labi ir norādīti visi raksti, kuros ir izmaiņas (salīdzinājumā ar pašlaik publicēto materiālu). Tiks publicēti tikai tie raksti, kuru izvēles rūtiņa ir atzīmēta',
        alwaysIncludedList: [
          'Bloķēts/atbloķēts statuss vai parastās kategorijas',
          'Projekta konfigurācija',
          'Sadaļas',
          'Rakstu sakārtošana sadaļās',
        ],
        lastUpdatedBy:
          'Tā administratora vārds, kurš pēdējo reizi rediģēja rakstu, nekad nav redzams personām, kas nav administratori.',
      },
      sidebarSettings: {
        title: 'Publicēt iestatījumus',
        publishMessage:
          'Publicēšanas ziņojums ir reģistrēts izmaiņu žurnālā, un sistēmā par to tiek parādīts paziņojums.',
        sendNotification:
          '”Publicēt ar paziņojumu” nozīmē, ka uz visām mobilajām ierīcēm, kurās ir instalēta lietotne, tiks nosūtīts sistēmas paziņojums..',
      },
      changelog: {
        title: 'Izmaiņu žurnāls',
        content:
          'Ja jūsu lietotnē ir izmaiņu žurnāls, tiek izveidots raksts ar informāciju par izmaiņām publikācijas laikā. Šajā rakstā tiek parādīts, tostarp, publikācijas ziņojums un kādi raksti ir izveidoti, atjaunināti vai dzēsti kopš pēdējās publikācijas.',
      },
    },
    titles: {
      lastUpdatedDate: 'Datums',
      lastUpdatedBy: 'Pēdējo atjauninājumu veica',
      lastUpdated: 'Pēdējo reizi atjaunināts',
      name: 'Raksts',
      state: 'Statuss',
    },
    editStates: {
      created: 'Pievienots',
      deleted: 'Dzēsts',
      updated: 'Atjaunināts',
    },
    noArticlesHaveBeenEdited: 'Kopš pēdējās publicēšanas neviens raksts nav rediģēts, pievienots vai dzēsts!',
    noStaffAccountsExist: 'Nav personāla kontu, kurus informēt!',
    noRoleExist: 'Nav lomu, par ko paziņot!',
    atClock: 'pulksten',
    publishMessagePlaceholder: 'Publicēt ziņu',
    errorLoadingAccounts: 'Nevar ielādēt kontus!',
    notificationSettings: 'Paziņojumu iestatījumi',
    notifyAll: 'Paziņot visiem lietotājiem',
    chooseTargets: 'Izvēlieties kontus, kuriem paziņot',
  },
  settings: {
    transferArticle: {
      moveSection: 'Pārvietot Sadaļu',
      moveArticle: 'Pārvietot Rakstu',
      articleTransfer: 'Pārvietoties starp kategorijām',
      transferArticleHeaderDesc: 'Izvēlieties divas kategorijas, starp kurām vēlaties pārvietot sadaļas vai rakstus.',
      transferArticleDescription:
        'Sadaļas pārvietošana pārvieto visus atbilstošos rakstus uz šo sadaļu, savukārt viena raksta pārvietošana pārvietotu tikai šo rakstu uz ',
      publishing: 'Rakstu vai sadaļu pārvietošana prasa publicēšanu un tiks iekļauta izmaiņu žurnālā.',
      selectCategoryOne: 'Izvēlieties pirmo kategoriju',
      selectCategoryTwo: 'Izvēlieties otro kategoriju',
    },
    roles: {
      settingItemTitle: 'Lomu konfigurācija',
      hint: 'Zemāk jūs varat pielāgot lomas',
      noRoleExists: 'Nav nevienas lomas',
      changeAccess: 'Mainīt piekļuvi kategorijām',
      createRole: 'Izveidot lomu',
      createRoleSuccess: 'Loma tika izveidota',
      createRoleError: 'Kaut kas nogāja greizi veidojot lomu, lūdzu mēģiniet vēlreiz',
      editRole: 'Loma tika rediģēta',
      roleName: 'Lomas nosaukums',
      save: 'Saglabāt',
      roleNameError: 'Nederīgs lomas nosaukums',
      categoriesTitle: 'Pārvaldiet piekļuvi kategorijām',
      workOrdersSubTitle: 'Atļaujiet piekļuvi visām sāktajām un aizvērtajām veidlapām',
      workOrdersTitle: 'Piekļuve darba pasūtījumu sistēmai',
      roleNameFormat: {
        line1: 'Lomas nosaukums var saturēt tikai angļu burtus, ciparus, -, _',
        line2: 'Lomas nosaukumā nedrīkst būt speciālie simboli',
        line3: 'Lomas nosaukums nedrīkst sākties vai beigties ar tukšumu',
      },
      delete: {
        modalHeader: 'Dzēst lomu',
        modalBody: 'Vai tiešām vēlaties dzēst šo lomu?',
        error: 'Neizdevās izdzēst lomu',
      },
    },
    multiStaffAccount: {
      displayName: 'Vārds',
      projectId: 'Projekts',
      createdAt: 'Izveidots plkst',
      status: 'Statuss',
      email: 'E-pasts',
      addAccount: 'Pievienojiet vienu kontu pēc e-pasta adreses',
      errorEmailRole: 'Lūdzu, pārbaudiet e-pastu un lomu',
      invitationSuccess: 'Lietotājs tagad ir uzaicināts',
      role: 'Loma',
      importedMailsCount: 'Importētas e-pasta adreses',
      inviteAll: 'Uzaiciniet visus',
      inviteOne: 'Uzaiciniet lietotāju',
      reinvite: 'Atkārtoti nosūtīt ielūgumu',
      reinviteTooltip: 'Atkārtoti nosūtiet ielūgumus neapstiprinātajiem lietotājiem',
      delete: 'Dzēst lietotājus',
      deleteTooltip: 'Dzēst visus atlasītos lietotājus',
      exportCSV: 'Eksportēt kā CSV',
      exportCSVTooltip: 'Eksportējiet atlasītos lietotājus CSV failā',
      changeRole: 'mainīt lomu uz:',
      editRole: 'Rediģēt lomas',
      editRoleTooltip: 'Mainīt lomu izvēlētajiem lietotājiem',
      forgotPassword: 'Aizmirsāt savu paroli? Noklikšķiniet šeit, lai atiestatītu paroli!',
      confirmDeleteAccounts: 'Vai tiešām vēlaties dzēst visus šos kontus?',
      deleteAccounts: 'Dzēst kontus',
      deleteAccountSuccess: 'veiksmīga konta dzēšana',
      deleteAccountFailure: 'neizdevās konta dzēšana',
      deleteAccountsSuccess: 'veiksmīgas konta dzēšanas',
      deleteAccountsFailure: 'neveiksmīga konta dzēšana',
      sendInvitationSuccess: 'veiksmīgi nosūtīts uzaicinājums',
      sendInvitationFailure: 'neizdevās ielūgums',
      sendInvitationDuplicate: 'dublēts e-pasts',
      sendInvitationsSuccess: 'veiksmīgi nosūtīti ielūgumi',
      sendInvitationsFailure: 'neizdevušies ielūgumi',
      sendInvitationsDuplicate: 'dublēti e-pasta ziņojumi',
    },
    accountRole: 'Izvēlieties lomu',
    provideUrl: 'Lūdzu, norādiet URL',
    settings: 'Iestatījumi',
    email: 'Atsauksmju e-pasta adreses',
    emailSettings: 'E-pasta iestatījumi',
    addEmailsHint: 'Pievienot e-pasta ziņojumus atsauksmju e-pastiem.',
    categoryAccessHint:
      'Piekļuve bloķētām kategorijām ir jānodrošina katram kontam atsevišķi. Atbloķētajām kategorijām var piekļūt ikviens.',
    updateOk: 'Izmaiņas ir saglabātas',
    updateFailed: 'Neizdevās saglabāt izmaiņas',
    emailAlreadyExist: 'E-pasts jau pastāv',
    staffAccountMng: 'Personāla konti',
    staffAccountCategories: 'Mainīt piekļuvi bloķētajām kategorijām',
    staffAccountCategoriesPlaceholder: 'Visas kategorijas ir atbloķētas!',
    staffAccountHasOngoingSubscription: 'Kontam ir aktīvs abonements.',
    accountMng: 'Kontu pārvaldība',
    selectAccount: 'Izvēlieties kontu rediģēšanai',
    confirmDeleteAccount: 'Vai tiešām vēlaties dzēst šo kontu',
    deleteAccount: 'Dzēst kontu',
    deleteAccountSuccess: 'Konts tika dzēsts',
    deleteAccountFailure: 'Konts netika dzēsts',
    emptyAccountSearch: 'Nav rezultātu!',
    changeAdminPass: 'Mainīt administratora konta paroli',
    changeStaffPass: 'Mainīt personāla konta paroli',
    passChangeSuccess: 'Paroles maiņa izdevās!',
    passChangeFail: 'Mainīt paroli neizdevās',
    emailError: 'Kļūda e-pastā!',
    export: {
      create: 'Izveidot jaunu eksportu',
      delete: 'Dzēst eksportu',
      deleteFinished: 'Eksports dzēsts',
      download: 'Lejupielādēt eksportu',
      exportFinished: 'Eksports ir ģenerēts!',
      exportStarted: 'Uzsāk eksportēšanu...',
      generating: 'Eksporta ģenerēšana...',
      hint: 'Eksportēt tikai bezsaistes režīmā pieejamu tīmekļa lietojumprogrammas versiju. Jaunākais eksports tiek saglabāts, un to var lejupielādēt vairākas reizes.',
      title: 'Eksportēt',
      notFound: 'Eksports vēl nav veikts',
      failedToDownload: 'Neizdevās lejupielādēt zip failu!',
      exportAll: 'Eksportēt visu',
    },
    userProfile: {
      title: 'Lietotāja profils',
      account: {
        title: 'Konts',
        firstName: 'Vārds',
        lastName: 'Uzvārds',
        email: 'E-pasts',
        phone: 'Tālrunis',
      },
      statistics: {
        title: 'Statistika',
        name: 'Vārds',
        benefactor: 'Labdaris',
        polygons: 'Daudzstūri',
        hectares: 'Hektāri',
        hectaresUsed: 'Izmantotie hektāri',
        hectaresAvailable: 'Pieejamie hektāri',
        acres: 'Akriem',
        emptyPolygons: 'Nav atrasti poligoni.',
      },
      subscription: {
        title: 'Abonēšana',
        level: 'Abonēšanas līmenis ir',
        noSubscription: 'Nav abonementa',
        perks: {
          title: 'Priekšrocības',
          unlimited: 'Neierobežota analīze',
          total: 'Kopā no',
        },
        cost: 'Izmaksas',
        month: 'Mēnesis',
        year: 'Gads',
      },
    },
    subscriptions: {
      noSubscription: 'Jums nav abonementa.',
      manageBillingInformation:
        'Noklikšķiniet šeit, lai pārvaldītu maksājuma metodi, rēķina informāciju un rēķina vēsturi.',
      subscriptionUntil: 'Jums ir abonements līdz',
      stripeProductId: 'Stripe abonēšanas ID',
      hectaresLimit: 'Hektāru ierobežojums',
      mostPopular: 'Vispopulārākais',
      noSubscriptionsExist: 'Nav abonementu.',
      getSubscription: 'Iegūt abonementu',
      upgradeSubscription: 'Jaunināt abonementu',
      subscribeSuccess: 'Jūs tagad esat abonējis.',
      subscribeError: 'Jūsu abonēšanas apstrāde neizdevās.',
      cancelSubscriptionSuccess: 'Abonements ir atcelts.',
      cancelSubscriptionError: 'Abonēšanas atcelšana neizdevās.',
      downgradeStubscriptionHeader: 'Mainīt abonementu',
      downgradeStubscription:
        'Jūs esat izmantojis {{usedHectares}} hektāru un jūsu izvēlētā abonementa limits ir {{newLimit}} hektāru. Lai pazeminātu abonementa līmeni, lūdzu, pārliecinieties, ka esat izdzēsis poligonus ar platību {{difference}} hektāru.',
      loadingPayWall: 'Notiek maksājuma veidlapas ielāde.',
      processSession: 'Notiek maksājuma apstrāde. Lūdzu, neaizveriet/atsvaidziniet logu',
      sort: 'Kārtot abonementus',
      custom: {
        contactUs: 'Sazinieties ar mums',
        title: 'Pielāgots',
        description:
          'Jums ir iespēja pielāgot savu piekļuvi un ierobežojumus atbilstoši jūsu konkrētajām vajadzībām. Lai izveidotu savu personalizēto plānu, vienkārši sazinieties ar mums, rakstot uz',
      },
      configuration: {
        title: 'Abonēšanas konfigurācija',
        hint: 'Zemāk varat pielāgot abonēšanu',
        add: 'Pievienot jaunu abonementu',
      },
      create: {
        title: 'Izveidot abonementu',
        success: 'Abonements tika pievienots.',
        error: 'Abonementa pievienošana neizdevās.',
        stripeProductIdError: 'Stripe produkts ar norādīto ID neeksistē.',
        alreadyExists: 'Aplikācija ar norādīto Stripe produkta ID jau eksistē.',
      },
      payment: {
        title: 'Purchase subscription',
      },
      list: {
        title: 'Abonementi',
        monthly: 'Mēneša maksājums',
        yearly: 'Gadu maksājums',
      },
      edit: {
        title: 'Edit subscription',
        success: 'Abonements tika rediģēts.',
        error: 'Abonementa rediģēšana neizdevās.',
      },
      delete: {
        modalHeader: 'Dzēst abonementu',
        modalBody: 'Vai tiešām vēlaties dzēst abonementu?',
        success: 'Abonements tika dzēsts.',
        error: 'Abonementa dzēšana neizdevās.',
      },
      cancel: {
        modalHeader: 'Atcelt abonēšanas plānu',
        modalBody: 'Vai tiešām vēlaties atcelt savu abonementu?',
        yes: 'Jā, atcelt jebkurā gadījumā',
        no: 'Nē',
      },
      tax: {
        modalHeader: 'Nodokļu maksātāja ID',
        id: 'Šeit ievadiet savu nodokļu maksātāja ID numuru',
        example: 'Piemērs: Brazīlijas CNPJ numurs 01.234.456/5432-10 / CPF numurs 123.456.789-87.',
        feedback: 'Nodokļa ID neatbilst CPF/CNPJ formātam!',
        accept: 'Lūdzu pieņem',
        termsOfUse: 'noteikumi un nosacījumi',
        register: 'Reģistrēt nodokļu ID',
      },
    },
    typeOfCategory: 'Kategorijas veids',
    categoryNameWrongFormat: 'Vārdam jāsatur tikai burti, cipari un atstarpes.',
    idChangelogCantBeUsed: 'Nosaukumu izmaiņu žurnāls nevar izmantot, ja kategorija nav izmaiņu žurnāls.',
    categoryNameAndIconNeeded: 'Vārds un ikona ir obligāti.',
    categoryNewNameOrNewIcon: 'Nepieciešams jauns vārds vai ikona.',
    projectSettings: 'Projekta iestatījumi',
    projectNameHint: 'Nosaukums',
    projectTitleHint: 'Nosaukums pie logotipa',
    projectOrganizationHint: 'Organizācija',
    projectPublisherHint: 'Izdevējs',
    handbook: 'Rokasgrāmata',
    categories: 'Kategorijas',
    handleCategories: 'Apstrādājiet kategorijas',
    accessRoles: 'Piekļuves lomas',
    access: 'Pārvaldīt piekļuvi kategorijām',
    selectLanguage: 'Izvēlieties valodu',
    noChangesMade: 'Nav veiktas nekādas izmaiņas',
    agroScan: {
      staffAccountAccess: 'Mainīt piekļuvi Agro Scan saturam',
      readPolygon: 'Sarakstīt poligonus',
      createPolygon: 'Izveidot jaunu poligonu',
      deletePolygon: 'Dzēst esošo poligonu',
      createPrescriptionFile: 'Izveidot recepte failus',
      readPrescriptionFile: 'Lejupielādēt receptes failus',
      readRequestsHistory: 'Skatīt pieprasījumu vēsturi',
      readCrudeProtein: 'Skatīt neapstrādāta proteīna analīzes rezultātus',
      processCrudeProtein: 'Iniciēt neapstrādāta proteīna analīzi',
      readDryMatter: 'Skatīt sausās vielas analīzes rezultātus',
      processDryMatter: 'Iniciēt sausās vielas analīzi',
      readPlantHealth: 'Skatīt augu veselības analīzes rezultātus',
      processPlantHealth: 'Iniciēt augu veselības analīzi',
      readSoilMoisture: 'Skatīt augsnes mitruma analīzes rezultātus',
      processSoilMoisture: 'Iniciēt augsnes mitruma analīzi',
      readSoilOrganicCarbon: 'Skatīt augsnes organiskā ogļa analīzes rezultātus',
      processSoilOrganicCarbon: 'Iniciēt augsnes organiskā ogļa analīzi',
      readVegetationIndices: 'Skatīt augu izplatības indeksa analīzes rezultātus',
      processVegetationIndices: 'Iniciēt augu izplatības indeksa analīzi',
      readWaterStress: 'Skatīt ūdens stresa analīzes rezultātus',
      processWaterStress: 'Iniciēt ūdens stresa analīzi',
      // TODO: translate
      crudeProtein: 'crudeProtein',
      dryMatter: 'dryMatter',
      plantHealth: 'plantHealth',
      soilMoisture: 'soilMoisture',
      soilOrganicCarbon: 'soilOrganicCarbon',
      vegetationIndices: 'vegetationIndices',
      waterStress: 'waterStress',
      potatoAnalysis: 'potatoAnalysis',
    },
    import: {
      title: 'Importēt dokumentu',
      upload: {
        started: 'Sākta dokumenta augšupielāde.',
        success: 'Dokuments ir veiksmīgi augšupielādēts',
        failed: 'Dokumenta augšupielāde neizdevās',
        noError: 'Visi raksti tika importēti veiksmīgi',
        failedBookmark: 'Dokumenta importēšana neizdevās ar šādām grāmatzīmēm: {{error}}',
        loadingImportingDocument: ' | Apstrādā dokumentus: {{document}}',
      },
      csvImport: 'Importējiet CSV failu',
      csvFormatError: 'CSV fails nav pareizi formatēts. Katrā rindā ir jābūt gan e-pasta adresei, gan lomai.',
      exceedingMaxUsers: 'Importēto lietotāju skaits pārsniedz maksimālo atļauto lietotāju skaitu.',
    },
    subprojects: {
      title: 'Pārvaldīt apakšprojektus',
      placeholder: 'Apakšprojekts',
      empty: 'Apakšprojektu nav',
      add: 'Pievienot jaunu apakšprojektu',
      edit: 'Rediģēt apakšprojektu',
      delete: 'Vai tiešām vēlaties dzēst apakšprojektu',
      sorting: {
        index: 'Rādītājs',
        id: 'ID',
        name: 'Vārds',
        logo: 'Ikonu',
        allowedUsers: 'Atļautie lietotāja konti',
        creationDate: 'Izveidots',
        lastUpdate: 'Pēdējoreiz atjaunots',
        admin: 'Īpašnieks',
        style: {
          primary: 'Projektu pamatkrāsa',
          primaryLight: 'Galvenās izvēlnes teksta krāsa',
          primaryDark: 'Elementa izvēlnes teksta krāsa',
          accent: 'Stils: Akcents',
        },
      },
      tooltips: {
        id: 'Apakšprojektu ID\n\n- Mazie burti, cipari un pasvītras\n- Bez simboliem un atstarpēm\n- Jābūt unikālam',
        name: 'Apakšprojektu nosaukums\n\n- Burti un cipari\n- Kopīgie simboli\n- Jābūt unikālam',
        logo: 'Augšupielādējiet attēlu, kas kalpo kā apakšprojekta logotips',
        admin: 'Personas vārds, kuram pieder apakšprojekts',
        style: {
          primary:
            'Mainiet šeit, lai mainītu projekta primāro krāsu; mainot šo krāsu, mainīsies arī galvenās izvēlnes krāsa. Krāsa tiek rakstīta sešciparu sistēmā (#292f6f).',
          primaryLight:
            'Mainīt šeit, lai mainītu galvenās izvēlnes teksta krāsu. Krāsa ir rakstīta sešciparu(#292f6f).',
          primaryDark:
            'Mainiet šeit, lai mainītu teksta krāsu elementu atlases izvēlnē. Krāsa ir rakstīta heksadecimālā(#292f6f).',
          accent:
            'Apakšprojektu akcentu krāsu stils\n\nVērtība ir heksadecimāla: #xxxxxx\nKatrs x ir cipars no 0 līdz 9 vai burts no A līdz F',
        },
      },
      contactInfosynk: 'Vai vēlaties palielināt kopējo lietotāju licences līgumu? Sazinieties ar Infosynk atbalstu!',
    },
    projectValues: {
      title: 'Pārvaldīt vērtības',
      edit: 'Rediģēt',
      newTag: 'Jauns Tags',
      noArticles: 'Raksti netika atrasti',
      noTags: 'Nav definēts neviens tags',
      sorting: {
        fId: 'ID',
        name: 'Vārds',
        category: 'Kategorija',
      },
      toast: {
        setOk: 'Pabeigts vērtību iestatīšana',
        setFail: 'Neizdevās iestatīt vērtības',
        deleteOk: 'Vērtību atiestatīšana ir pabeigta',
        deleteFail: 'Vērtību atiestatīšana neizdevās',
      },
      showAllTags: 'Parādīt visus tagus',
    },
    subprojectAdmin: {
      title: 'Pārvaldīt apakšadministratori',
      hint: 'Norādiet, kuras kategorijas apakšadministrators var pārvaldīt',
    },
    transfer: {
      title: 'Pārsūtīt starp projektiem',
      next: 'NĀKAMAIS',
      goBack: 'ATGRIEZTIES',
      confirm: 'APSTIPRINĀT',
      projectFrom: 'Atlasiet rakstus no projekta:',
      projectTo: 'Pārsūtīt rakstus uz projektu:',
      showTable: 'Izvērst',
      hideTable: 'Sakļaut',
      started: 'Sākta rakstu pārsūtīšana...',
      finished: 'Pabeigta rakstu pārsūtīšana',
    },
  },
  sorting: {
    articleSort: 'Raksti',
    sectionSort: 'Sadaļas',
    titleForm: 'Rakstu un sadaļu kārtošana',
    articleEdit: 'Rediģēt rakstus',
    sectionEdit: 'Rediģēt sadaļas',
    cancelSorting: 'Atcelt kārtošanu',
    saveSorting: 'Saglabāt kārtošanu',
    sortingArticlesHasChanged: 'Kārtošana ir mainīta',
    sortingSectionsHasChanged: 'Kārtošana ir mainīta',
    unsavedChanges: 'Jums ir nesaglabātas izmaiņas',
    sortingHasChanged: 'Kārtošana ir mainīta',
  },
  article: {
    article: 'Raksts',
    draftModalHeader: 'Saturs nav saglabāts',
    draftModalBody: 'Rediģētais saturs nav saglabāts.',
    articleSavedOk: 'Raksts ir saglabāts',
    deleteModalHeader: 'Dzēst rakstu',
    deleteModalBody: 'Vai esat pārliecināts, ka vēlaties izdzēst šo rakstu?',
    deleteModalBodyPerma: 'Vai esat pārliecināts, ka vēlaties neatgriezeniski izdzēst šo rakstu?',
    deleteOk: 'Raksts ir izdzēsts!',
    addOk: 'Raksts ir pievienots!',
    sortingSaved: 'Rakstu kārtošana ir saglabāta',
    addNewArticle: 'Pievienot jaunu rakstu',
    wrongArticleName: 'Nepareizs raksta nosaukums',
    notFoundWarning: 'Raksts nav atrasts!',
    updateOk: 'Raksts ir atjaunināts',
    updateFailed: 'Raksta atjaunināšana neizdevās',
    fileTooLarge: 'Datne ir pārāk liela. Maks. 10 MB',
    protectedArticle: {
      errorMessage: 'Jums jāpierakstās, lai skatītu šo rakstu.',
      errorMessageStaff: 'Jums jābūt piešķirtai piekļuvei, lai skatītu šo rakstu.',
    },
  },
  category: {
    addCategory: 'Pievienot kategoriju',
    uploadCategoryIcon:
      'Velciet attēlu vai nospiediet "Augšupielādēt attēlu", lai izvēlētos savu attēlu, vai arī izvēlieties kādu no mūsu galerijas, nospiežot "Izvēlēties no galerijas".',
    category: 'Kategorija',
    lock: 'Pin bloķētājs',
    lockCategoryInputHint:
      'Iestatiet PIN kodu, lai aizsargātu kategoriju. PIN koda garumam jābūt no 4 līdz 6 cipariem' +
      'lai noņemtu PIN, norādiet tukšu vērtību',
    lockOk: 'Ir iestatīts kategorijas PIN.',
    lockFail: 'Kļūda, iestatot kategorijas PIN kodu.',
    login: 'Skatīt saturu',
    password: 'Ievadiet PIN kodu',
    pinCheckNotValid: 'PIN kods nav derīgs!',
    pinAddNotValid:
      'Iesniegtais PIN nav derīgs — tam jābūt starp 4-6 skaitļiem garam.  Lai noņemtu PIN kodu, norādiet tukšu vērtību',
    protectOk: 'Kategorijas aizsardzība ir mainīta',
    protectFail: 'Nomainīt kategoriju aizsardzību neizdevās',
    notFoundWarning: 'Kategorija nav atrasta!',
    updated: 'Kategorija atjaunināta',
    updateError: 'Kļūda, atjauninot kategoriju',
    protectedCategory: {
      chiefErrorMessage: 'Jums jābūt administratoram, lai skatītu šos rakstus.',
      defaultErrorMessage: 'Jums jābūt piešķirtai piekļuvei, lai skatītu šos rakstus.',
    },
    categoryNames: {
      html: 'Kalkulators',
      changelog: 'Izmaiņu žurnāls',
      url: 'Ārējā saite',
      workorder: 'Formēšanas modulis',
      nativeModule: 'Mobilais modulis',
      articleCategory: "Standarta kategorija'",
    }, // ['Standarta kategorija', 'Ārējā saite', 'Kalkulators', 'Formēšanas modulis', 'Izmaiņu žurnāls'],
    categoryDescription: {
      articleCategory: 'Parasta kategorija, ko izmanto rakstiem un sadaļām.',
      url: 'Kategorija, kas darbojas tikai mobilajās ierīcēs, šeit lietotājs tiek novirzīts uz konkrētu URL.',
      html: 'Kalkulatora kategorija, ko izmanto medicīnas aprēķiniem, ja izvēlēta, Softwerk AB sazināsies ar jums, lai iestatītu kalkulatoru.',
      workorder:
        'Formu modulī var izveidot formas, un pēc tam tās var aizpildīt lietotāji. Lieliski piemērots sarakstiem vai bieži aizpildāmiem veidlapām.',
      changelog: 'Izmaiņu žurnālā tiek saglabātas rakstu izmaiņas, labs lietojams kopā ar paziņojumu sistēmu.',
      nativeModule: '',
    },
    termsOfUseAddCategory: `Jaunas kategorijas pievienošanas nosacījumi\n\nPievienojot mūsu platformai jaunu kategoriju, jūs atzīstat un piekrītat šādiem noteikumiem:\n\nCena un norēķini:\n\nPar katru jaunu pievienoto kategoriju tiek piemērota papildu maksa SEK {{value}} bez PVN gadā. Šī cena ir balstīta uz mūsu pašreizējo cenu modeli saskaņā ar vienošanos. Jaunās kategorijas cena tiek samazināta par pirmo gadu, pamatojoties uz dienu skaitu, kas atlikušas jūsu abonēšanas periodā no kategorijas pievienošanas datuma. Atlaide tiek aprēķināta katru dienu un proporcionāli pirmā gada laikā, lai precīzi piemērotu cenu, kas tiek ievērota nākamajam abonēšanas periodam.\n\nNoklikšķinot uz "Piekrītu", jūs apstiprināt, ka esat izlasījis, sapratis un piekrītat pievienot jaunu kategoriju saskaņā ar iepriekš minētajiem noteikumiem.`,
    chiefLoading: 'Administratora kategorijas ielāde',
    sortingStarted: 'Saglabā kārtojumu',
    sortingCompleted: 'Kategorijas tika veiksmīgi sakārtotas',
    sortingError: 'Kaut kas nogāja greizi, lūdzu, mēģiniet vēlreiz',
    editCategoryStarted: 'Saglabā rediģēto kategoriju',
    editCategorySucces: 'Kategorijas rediģēšana izdevās',
    editCategoryFailed: 'Neizdevās atjaunināt kategoriju, lūdzu, mēģiniet vēlreiz',
    startedCreatingCategory: 'Kategorijas izveide uzsākta',
    categoryIdExists: 'Kategorijas ID jau pastāv, lūdzu, mēģiniet vēlreiz ar citu nosaukumu',
    failedCreatingCategory: 'Neizdevās izveidot kategoriju, lūdzu, mēģiniet vēlreiz',
    successCreatingCategory: 'Kategorija veiksmīgi izveidota',
  },
  section: {
    section: 'Sadaļa',
    defaultSection: 'Nav sadaļas',
    deleteModalHeader: 'Dzēst sadaļu',
    deleteModalBody: 'Ištrynus šį skyrių, bus ištrinti ir toliau išvardyti straipsniai:',
    deleteModalBodyPerma: 'Vai esat pārliecināts, ka vēlaties šo sadaļu dzēst uz visiem laikiem?',
    sectionDeleted: 'Sadaļa ir dzēsta!',
    sortingSaved: 'Saglabāta sadaļu kārtošana',
    addOk: 'Pievienota sadaļa',
    addError: 'Sadaļas pievienošana neizdevās',
    deleteOk: 'Sadaļa ir dzēsta',
    wrongSectionName: 'Nederīgs sadaļas nosaukums',
    addNewSection: 'Pievienot jaunu sadaļu',
    deletingAdditionalArticles: 'ATTENTION! The following articles will be removed:',
  },
  project: {
    notLoaded: 'Projekts nav pareizi ielādēts vai nav atrasts.',
    noStaffAccount: 'Šim projektam NAV personāla konta',
  },
  forms: {
    placeHolders: {
      createNewSection: 'Izveidot jaunu sadaļu',
      sectionName: 'Sadaļas nosaukums',
      articleName: 'Raksta nosaukums',
    },
    messages: { valueToShort: 'Ievadītā vērtība ir pārāk īsa' },
    uploadImage: 'Augšupielādēt attēlu',
    gallerySelect: 'Izvēlēties no galerijas',
    saveSortingButton: 'Saglabāt kārtojumu.',
  },
  search: {
    relevance: 'Atbilstība',
    alphabetical: 'Pēc alfabēta',
    up: 'Augoši',
    down: 'Dilstoši',
    allResults: 'Visi rezultāti',
    seeAll: 'Skatīt visus rezultātus par',
    searchSuggest: 'Meklēšanas ieteikumi',
    searchError: 'Jūs neievadījāt nekādus meklēšanas noteikumus',
    pageNumberError: 'Ievadītais numurs ir nepareizs',
    resultError: 'Netika atrasts neviens raksts, kas atbilstu jūsu atlasei..',
  },
  versionOutdated: {
    title: 'Ir pieejama jauna versija',
    body: 'Dažas funkcijas var nedarboties, kamēr nav ielādēta jaunā versija',
    button: 'Ielādēt jauno versiju',
  },
};

export const pt: LocalizedStrings = {
  auth: {
    emailPlaceholder2: 'e-mail para convidar',
    invalidPasswordLenght: 'A senha deve conter entre 8 e 20 caracteres.',
    invite: 'Convidar este usuário',
    signInButton: 'Iniciar sessão',
    signOutButton: 'Encerrar sessão',
    registerButton: 'Registrar',
    firstNameTitle: 'Nome',
    firstNamePlaceholder: 'Nome',
    lastNameTitle: 'Sobrenome',
    lastNamePlaceholder: 'Sobrenome',
    emailTitle: 'Email',
    emailPlaceholder: 'Digite seu email',
    phoneTitle: 'Telefone',
    phonePlaceHolder: 'DDD+Telefone',
    phoneErrorDisplay: 'Exemplo: 1133333333 ou 11999999999',
    phoneAlreadyExists: 'O número de telefone indicado já está sendo utilizado por um usuário',
    passwordTitle: 'Senha',
    passwordConfirmTitle: 'Confirmar senha',
    passwordPlaceholder: ' Digite sua senha',
    passwordPlaceholderRetype: 'Repita sua senha',
    logout: 'Logout',
    error: {
      unknown: 'Erro desconhecido',
      invalidCredentials: 'Dados de login inválidos',
      invalidStaffPassword: 'Senha da conta de equipe inválida',
      invalidCreateAccount: 'Não foi possível registrar nova conta de usuário',
      invalidEmailVerification: 'Não foi possível enviar e-mail de verificação',
    },
    wrongProject: 'Você não tem permissão para acessar esse projeto.',
    staffAccount: 'Código',
    adminAccount: 'E-mail/senha',
    adminAccountAddCategory: 'Administrador',
    authenticated: 'Você está conectado como',
    loggedout: 'Você foi desconectado',
    notAuthenticated: 'Você não está conectado',
    notAuthorized: 'Você não tem permissão',
    passwordChangeFailure: 'Erro na alteração da senha',
    passwordChangeSuccess: 'Senha alterada com sucesso',
    invalidPasswordFormat: 'Formato da senha inválido!',
    invalidPasswordRetype: 'As senhas precisam ser idênticas.',
    staffPasswordNotUnique: 'A senha para a conta de equipe precisa ser única',
    welcomeBack: 'Seja bem-vindo',
    loginToGetAccess: 'Inicie a sessão para acessar a plataforma.',
    registerToGetAccess: 'Registre-se para ter acesso à plataforma',
    forgotPasswordClick: 'Esqueceu a senha? Clique',
    noAccountYetClick: 'Não possui uma conta? Registre-se',
    here: 'aqui',
    resetEmailSent: 'Email enviado',
    resetEmailFail: 'Erro desconhecido. Email não enviado, por favor entre em contato com o suporte.',
    recoveryEmailInfo:
      'Insira o e-mail de acesso para recuperar a senha. Se não receber o e-mail de recuperação ou tiver algum outro problema, por favor entre em contato com o suporte',
    resetPassword: 'Restaurar a senha',
    loginToSee: 'Inicie a sessão para acessar o conteúdo',
    passwordRules: {
      adminHeader: 'Senha de administrador',
      staffHeader: 'Senha de equipe',
      lowercase: 'A senha deve conter pelo menos uma letra minúscula',
      uppercase: 'A senha deve conter pelo menos uma letra maiúscula',
      special: 'A senha deve conter pelo menos um caracter especial [?,!,$,...]',
      number: 'A senha deve conter pelo menos um número',
      whitespace: 'A senha não pode conter espaço',
      adminMin: ' A senha deve conter pelo 8 caracteres',
      staffMin: ' A senha deve conter pelo menos 6 caracteres',
      staffMax: ' A senha deve conter no máximo 20 caracteres',
    },
    emailVerification: {
      emailSent: 'E-mail de verificação enviado.',
      invalidToken: 'O token de verificação é inválido ou expirou.',
      emailNotVerified:
        'O seu e-mail não foi verificado. Você pode realizar a verificação através do link enviado por e-mail. Caso o link esteja expirado, você pode solicitar novamente aqui.',
      send: 'Enviar e-mail de verificação.',
      verify: 'Verificar e-mail',
      expired: 'O e-mail de verificação expirou',
      verified: 'O seu e-mail foi verificado',
      error: 'Erro ao tentar verificar o e-mail',
    },
    oldPasswordPlaceholder: 'Senha antiga',
    invalidOldPassword: 'A senha está incorreta.',
    editAccountInfo: 'Editar informações da conta',
    submit: 'Envia',
    welcomeUser: 'Bem-vindo ao {{user}}. Preencha os campos abaixo para criar sua conta.',
  },
  error: {
    error: 'Erro',
    unknown: 'Algo deu errado!',
    404: 'Conteúdo não encontrado',
  },
  external: {
    externalContent: 'Conteúdo Externo',
  },
  adminViewMode: {
    enterPublishMode: 'Ver conteúdo publicado',
    onlyPublishedMaterialIsShown: 'apenas conteúdo público é mostrado',
    editTip:
      'Alterações realizadas nesse modo podem ser vistas apenas por administradores. Após publicadas, as alteração estarão visíveis a todos.',
    publishTip:
      'Apenas materiais publicados estão visíveis nesse modo. As categorias, seções e artigos são mostradas como um perfil não-administrativo estaria enxergando. E nesse modo não são possíveis realizar alterações.',
  },
  global: {
    add: 'Adicionar',
    save: 'Salvar',
    doNotSave: 'Não salvar',
    confirm: 'Confirme',
    cancel: 'Cancelar',
    edit: 'Editar',
    preview: 'Prévia',
    view: 'Visualizar',
    delete: 'Deletar',
    loading: 'Carregando...',
    startApplication: 'Iniciar aplicação...',
    searchResult: 'Resultado da pesquisa',
    search: 'Pesquisar',
    allResults: 'Todos os resultados para',
    feedbackSubject: 'Feedback',
    privacyPolicy: 'Política de Privacidade',
    termsOfUse: 'Termos de Uso',
    fileFromatError: 'Extensão do arquivo não suportada',
    print: 'Imprimir',
    create: 'Criar',
    clear: 'Apagar',
    ok: 'OK',
    send: 'Enviar',
    online: 'Você está online',
    offline: 'Você está offline. Algumas funcionalidades podem estar limitadas.',
    clickToReload: 'Recarregar componente',
    clickToReloadFull: 'Recarregar página',
    linkTo: 'Link para',
    linkCopied: 'O link foi copiado para sua área de transferência',
    insertAnchorlink: 'Insira o link âncora',
    homePage: 'Página inicial',
    fullscreen: 'Tela inteira',
    feedback: {
      feedback: 'Feedback',
      feedbackAndPrint: 'Feedback & Print',
      send: 'Enviar feedback',
      noFeedbackEmailExist: 'Nenhum e-mail de resposta foi registrado',
    },
    addurl: 'Incorporar conteúdo externo',
    iframTooltip:
      'Você pode inserir o valor em pixels ou porcentagem. Por favor, inclua % or px após o valor inserido. Valor padrão:',
    youAreIn: 'Você está dentro',
    selectAll: 'Selecionar tudo',
    reset: 'Redefinir',
    only: 'Apenas',
    mobile: 'Celular',
    website: 'Página web',
    icon: 'Icone',
    name: 'Nome',
    url: 'URL',
    availableOn: 'Disponível em',
  },
  publish: {
    noRoleExist: 'Não há funções para notificar!',
    publish: 'Publicar',
    publishing: 'Publicando',
    start: 'Publicação iniciada',
    publishOk: 'Publicação realizada',
    publishFail: 'Erro na publicação',
    publishMode: 'Modo Publicação',
    editMode: 'Modo de edição',
    defaultPublishMessage: 'Novo conteúdo publicado.',
    setPublishMessage: 'Definir mensagem de publicação',
    publishWithNotification: 'Publicar com notificação',
    linksWithIssues: 'Links com problemas',
    imagesWithIssues: 'Imagens com problemas',

    help: {
      title: 'Ajuda',
      whatIsIncludedWhenPublishing: {
        title: 'O que está incluído na publicação?',
        articles:
          'A lista mostra todos os artigos que possuem alterações em relação ao material publicado. Apenas os artigos selecionados serão publicados. Observe que, independentemente de quais artigos estão selecionados, se houver alterações nos itens a seguir SEMPRE, estas serão incluídas durante a publicação:',
        alwaysIncludedList: [
          'Status bloqueado/desbloqueado ou categorias regulares',
          'Definições do projeto',
          'Seções',
          'Ordenação dos artigos nas seções',
        ],
        lastUpdatedBy:
          'O nome do administrador que realizou a última alteração no artigo, não é visível aos não-administradores.',
      },
      sidebarSettings: {
        title: 'Definições da publicação',
        publishMessage:
          'A mensagem de publicação está incluída no log de alterações e é mostrada nas notificações push.',
        sendNotification:
          '"Publicação com notificação push" significa que uma notificação push será enviada aos dispositivos móveis que tenham o aplicativo instalado.',
      },
      changelog: {
        title: 'Registro de alterações',
        content:
          'Caso sua aplicação possua um changelog, é criado um artigo com informações sobre as alterações no momento da publicação. Este artigo exibe, entre outras coisas, a mensagem de publicação e quais artigos foram criados, atualizados ou removidos desde a última publicação.',
      },
    },
    titles: {
      lastUpdatedDate: 'Data',
      lastUpdatedBy: 'Atualizado por',
      lastUpdated: 'Última atualização',
      name: 'Artigo',
      state: 'Status',
    },
    editStates: {
      created: 'Adicionado',
      deleted: 'Excluído',
      updated: 'Atualizado',
    },
    noArticlesHaveBeenEdited: 'Nenhum artigo foi modificado, adicionado ou excluído desde a última publicação!',
    noStaffAccountsExist: 'Não existem contas de equipe a serem notificadas!',
    atClock: 'às',
    publishMessagePlaceholder: 'Publicar menssagem',
    errorLoadingAccounts: 'Não foi possível carregar as contas.',
    notificationSettings: 'Definições de notificação',
    notifyAll: 'Comunicar a todos os usuários',
    chooseTargets: 'Escolher contas a serem comunicadas',
  },
  settings: {
    transferArticle: {
      moveSection: 'Mover Seção',
      moveArticle: 'Mover Artigo',
      articleTransfer: 'Mover entre categorias',
      transferArticleHeaderDesc: 'Selecione as duas categorias entre as quais você deseja mover seções ou artigos.',
      transferArticleDescription:
        'Transferir uma seção move todos os artigos correspondentes para essa seção, enquanto mover um único artigo moveria apenas esse artigo para a ',
      publishing: 'Mover artigos ou seções requer uma publicação e será registrado no changelog.',
      selectCategoryOne: 'Selecione a Categoria Um',
      selectCategoryTwo: 'Selecione a Categoria Dois',
    },
    roles: {
      categoriesTitle: 'Gerenciar o acesso às categorias',
      changeAccess: 'Alterar o acesso às categorias',
      createRole: 'Criar função',
      createRoleSuccess: 'A função foi criada',
      createRoleError: 'Algo deu errado ao criar a função, tente novamente!',
      delete: {
        error: 'Falha ao excluir a função',
        modalBody: 'Tem certeza de que deseja excluir essa função?',
        modalHeader: 'Excluir a função',
      },
      editRole: 'O papel foi editado',
      hint: 'Abaixo, você pode ajustar as funções',
      noRoleExists: '',
      roleName: 'Nome da função',
      roleNameError: 'Nome de função inválido',
      roleNameFormat: {
        line1: 'O nome da função pode conter apenas letras, números, -, _',
        line2: 'O nome da função não pode conter caracteres especiais',
        line3: 'O nome da função não pode começar ou terminar com espaço em branco ',
      },
      save: 'Salvar',
      settingItemTitle: 'Configuração da função',
      workOrdersSubTitle: 'Permitir o acesso a todos os formulários iniciados e fechados ',
      workOrdersTitle: 'Acesso ao sistema de ordens de serviço',
    },
    multiStaffAccount: {
      displayName: 'Nome',
      projectId: 'Projeto',
      createdAt: 'Criado em',
      status: 'Status',
      email: 'E-mail',
      addAccount: 'Adicione uma conta por endereço de e-mail',
      role: 'Papel',
      errorEmailRole: 'Verifique o e-mail e a função',
      invitationSuccess: 'O usuário foi convidado',
      importedMailsCount: 'E-mails importados',
      inviteAll: 'Convide todos',
      inviteOne: 'Convidar um usuário',
      reinvite: 'Reenviar convite',
      reinviteTooltip: 'Reenviar convite para os usuários pendentes',
      delete: 'Excluir usuários',
      deleteTooltip: 'Exclua os usuários selecionados',
      exportCSV: 'Exportar como CSV',
      exportCSVTooltip: 'Exporte usuários selecionados para um arquivo CSV',
      changeRole: 'Alterar função para:',
      editRole: 'Editar funções',
      editRoleTooltip: 'Alterar a função para os usuários selecionados',
      forgotPassword: 'Esqueceu sua senha? Clique aqui para redefinir sua senha!',
      confirmDeleteAccounts: 'Tem certeza de que deseja excluir todas estas contas',
      deleteAccounts: 'Excluir contas',
      deleteAccountSuccess: 'exclusão de conta com sucesso',
      deleteAccountFailure: 'falha na exclusão da conta',
      deleteAccountsSuccess: 'exclusões de contas bem-sucedidas',
      deleteAccountsFailure: 'exclusões de conta com falha',
      sendInvitationSuccess: 'convite enviado com sucesso',
      sendInvitationFailure: 'convite falhado',
      sendInvitationDuplicate: 'e-mail duplicado',
      sendInvitationsSuccess: 'convites enviados com sucesso',
      sendInvitationsFailure: 'convites falhados',
      sendInvitationsDuplicate: 'e-mails duplicados',
    },
    import: {
      csvFormatError: 'O arquivo CSV não está formatado corretamente. Cada linha deve conter um e-mail e uma função.',
      csvImport: 'Importar um arquivo CSV',
      title: 'Importar um documento',
      upload: {
        failed: 'Falha no upload do documento',
        started: 'Iniciou o upload de documentos.',
        success: 'Documento carregado com sucesso',
        noError: 'Todos os artigos foram importados com sucesso',
        failedBookmark: 'A importação do documento falhou com os seguintes marcadores: {{error}}',
        loadingImportingDocument: ' | Processando documentos: {{document}}',
      },
      exceedingMaxUsers: 'Os usuários importados ultrapassam o número máximo de usuários permitidos.',
    },
    subprojects: {
      title: 'Gerenciar subprojetos',
      placeholder: 'Subprojeto',
      empty: 'Não existem subprojetos',
      add: 'Adicionar novo subprojeto',
      edit: 'Editar subprojeto',
      delete: 'Tem certeza de que deseja excluir o subprojeto',
      sorting: {
        index: 'Index',
        id: 'ID',
        name: 'Nome',
        logo: 'Logo',
        allowedUsers: 'Contas de usuário permitidas',
        creationDate: 'Data de criação',
        lastUpdate: 'Última atualização',
        admin: 'Admin',
        style: {
          primary: 'Cor primária dos projetos',
          primaryLight: 'Cor do texto do menu principal',
          primaryDark: 'Cor do texto do menu de itens',
          accent: 'Estilo: Accent',
        },
      },
      tooltips: {
        id: 'ID do subprojeto\n\n- Letras minúsculas, números e sublinhados\n- Sem símbolos e espaços\n- Deve ser exclusivo',
        name: 'O nome do subprojeto\n\n- Letras e números\n- Símbolos comuns\n- Deve ser exclusivo',
        logo: 'Carregue uma imagem que sirva como logotipo do subprojeto',
        admin: 'Nome da pessoa que possui o subprojeto',
        style: {
          primary:
            'Mude aqui para alterar a cor primária do projeto; mudar isso mudará a cor do menu principal. A cor é escrita em hexadecimal (#292f6f).',
          primaryLight:
            'Altere aqui para mudar a cor do texto no menu principal. A cor é escrita em hexadecimal (#292f6f),',
          primaryDark:
            'Altere aqui para mudar a cor do texto no menu de seleção de itens. A cor é escrita em hexadecimal (#292f6f)',
          accent:
            'O estilo da cor de destaque dos subprojetos\n\nO valor é hexadecimal: #xxxxxx\nCada x é um número de 0 a 9 ou uma letra de A a F',
        },
      },
      contactInfosynk:
        'Deseja aumentar o contrato de licença do total de usuários? Entre em contato com o suporte da Infosynk!',
    },
    projectValues: {
      edit: 'Editar',
      newTag: 'Nova etiqueta',
      noArticles: 'Não foram encontrados artigos',
      noTags: 'Não há tags definidas',
      sorting: { category: 'Categoria', fId: 'ID', name: 'Nome' },
      title: 'Gerenciar valores',
      toast: {
        deleteFail: 'Falha na redefinição de valores',
        deleteOk: 'Concluída a redefinição de valores',
        setFail: 'Falha na definição dos valores',
        setOk: 'Valores de configuração concluídos',
      },
      showAllTags: 'Mostrar todas as tags',
    },
    transfer: {
      title: 'Transferir entre projetos',
      next: 'PRÓXIMO',
      goBack: 'VOLTAR',
      confirm: 'CONFIRMAR',
      projectFrom: 'Selecionar artigos do projeto:',
      projectTo: 'Transferir artigos para o projeto:',
      showTable: 'Expandir',
      hideTable: 'Ocultar tabela',
      started: 'Iniciou a transferência de artigos...',
      finished: 'Terminou a transferência de artigos',
    },
    settings: 'Definições',
    email: 'Endereços de e-mail para feedback',
    emailSettings: 'Definições de e-mail',
    addEmailsHint: 'Adicionar e-mail para receber feedback',
    categoryAccessHint:
      'O acesso às categorias bloqueadas é realizado individualmente para cada conta. Categorias desbloqueadas podem ser acessadas por todos.',
    updateOk: 'Alterações salvas com sucesso',
    updateFailed: 'Erro ao tentar salvar as alterações',
    emailAlreadyExist: 'O e-mail já possui uma conta cadastrada.',
    staffAccountMng: 'Contas de usuários',
    staffAccountCategories: 'Alterar o acesso das categorias bloqueadas.',
    staffAccountCategoriesPlaceholder: 'Todas as categorias estão desbloqueadas!',
    //TODO: Validate translation
    staffAccountHasOngoingSubscription: 'A conta possui uma assinatura em andamento.',
    accountMng: 'Gerenciamento da conta',
    selectAccount: 'Selecione uma conta para editar',
    confirmDeleteAccount: 'Tem certeza de que deseja excluir esta conta',
    deleteAccount: 'Excluir conta',
    deleteAccountSuccess: 'A conta foi excluída',
    deleteAccountFailure: 'A conta não foi excluída',
    emptyAccountSearch: 'Sem resultados!',
    changeAdminPass: 'Alterar a senha da conta de administrador',
    changeStaffPass: 'Alterar a senha da conta de equipe',
    passChangeSuccess: 'Senha alterada com sucesso!',
    passChangeFail: 'Erro ao tentar alterar a senha',
    emailError: 'Endereço de e-mail inválido.',
    export: {
      create: 'Criar arquivo HTML de exportação',
      delete: 'Excluir arquivo de exportação',
      deleteFinished: 'Arquivo de exportação excluído',
      download: 'Download do arquivo de exportação',
      exportFinished: 'Arquivo de exportação gerado!',
      exportStarted: 'Iniciando a exportação...',
      generating: 'Gerando o arquivo de exportação...',
      hint: 'Exportar um arquivo HTML (offline) da versão web, contendo as últimas publicações. A última exportação fica disponível para ser baixada a qualquer momento.',
      title: 'Exportar',
      notFound: 'Nenhum arquivo de exportação foi geradoaté o momento',
      failedToDownload: 'Falha no download do arquivo .zip!',
      exportAll: 'Exportar tudo',
    },
    userProfile: {
      title: 'Meu perfil',
      account: {
        title: 'Conta',
        firstName: 'Nome',
        lastName: 'Sobrenome',
        email: 'E-mail',
        phone: 'Telefone',
      },
      statistics: {
        title: 'Estatísticas',
        name: 'Nome',
        benefactor: 'Benfeitor',
        polygons: 'Talhões',
        hectares: 'Hectares',
        hectaresUsed: 'Hectares utilizados',
        hectaresAvailable: 'Hectares disponíveis',
        acres: 'Acres',
        emptyPolygons: 'Nenhum talhão encontrado.',
      },
      subscription: {
        title: 'Plano contratado',
        level: 'Plano contratado:',
        noSubscription: 'Sem assinatura',
        perks: {
          title: 'Serviços incluídos',
          unlimited: 'Análises ilimitadas',
          total: 'Total de',
        },
        cost: 'Valor',
        month: 'Mês',
        year: 'Ano',
      },
    },
    //TODO: Validate translations
    subscriptions: {
      noSubscription: 'Você não possui nenhum plano ativo.',
      manageBillingInformation:
        'Clique aqui para gerenciar os seus métodos de pagamento, informações de nota fiscal e histórico de cobrança.',
      subscriptionUntil: 'Você possui uma assinatura até',
      stripeProductId: 'ID da assinatura no Stripe',
      hectaresLimit: 'Limite de hectares',
      mostPopular: 'Mais popular',
      noSubscriptionsExist: 'Não existem assinaturas.',
      getSubscription: 'Obter assinatura',
      upgradeSubscription: 'Atualizar assinatura',
      subscribeSuccess: 'Agora você está inscrito.',
      subscribeError: 'Falha no processamento da sua assinatura.',
      cancelSubscriptionSuccess: 'Assinatura foi cancelada.',
      cancelSubscriptionError: 'Cancelar assinatura do plano.',
      downgradeStubscriptionHeader: 'Fazer downgrade da assinatura',
      downgradeStubscription:
        'Você usou {{usedHectares}} hectares e o limite da assinatura escolhida é {{newLimit}} hectares. Para fazer o downgrade de sua assinatura, certifique-se de excluir os polígonos com área de {{difference}} hectares.',
      loadingPayWall: 'Carregando o formulário de pagamento.',
      processSession: 'Processando o pagamento. Por favor, não feche/atualize a janela',
      sort: 'Ordenar assinaturas',
      custom: {
        contactUs: 'Fale Conosco',
        title: 'Personalizado',
        description:
          'Você tem o poder de personalizar seu acesso e limitações de acordo com suas necessidades específicas. Para criar seu plano personalizado, basta entrar em contato conosco em',
      },
      configuration: {
        title: 'Configuração de Assinaturas',
        hint: 'Abaixo você pode ajustar as assinaturas',
        add: 'Adicionar nova assinatura',
      },
      create: {
        title: 'Criar assinatura',
        success: 'Assinatura foi adicionada.',
        error: 'Falha ao adicionar assinatura.',
        stripeProductIdError: 'Um produto Stripe com o ID especificado não existe.',
        alreadyExists: 'Já existe uma assinatura com o ID do produto Stripe especificado.',
      },
      payment: {
        title: 'Purchase subscription',
      },
      list: {
        title: 'Planos e preços',
        monthly: 'Mensal',
        yearly: 'Anual',
      },
      edit: {
        title: 'Edit subscription',
        success: 'Assinatura foi editada.',
        error: 'Falha ao editar assinatura.',
      },
      delete: {
        modalHeader: 'Excluir assinatura',
        modalBody: 'Você tem certeza que quer cancelar sua assinatura?',
        success: 'Assinatura foi excluída.',
        error: 'Falha ao excluir assinatura.',
      },
      cancel: {
        modalHeader: 'Cancelar assinatura',
        modalBody:
          'Ao cancelar a assinatura, você perderá acesso aos talhões existentes.\nTem certeza que deseja cancelar?',
        yes: 'Sim, cancelar mesmo assim',
        no: 'Não',
      },
      tax: {
        modalHeader: 'Número do documento',
        id: 'Insira o número do CPF ou CNPJ',
        example: 'Exemplo: CNPJ 01.234.456/5432-10 ou CPF 123.456.789-87',
        feedback: 'O formato do número do CPF ou CNPJ está incorreto.',
        accept: 'Estou de acordo com os',
        termsOfUse: 'termos e condições',
        register: 'Continuar',
      },
    },
    projectSettings: 'Definições de projeto',
    projectNameHint: 'Nome',
    projectTitleHint: 'Título',
    projectOrganizationHint: 'Empresa',
    projectPublisherHint: 'Editor',
    handbook: 'Manual',
    categories: 'Categorias',
    accessRoles: 'Perfis de acesso',
    accountRole: '',
    access: 'Gerenciar acessos às categorais',
    selectLanguage: 'Selecionar idioma',
    //TODO: Validate translations
    agroScan: {
      staffAccountAccess: 'Alterar acesso ao conteúdo Agro Scan',
      readPolygon: 'Listar polígonos',
      createPolygon: 'Criar um novo polígono',
      deletePolygon: 'Excluir polígono existente',
      createPrescriptionFile: 'Prescrição em Taxa Variável',
      readPrescriptionFile: 'Prescrição em Taxa Variável',
      readRequestsHistory: 'Ver histórico de pedidos',
      readCrudeProtein: 'Ver resultados de análise de proteína bruta',
      processCrudeProtein: 'Iniciar análise de proteína bruta',
      readDryMatter: 'Ver resultados de análise de matéria seca',
      processDryMatter: 'Iniciar análise de matéria seca',
      readPlantHealth: 'Ver resultados de análise de saúde das plantas',
      processPlantHealth: 'Iniciar análise de saúde das plantas',
      readSoilMoisture: 'Ver resultados de análise de umidade do solo',
      processSoilMoisture: 'Iniciar análise de umidade do solo',
      readSoilOrganicCarbon: 'Ver resultados de análise de carbono orgânico do solo',
      processSoilOrganicCarbon: 'Iniciar análise de carbono orgânico do solo',
      readVegetationIndices: 'Ver resultados de análise de índices de vegetação',
      processVegetationIndices: 'Iniciar análise de índices de vegetação',
      readWaterStress: 'Ver resultados de análise de estresse hídrico',
      processWaterStress: 'Iniciar análise de estresse hídrico',
      crudeProtein: 'Proteína bruta',
      dryMatter: 'Matéria seca',
      plantHealth: 'Saúde de Lavoura',
      soilMoisture: 'Umidade do solo',
      soilOrganicCarbon: 'Matéria orgânica do solo',
      vegetationIndices: 'Índices de vegetação',
      waterStress: 'Estresse hídrico',
      potatoAnalysis: 'Análise da batata',
    },
    subprojectAdmin: {
      title: 'Gerenciar Subadministrador',
      hint: 'Especifique quais categorias um subadministrador pode gerenciar',
    },
    handleCategories: 'Categorias de alças',
    typeOfCategory: 'Tipo de categoria',
    categoryNameWrongFormat: 'O nome deve conter apenas letras, números e espaços.',
    provideUrl: 'Forneça um URL',
    idChangelogCantBeUsed: 'O nome changelog não pode ser usado se a categoria não for um changelog.',
    categoryNameAndIconNeeded: 'O nome e o ícone são obrigatórios.',
    categoryNewNameOrNewIcon: 'É necessário um novo nome ou um novo ícone.',
    noChangesMade: 'Nenhuma alteração foi feita',
  },
  sorting: {
    articleSort: 'Artigos',
    sectionSort: 'Seções',
    titleForm: 'Ordenação de Artigos e Seções',
    articleEdit: 'Editar Artigos',
    sectionEdit: 'Editar Seções',
    cancelSorting: 'Cancelar Ordenação',
    saveSorting: 'Salvar Ordenação',
    sortingArticlesHasChanged: 'A ordenação foi alterada',
    sortingSectionsHasChanged: 'A ordenação foi alterada',
    unsavedChanges: 'Você possui alterações não salvas',
    sortingHasChanged: 'A ordenação foi alterada',
  },
  article: {
    article: 'Artigo',
    draftModalHeader: 'Conteúdo não salvo.',
    draftModalBody: 'O conteúdo editado não foi salvo.',
    articleSavedOk: 'O artigo está salvo',
    deleteModalHeader: 'Excluir artigo',
    deleteModalBody: 'Tem certeza de que deseja excluir este artigo?',
    deleteModalBodyPerma: 'Tem certeza de que deseja excluir permanentemente este artigo?',
    deleteOk: 'Artigo excluído!',
    addOk: 'Artigo adicionado!',
    sortingSaved: 'Ordenação do artigo salvo',
    addNewArticle: 'Adicionar novo artigo',
    wrongArticleName: 'Nome do artigo inválido',
    notFoundWarning: 'Artigo não encontrado!',
    updateOk: 'O artigo foi atualizado',
    updateFailed: 'Falha na atualização do artigo',
    fileTooLarge: 'Tamanho do arquivo muito grande. Max 10MB',
    protectedArticle: {
      errorMessage: 'Você precisa estar logado para ver este artigo.',
      errorMessageStaff: 'Você não possui acesso a este artigo.',
    },
  },
  category: {
    category: 'Categoria',
    lock: 'Código PIN',
    lockCategoryInputHint:
      'Defina um código PIN de proteção para a categoria. O PIN deve conter entre 4 e 6 números.' +
      'para remover o código PIN deixe o campo em branco',
    lockOk: 'O código PIN da categoria foi definido.',
    lockFail: 'Erro ao definir o código PIN.',
    login: 'Ver conteúdo',
    password: 'Digite o código PIN',
    pinCheckNotValid: 'Código PIN inválido!',
    pinAddNotValid:
      'O código PIN é inválido, ele deve conter entre 4 e 6 números. Para remover o código PIN deixe o campo em branco',
    protectOk: 'A proteção da categoria foi alterada.',
    protectFail: 'A alteração da proteção da categoria falhou.',
    notFoundWarning: 'Categoria não encontrada!',
    updated: 'Categoria atualizada',
    updateError: 'Erro ao atualizar a categoria',
    protectedCategory: {
      chiefErrorMessage: 'Você precisa ser administrador para ter acesso a esses artigos.',
      defaultErrorMessage: 'Você precisa de acesso para ver esses artigos.',
    },
    addCategory: 'Adicionar categoria',
    uploadCategoryIcon:
      'Arraste uma imagem ou pressione “Carregar imagem” para escolher sua própria imagem, ou selecione uma de nossa galeria pressionando “Escolher na Galeria”.',
    categoryNames: {
      html: 'Calculadora',
      changelog: 'Registro de Alterações',
      url: 'Link Externo',
      workorder: 'Módulo de moldagem',
      nativeModule: 'Módulo móvel',
      articleCategory: 'Categoria padrão',
    },
    categoryDescription: {
      articleCategory: 'Uma categoria normal usada para artigos e seções',
      html: 'Uma categoria de calculadora, usada para cálculo de medicamentos; se escolhida, a Softwerk AB entrará em contato com você para configurar a calculadora.',
      changelog:
        'No changelog, as alterações nos artigos são armazenadas, o que é bom para usar junto com o sistema de notificação.',
      url: 'Uma categoria que só funciona em dispositivos móveis, onde o usuário é encaminhado para um URL específico.',
      workorder:
        'No módulo de formulário, os formulários podem ser criados e depois preenchidos pelos usuários. Ótimo para listas de verificação ou formulários comuns que precisam ser preenchidos com frequência.',
      nativeModule: '',
    },
    termsOfUseAddCategory:
      'Condições para adicionar uma nova categoria\n\nAo adicionar uma nova categoria à nossa plataforma, você reconhece e concorda com os seguintes termos:\n\nPreço e cobrança:\n\nUma cobrança adicional de SEK {{value}} sem IVA por ano é adicionada para cada nova categoria adicionada. Esse preço é baseado em nosso modelo de preço atual de acordo com o contrato. O preço da nova categoria é descontado para o primeiro ano com base no número de dias restantes em seu período de assinatura a partir da data em que a categoria é adicionada. O desconto é calculado diariamente e aplicado proporcionalmente durante o primeiro ano para garantir que o preço seja ajustado adequadamente para o próximo período de assinatura.\n\nAo clicar em “Concordo”, você confirma que leu, entendeu e concorda em adicionar uma nova categoria de acordo com os termos acima.',
    chiefLoading: 'Carregamento de categorias principais',
    sortingStarted: 'Salvando categorias classificadas',
    sortingCompleted: 'Categorias classificadas com sucesso',
    sortingError: 'Algo deu errado na classificação, tente novamente',
    editCategoryStarted: 'Salvando a categoria editada',
    editCategorySucces: 'Categoria editada com sucesso',
    editCategoryFailed: 'Falha ao editar a categoria, tente novamente',
    startedCreatingCategory: 'Criação de categoria iniciada',
    categoryIdExists: 'O ID da categoria já existe, tente novamente com outro nome',
    failedCreatingCategory: 'Falha ao criar a categoria, tente novamente',
    successCreatingCategory: 'Categoria criada com sucesso',
  },
  section: {
    deletingAdditionalArticles: 'ATENÇÃO! Os artigos a seguir serão removidos:',
    section: 'Seção',
    defaultSection: 'Seção não definida',
    deleteModalHeader: 'Excluir seção',
    deleteModalBody: 'Tem certeza de que deseja excluir esta seção?',
    deleteModalBodyPerma: 'Tem certeza de que deseja excluir permanentemente esta seção?',
    sectionDeleted: 'Seção excluída!',
    sortingSaved: 'A ordenação da seção foi salva',
    addOk: 'Seção adicionada',
    addError: 'Falha ao adicionar a seção',
    deleteOk: 'Seção excluída!',
    wrongSectionName: 'Noma da seção inválido',
    addNewSection: 'Adicionar nova seção',
  },
  project: {
    notLoaded: 'O projeto não foi carregado corretamente ou não foi encontrado.',
    noStaffAccount: 'Esse projeto não possui conta de equipe',
  },
  forms: {
    placeHolders: { createNewSection: 'Criar nova seção', sectionName: 'Nome da seção', articleName: 'Nome do artigo' },
    messages: { valueToShort: 'O nome escolhido é muito curto.' },
    uploadImage: 'Carregar imagem',
    gallerySelect: 'Escolher na Galeria',
    saveSortingButton: 'Salvar classificação',
  },
  search: {
    relevance: 'Relevância',
    alphabetical: 'Alfabética',
    up: 'do menor para o maior',
    down: 'do maior para o menor',
    allResults: 'Todos os resultados',
    seeAll: 'Mostrando todos os resultados para',
    searchSuggest: 'Sugestões de busca',
    searchError: 'Você não incluiu nenhum termo na pesquisa',
    pageNumberError: 'O número inserido está incorreto',
    resultError: 'Nenhum artigo encontrado de acordo com a sua seleção.',
  },
  versionOutdated: {
    title: 'Uma nova versão está disponível',
    body: 'Algumas funcionalidades podem não funcionar corretamente até enquando a versão não for carregada',
    button: 'Carregar nova versão',
  },
};

/**
 * Localization implemented as a class that just reads from localstorage to support our custom
 * Froala components. They don't work with hooks because they aren't React components.
 * It's a bit messy to have two sort-of parallell localization systems, but it works for now.
 * (See useLanguage in LocalizationContext for regular localization implementation)
 */
export class StaticLocalization {
  strings: LocalizedStrings;

  constructor() {
    let tmpLang: string;
    try {
      tmpLang = JSON.parse(localStorage.getItem('language') || '');
    } catch (error) {
      tmpLang = LanguageCode.Svenska;
    }
    this.setLanguage(tmpLang as LanguageCode);
  }

  private setLanguage = (lang: LanguageCode) => {
    switch (lang) {
      case LanguageCode.English:
        this.strings = en;
        break;
      case LanguageCode.Latviešu:
        this.strings = lv;
        break;
      case LanguageCode.Portuguese:
        this.strings = pt;
        break;
      default:
        this.strings = sv;
        break;
    }
  };
}

export const staticLocalization = new StaticLocalization();
