import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './SettingsSidebar.scss';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { useAuth } from '../../ContextProviders/Auth';

export const SettingsSidebar = (): ReactElement => {
  const localization = useLocalization();
  const project = useConcreteProject();
  const { isAdmin, isSubprojectAdmin } = useAuth();
  const [isToggled, setIsToggled] = useState(true);
  const concreteProject = useConcreteProject();

  return (
    <>
      <aside className={isToggled ? 'sidebar' : 'sidebar closed'}>
        <ul className={'settings-sidebar'}>
          <SidebarItem
            to="/settings/account"
            title={localization.strings.settings.accountMng}
            shouldRender={(project.uniformAuthentication && project.allowAccountCreation) || isAdmin}
          />

          <SidebarItem
            to="/settings/userprofile"
            title={localization.strings.settings.userProfile.title}
            shouldRender={!isAdmin && (project.enableStripe ?? false)}
          />

          <SidebarItem
            to="/settings/subscriptions-configuration"
            title={localization.strings.settings.subscriptions.configuration.title}
            shouldRender={isAdmin && (project.enableStripe ?? false)}
          />

          <SidebarItem
            to="/settings/role-configuration"
            title={localization.strings.settings.roles.settingItemTitle}
            shouldRender={(isAdmin || isSubprojectAdmin) && (project.enableStaffRoleTire ?? false)}
          />

          {project.subprojectsEnabled && project.enableSubprojectsAdmins && (
            <SidebarItem
              to="/settings/manage-subrpoject-admin-permissions"
              title={localization.strings.settings.subprojectAdmin.title}
              shouldRender={isAdmin && !isSubprojectAdmin && !!concreteProject.subprojectsEnabled}
            />
          )}

          <SidebarItem
            to="/settings/subscriptions"
            title={localization.strings.settings.subscriptions.list.title}
            shouldRender={project.enableStripe ?? false}
          />

          <SidebarItem
            to="/settings/staff"
            title={localization.strings.settings.staffAccountMng}
            shouldRender={isAdmin || isSubprojectAdmin}
          />

          <SidebarItem
            to="/settings/access"
            title={localization.strings.settings.access}
            shouldRender={isAdmin || isSubprojectAdmin}
          />

          <SidebarItem
            to="/settings/email"
            title={localization.strings.settings.email}
            shouldRender={isAdmin && !isSubprojectAdmin}
          />

          <SidebarItem
            to="/settings/handbook"
            title={localization.strings.settings.handbook}
            shouldRender={isAdmin || isSubprojectAdmin}
          />

          <SidebarItem
            to="/settings/export"
            title={localization.strings.settings.export.title}
            shouldRender={isAdmin && !isSubprojectAdmin}
          />
          <SidebarItem
            to="/settings/import"
            shouldRender={isAdmin && !isSubprojectAdmin}
            title={localization.strings.settings.import.title}
          />
          <SidebarItem
            to="/settings/subprojects"
            shouldRender={isAdmin && !isSubprojectAdmin && !!concreteProject.subprojectsEnabled}
            title={localization.strings.settings.subprojects.title}
          />
          <SidebarItem
            to="/settings/projectvalues"
            title={localization.strings.settings.projectValues.title}
            shouldRender={isAdmin && !isSubprojectAdmin && !!concreteProject.subprojectsEnabled}
          />
          <SidebarItem
            to="/settings/transfer"
            title={localization.strings.settings.transfer.title}
            shouldRender={isAdmin && !isSubprojectAdmin && !!concreteProject.subprojectsEnabled}
          />
          <SidebarItem
            to="/settings/categories"
            shouldRender={isAdmin && !isSubprojectAdmin}
            title={localization.strings.settings.handleCategories}
          />
          <SidebarItem
            to="/settings/article-transfer"
            shouldRender={isAdmin && !isSubprojectAdmin}
            title={localization.strings.settings.transferArticle.articleTransfer}
          />
        </ul>
      </aside>
      <button className="collapse-button" onClick={() => setIsToggled(!isToggled)}>
        <FontAwesomeIcon icon={isToggled ? faAngleLeft : faAngleRight} />
      </button>
    </>
  );
};

const SidebarItem = ({ to, title, shouldRender }: { to: string; title: string; shouldRender: boolean }) => {
  return shouldRender ? (
    <li>
      <NavLink activeClassName="active" to={to}>
        <span> {title}</span>
      </NavLink>
    </li>
  ) : null;
};
