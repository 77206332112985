import { ReactElement } from 'react';
import { useIsDraft, useProjectConfig } from '../ContextProviders/AppContext';
import { ArticleBodyEdit } from './ArticleBodyEdit';
import { ArticleBodyPublish } from './ArticleBodyPublish';
import './ArticleBody.scss';
import { FabButtonArticle } from '../FabButton/FabButton';
import { useAuth } from '../ContextProviders/Auth';

interface ArticleBodyProps {
  id: string;
  categoryId: string;
}
export const ArticleBody = ({ id, categoryId }: ArticleBodyProps): ReactElement => {
  const [isDraft] = useIsDraft();
  const auth = useAuth();
  const config = useProjectConfig();
  const subprojectAdminCategories = config.doc.categoryEditPermissions ?? [];

  return (
    <div style={{ display: 'flex' }}>
      <FabButtonArticle id={id} />
      {isDraft && auth.isAdmin && !auth.isSubprojectAdmin ? (
        <ArticleBodyEdit id={id} />
      ) : isDraft && auth.isAdmin && auth.isSubprojectAdmin && subprojectAdminCategories?.includes(categoryId) ? (
        <ArticleBodyEdit id={id} />
      ) : (
        <ArticleBodyPublish id={id} key={id} />
      )}
    </div>
  );
};
