import { ReactElement } from 'react';
import { useIsDraft, useProjectConfig } from '../ContextProviders/AppContext';
import { CategoryNavEdit } from './CategorySort/CategoryNavEdit';
import { CategoryNavPublish } from './CategoryNavPublish';
import { useAuth } from '../ContextProviders/Auth';

export const CategoryNav = ({ id }: { id: string }): ReactElement => {
  const auth = useAuth();
  const config = useProjectConfig();
  const subprojectAdminCategories = config.doc.categoryEditPermissions ?? [];

  const [isDraft] = useIsDraft();

  return isDraft && auth.isAdmin && !auth.isSubprojectAdmin ? (
    <CategoryNavEdit id={id} key={id} />
  ) : isDraft && auth.isAdmin && auth.isSubprojectAdmin && subprojectAdminCategories?.includes(id) ? (
    <CategoryNavEdit id={id} key={id} />
  ) : (
    <CategoryNavPublish id={id} key={id} />
  );
};
