import { firestore } from '../typings/firestore';
import { Category } from './models/category';
import { ProjectConfiguration } from './models/project-configuration';
import { Section, SectionFieldPath } from './models/section';
import { Article, ArticleFieldPath } from './models/article';
import { Project } from './models/project';
import { Resource } from './models/resource';
import { LegalDocuments } from './models/legalDocuments';
import { Image } from './models/image';
import { PublishResult, PublishProcess } from './models/publish';
import { Configuration, ConfigurationData } from './models/configuration';
import { ExternalDoc } from './models/external';
import { FeaturesDoc, ChiefCategory } from './models/features';
import { UserRole } from './models';

function draftSuffix(collectionName: string, isDraft: boolean): string {
  return isDraft ? collectionName + 'Draft' : collectionName;
}

const excludeDeletedItemsFilter = (obj: Article | Section): boolean => !obj.data().deleted;

enum Collection {
  Project = 'project',
  Platform = 'platform',
  Resources = 'resources',
  Category = 'category',
  Section = 'section',
  Article = 'article',
  Invocation = 'invocation',
  UploadedImage = 'uploadedImage',
  Configuration = 'configuration',
  EXTERNAL = 'externals',
  Features = '_features',
  AccessRoles = 'accessRoles',
  Chief = 'chief',
  Changelog = 'changelog',
  CMSUser = 'cmsUser',
}

enum Invocation {
  Delete = 'delete',
  PublishProcess = 'publishProcess',
  PinProtect = 'pinProtect',
  ProtectCategory = 'protectCategory',
  ChangeStaffCredentials = 'changeStaffCredentials',
  ChangeAccountCredentials = 'changeAccountCredentials',
}

enum Document {
  SubscribeNotification = 'subscribe-notifications',
}

export class StoreService {
  readonly projectRef: firestore.DocumentReference;
  readonly articleCollectionRef: firestore.CollectionReference;
  readonly categoryCollectionRef: firestore.CollectionReference;
  readonly externalsCollectionRef: firestore.CollectionReference;
  readonly chiefCategoryRef: firestore.CollectionReference;
  readonly chiefSectionRef: firestore.CollectionReference;
  readonly chiefArticleRef: firestore.CollectionReference;
  readonly changelogSectionRef: firestore.CollectionReference;
  readonly changelogArticleRef: firestore.CollectionReference;

  private readonly store: firestore.Firestore;
  private readonly projectID: string;
  private readonly isDraft: boolean;
  private permittedCategories: string[];

  private cache: {
    project?: Project;
    categoryCollection?: Category[];
    externalsCollection?: Category[];
    sectionsCollection?: Section[];
  } = {};

  constructor(store: firestore.Firestore, projectName: string, isDraft = false) {
    this.store = store;
    this.projectID = projectName;
    this.isDraft = isDraft;
    this.projectRef = store.collection(Collection.Project).doc(projectName);
    this.articleCollectionRef = this.projectRef.collection(draftSuffix(Collection.Article, this.isDraft));
    this.categoryCollectionRef = this.projectRef.collection(draftSuffix(Collection.Category, this.isDraft));
    this.externalsCollectionRef = this.projectRef.collection(Collection.EXTERNAL);
    this.chiefCategoryRef = this.projectRef.collection(Collection.Chief).doc('db').collection(Collection.Category);
    this.chiefSectionRef = this.projectRef.collection(Collection.Chief).doc('db').collection(Collection.Section);
    this.chiefArticleRef = this.projectRef.collection(Collection.Chief).doc('db').collection(Collection.Article);
    this.changelogSectionRef = this.projectRef
      .collection(Collection.Changelog)
      .doc('db')
      .collection(Collection.Section);
    this.changelogArticleRef = this.projectRef
      .collection(Collection.Changelog)
      .doc('db')
      .collection(Collection.Article);

    this.listenToCategoryCollection();
    this.listenToExternalsCollection();
    this.listenToSectionCollection();
  }

  private listenToCategoryCollection() {
    if (!this.categoryCollectionRef) {
      console.error('Category collection ref is null or undefined');
      return;
    }

    this.categoryCollectionRef.onSnapshot((snapshot) => {
      const categories = snapshot.docs.map((doc) => {
        if (doc.exists) {
          return doc as Category;
        } else {
          console.error('Category does not exist');
          return null;
        }
      });
      this.cache.categoryCollection = categories;
    });
  }

  private listenToSectionCollection() {
    if (!this.projectRef.collection(draftSuffix(Collection.Section, this.isDraft))) {
      console.error('Section collection ref is null or undefined');
      return;
    }

    this.projectRef.collection(draftSuffix(Collection.Section, this.isDraft)).onSnapshot((snapshot) => {
      const sections = snapshot.docs.map((doc) => {
        if (doc.exists) {
          return doc as Section;
        } else {
          console.error('Section does not exist');
          return null;
        }
      });
      this.cache.sectionsCollection = sections;
    });
  }

  private listenToExternalsCollection() {
    if (!this.externalsCollectionRef) {
      console.error('External collection ref is null or undefined');
      return;
    }

    this.externalsCollectionRef.onSnapshot((snapshot) => {
      const externals = snapshot.docs.map((doc) => {
        if (doc.exists) {
          return doc as Category;
        } else {
          console.error('External does not exist');
          return null;
        }
      });
      this.cache.externalsCollection = externals;
    });
  }

  /**
   *
   * @param userRole
   * @param categoryPermissions
   * @returns {string[]} permittedCategories
   */
  async initPermittedCategories(userRole: UserRole, categoryPermissions: string[]): Promise<string[]> {
    console.log('[@core/StoreService] initPermittedCategories called');
    try {
      const categories = await this.fetchAllCategories();
      if (userRole === UserRole.ADMIN || userRole === UserRole.SUPER_ADMIN) {
        this.permittedCategories = categories.map((c) => c.id);
      } else if (userRole === UserRole.STAFF) {
        this.permittedCategories = categories
          .filter((c) => !c.data().isProtected)
          .map((c) => c.id)
          .concat(categoryPermissions);
      } else if (userRole === UserRole.NONE) {
        this.permittedCategories = categories.filter((c) => !c.data().isProtected).map((c) => c.id);
      } else {
        this.permittedCategories = [];
      }
      return this.permittedCategories;
    } catch (e) {
      console.log('[@core/StoreService] initPermittedCategories error:', e);
      this.permittedCategories = [];
      return this.permittedCategories;
    }
  }

  //
  // Project
  //

  // fetchProjectConfig returns the project configuration, as deployed to firebase using eir-cli deploy configuration.
  async fetchProjectConfig(): Promise<ProjectConfiguration> {
    console.log('[@core/StoreService] fetchProjectConfig called');

    const doc = await this.store.doc('configuration/project').get();
    if (doc.exists) {
      return doc.data() as ProjectConfiguration;
    } else {
      throw Error('Failed to fetch project configurration');
    }
  }

  async fetchProjectFeatures(): Promise<FeaturesDoc> {
    console.log('[@core/StoreService] fetchProjectFeatures called');

    const doc = await this.projectRef.collection(Collection.Features).doc('enabled').get<FeaturesDoc>();
    if (doc === null || !doc.exists) {
      return null;
    }
    return doc;
  }

  async fetchProject(): Promise<Project> {
    console.log('[@core/StoreService] fetchProject called');

    const doc = await this.projectRef.get<Project>();

    if (doc == null || !doc.exists) {
      throw Error('Failed to fetch project');
    }

    return doc;
  }

  async updateProjectConfiguration(config: Partial<ConfigurationData>): Promise<void> {
    console.log('[@core/StoreService] updateProjectConfiguration called');

    return await this.projectRef.collection(Collection.Configuration).doc('draft').update(config);
  }

  // in web we depend on configuration document entity (not on project entity)
  async fetchProjectConfigurationDoc(isDraft: boolean): Promise<Configuration> {
    console.log('[@core/StoreService] fetchProjectConfigurationDoc called');

    return await this.projectRef
      .collection(Collection.Configuration)
      .doc(isDraft ? 'draft' : 'published')
      .get<Configuration>();
  }

  async fetchAllCategories(): Promise<Category[]> {
    console.log('[@core/StoreService] fetchAllCategories called');
    if (this.cache.categoryCollection) {
      return this.cache.categoryCollection;
    }
    const snapshot = await this.categoryCollectionRef.get<Category>();
    this.cache.categoryCollection = snapshot.docs.map((doc) => doc);
    return this.cache.categoryCollection;
  }

  async fetchAllExternals(): Promise<Category[]> {
    console.log('[@core/StoreService] fetchAllExternals called');
    if (this.cache.externalsCollection) {
      return this.cache.externalsCollection;
    }
    const snapshot = await this.externalsCollectionRef.get<Category>();
    this.cache.externalsCollection = snapshot.docs.map((doc) => doc);
    return this.cache.externalsCollection;
  }

  async fetchAllSections(): Promise<Section[]> {
    console.log('[@core/StoreService] fetchAllSections called');
    if (this.cache.sectionsCollection) {
      return this.cache.sectionsCollection;
    }
    const snapshot = await this.projectRef.collection(draftSuffix(Collection.Section, this.isDraft)).get<Section>();
    this.cache.sectionsCollection = snapshot.docs.map((doc) => doc);
    return this.cache.sectionsCollection;
  }

  async fetchAllData(): Promise<void> {
    console.log('[@core/StoreService] fetchAllData called');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fetchProject: Promise<any> = this.fetchProject();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fetchLegalDocuments: Promise<any> = this.fetchLegalDocuments();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fetchProjectMetadata: Array<Promise<any>> = [this.fetchProjectJs(), this.fetchProjectCss()];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fetchPlatformMetadata: Array<Promise<any>> = [this.fetchPlatformJs(), this.fetchPlatformCss()];

    try {
      await Promise.all([
        fetchProject,
        fetchLegalDocuments,
        this.fetchAllCategories(),
        this.fetchAllSections(),
        this.fetchAllArticles(false),
        ...fetchProjectMetadata,
        ...fetchPlatformMetadata,
      ]);
    } catch (e) {
      console.warn('Failed to fetch Firestore data:', e.message);
    }
  }

  async fetchLegalDocuments(): Promise<LegalDocuments> {
    console.log('[@core/StoreService] fetchLegalDocuments called');

    const doc = await this.store.collection(Collection.Platform).doc('legalDocuments').get<LegalDocuments>();

    if (doc == null || !doc.exists) {
      throw Error('Failed to fetch legal documents');
    }

    return doc;
  }

  async fetchProjectCss(): Promise<Resource> {
    console.log('[@core/StoreService] fetchProjectCss called');

    const doc = await this.projectRef.collection(Collection.Resources).doc('CSS').get<Resource>();

    if (doc == null || !doc.exists) {
      throw Error('Failed to fetch project CSS');
    }

    return doc;
  }

  async fetchProjectJs(): Promise<Resource> {
    console.log('[@core/StoreService] fetchProjectJs called');

    const doc = await this.projectRef.collection(Collection.Resources).doc('JS').get<Resource>();

    if (doc == null || !doc.exists) {
      throw Error('Failed to fetch project JS');
    }

    return doc;
  }

  async fetchPlatformJs(): Promise<Resource> {
    console.log('[@core/StoreService] fetchPlatformJs called');

    const doc = await this.store.collection(Collection.Platform).doc('JS').get<Resource>();

    if (doc == null || !doc.exists) {
      throw Error('Failed to fetch platform JS');
    }

    return doc;
  }

  async fetchPlatformCss(): Promise<Resource> {
    console.log('[@core/StoreService] fetchPlatformCss called');

    const doc = await this.store.collection(Collection.Platform).doc('CSS').get<Resource>();

    if (doc == null || !doc.exists) {
      throw Error('Failed to fetch platform CSS');
    }

    return doc;
  }

  chunk(data: string[], chunkSize: number): string[][] {
    console.log('[@core/StoreService] chunk called');

    return Array.from(Array(Math.ceil(data.length / chunkSize))).map((_: unknown, index: number) =>
      data.slice(index * chunkSize, Math.min(data.length, (index + 1) * chunkSize)),
    );
  }

  // For bookmarks
  async fetchChiefArticleList(ids: string[]): Promise<Article[]> {
    console.log('[@core/StoreService] fetchChiefArticleList called');

    const chunks = this.chunk(ids, 10);
    const fetchArticles = chunks.map((chunk) => {
      return this.chiefArticleRef
        .where('__name__', 'in', chunk)
        .get()
        .then((queries) => queries.docs)
        .catch((e) => {
          console.error(e);
          return [];
        });
    });

    return [].concat(...(await Promise.all(fetchArticles)));
  }

  async fetchChangelogArticleList(ids: string[]): Promise<Article[]> {
    console.log('[@core/StoreService] fetchChangelogArticleList called');

    const chunks = this.chunk(ids, 10);
    const fetchArticles = chunks.map(async (chunk) => {
      return this.changelogArticleRef
        .where('__name__', 'in', chunk)
        .get()
        .then((queries) => {
          return queries.docs;
        })
        .catch((e) => {
          console.log(e);
          return [];
        });
    });

    return [].concat(...(await Promise.all(fetchArticles)));
  }

  async fetchArticleList(ids: string[], role: UserRole, permittedCategories: string[]): Promise<Article[]> {
    console.log('[@core/StoreService] fetchArticleList called', role, permittedCategories);

    let chief: Article[] = [];
    let changelog: Article[] = [];

    const regular = (await this.fetchAllArticles()).filter((a) => ids.includes(a.id));
    if (role === UserRole.ADMIN || role === UserRole.SUPER_ADMIN) chief = await this.fetchChiefArticleList(ids);
    if (permittedCategories.includes('changelog')) changelog = await this.fetchChangelogArticleList(ids);
    return regular.concat(chief, changelog);
  }

  async publish(data: PublishProcess, uid: string): Promise<PublishResult> {
    console.log('[@core/StoreService] publish called');

    const all = await this.fetchAllArticles(true);

    // Publish ALL articles that have been modified, based on the precense of the "state" field.
    const articlesToPublish = all.filter((a) => a.data().state).map((a) => a.id);

    const doc = this.store
      .collection(Collection.Invocation)
      .doc(Invocation.PublishProcess)
      .collection('i_project')
      .doc(this.projectID)
      .collection(uid)
      .doc();

    await doc.set({ ...data, articles: articlesToPublish });

    return await doc.get<PublishResult>();
  }

  /**
   * Fetch all articles from permitted categories.
   * @param isPrePublishFetching If true, assumes draft mode is active and user is admin.
   */
  async fetchAllArticles(isPrePublishFetching?: boolean): Promise<Article[]> {
    const collectionPath = this.projectRef.collection(
      draftSuffix(Collection.Article, isPrePublishFetching || this.isDraft),
    );

    if (!isPrePublishFetching) {
      if (this.permittedCategories?.length < 1) return [];

      const ids = [...this.permittedCategories];
      const batches = [];

      while (ids.length > 0) {
        const batch = ids.splice(0, 10);

        batches.push(
          collectionPath
            .where('category', 'in', [...batch])
            .orderBy(ArticleFieldPath.orderIndex)
            .get<Article>()
            .then((query) => query.docs.filter(excludeDeletedItemsFilter)),
        );
      }
      return [].concat(...(await Promise.all(batches)));
    } else {
      return await collectionPath
        .orderBy(ArticleFieldPath.orderIndex)
        .get<Article>()
        .then((query) => query.docs);
    }
  }

  async fetchAllImages(): Promise<Image[]> {
    console.log('[@core/StoreService] fetchAllImages called');

    return (await this.projectRef.collection(Collection.UploadedImage).get<Image>()).docs;
  }

  async fetchFroalaCss(): Promise<Resource> {
    console.log('[@core/StoreService] fetchFroalaCss called');

    const doc = await this.store.collection(Collection.Platform).doc('froalaCSS').get<Resource>();

    if (doc == null || !doc.exists) {
      throw Error('Failed to fetch project CSS');
    }

    return doc;
  }

  async fetchExternal(id: string): Promise<ExternalDoc> {
    console.log('[@core/StoreService] fetchExternal called');

    const doc = await this.projectRef.collection(Collection.EXTERNAL).doc(id).get<ExternalDoc>();

    if (doc == null || !doc.exists) {
      throw Error(`Failed to fetch article '${id}'`);
    }

    return doc;
  }

  async fetchChangelogArticle(id: string): Promise<Article> {
    console.log('[@core/StoreService] fetchChangelogArticle called');

    const doc = await this.changelogArticleRef.doc(id).get<Article>();

    if (doc == null || !doc.exists) {
      throw Error(`Failed to fetch article '${id}'`);
    }

    return doc;
  }

  async fetchChiefArticle(id: string): Promise<Article> {
    console.log('[@core/StoreService] fetchChiefArticle called');

    const doc = await this.chiefArticleRef.doc(id).get<Article>();

    if (doc == null || !doc.exists) {
      throw Error(`Failed to fetch article '${id}'`);
    }

    return doc;
  }

  async fetchArticle(id: string): Promise<Article> {
    console.log('[@core/StoreService] fetchArticle called');

    const doc = await this.projectRef.collection(draftSuffix(Collection.Article, this.isDraft)).doc(id).get<Article>();

    if (doc == null || !doc.exists) {
      throw Error(`Failed to fetch article '${id}'`);
    }

    return doc;
  }

  async fetchCategoryFromRegularArticleId(id: string): Promise<Category> {
    console.log('[@core/StoreService] fetchCategoryFromRegularArticleId called');

    const doc = await this.projectRef
      .collection('aggregatedHelpers')
      .doc('publicArticleData')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .get<any>();

    if (doc == null || !doc.exists) {
      throw Error(`Failed to fetch public article data for '${id}'`);
    }

    const { category: categoryId } = doc.data()[id];

    const doc2 = await this.projectRef
      .collection(draftSuffix(Collection.Category, this.isDraft))
      .doc(categoryId)
      .get<Category>();

    if (doc2 == null || !doc2.exists) {
      throw Error(`Failed to fetch category '${categoryId}'`);
    }

    return doc2;
  }

  async fetchCategory(categoryId: string): Promise<Category> {
    console.log('[@core/StoreService] fetchCategory called');

    const doc = await this.projectRef
      .collection(draftSuffix(Collection.Category, this.isDraft))
      .doc(categoryId)
      .get<Category>();

    if (doc == null || !doc.exists) {
      throw Error(`Failed to fetch category '${categoryId}'`);
    }

    return doc;
  }

  async registerFirebaseCloudMessageToken(token: string): Promise<void> {
    console.log('[@core/StoreService] registerFirebaseCloudMessageToken called');

    const collectionRef = this.store
      .collection(Collection.Invocation)
      .doc(Document.SubscribeNotification)
      .collection(this.projectID);

    return await collectionRef.doc(token).set({});
  }

  async fetchChiefCategoryList(): Promise<ChiefCategory[]> {
    console.log('[@core/StoreService] fetchChiefCategoryList called');

    try {
      const snapshot = await this.chiefCategoryRef.orderBy(SectionFieldPath.orderIndex).get<ChiefCategory>();
      return snapshot.docs;
    } catch (e) {
      return [];
    }
  }

  async fetchAllChiefArticles(): Promise<Article[]> {
    console.log('[@core/StoreService] fetchAllChiefArticles called');

    try {
      const result = await this.chiefArticleRef.orderBy(ArticleFieldPath.orderIndex).get<Article>();
      return result.docs;
    } catch (e) {
      return [];
    }
  }

  async fetchAllChangelogArticles(): Promise<Article[]> {
    console.log('[@core/StoreService] fetchAllChangelogArticles called');

    try {
      const result = await this.changelogArticleRef.orderBy(ArticleFieldPath.orderIndex).get<Article>();
      return result.docs;
    } catch (e) {
      return [];
    }
  }

  async fetchChiefCategory(categoryId: string): Promise<ChiefCategory> {
    console.log('[@core/StoreService] fetchChiefCategory called');

    const doc = await this.chiefCategoryRef.doc(categoryId).get<ChiefCategory>();
    if (doc == null || !doc.exists) {
      throw Error(`Failed to fetch category '${categoryId}'`);
    }
    return doc;
  }

  async fetchChiefSectionsByCategoryId(categoryId: string): Promise<Section[]> {
    console.log('[@core/StoreService] fetchChiefSectionsByCategoryId called');

    const result = await this.chiefSectionRef
      .where(SectionFieldPath.category, '==', categoryId)
      .orderBy(SectionFieldPath.orderIndex)
      .get<Section>();
    return result.docs;
  }

  async fetchOrphanArticlesByCategoryId(categoryId: string): Promise<Article[]> {
    console.log('[@core/StoreService] fetchOrphanArticlesByCategoryId called');

    try {
      const result = await this.chiefArticleRef
        .where(ArticleFieldPath.Category, '==', categoryId)
        .where(ArticleFieldPath.Section, '==', null)
        .orderBy(ArticleFieldPath.orderIndex)
        .get<Article>();
      return result.docs;
    } catch (e) {
      return [];
    }
  }

  async fetchChangelogSectionsByCategoryId(categoryId: string): Promise<Section[]> {
    console.log('[@core/StoreService] fetchChangelogSectionsByCategoryId called');

    const result = await this.changelogSectionRef
      .where(SectionFieldPath.category, '==', categoryId)
      .orderBy(SectionFieldPath.orderIndex)
      .get<Section>();
    return result.docs;
  }

  async fetchOrphanChangelogArticlesByCategoryId(categoryId: string): Promise<Article[]> {
    console.log('[@core/StoreService] fetchOrphanChangelogArticlesByCategoryId called');

    try {
      const result = await this.changelogArticleRef
        .where(ArticleFieldPath.Category, '==', categoryId)
        .where(ArticleFieldPath.Section, '==', null)
        .orderBy(ArticleFieldPath.orderIndex)
        .get<Article>();
      return result.docs;
    } catch (e) {
      return [];
    }
  }

  async fetchSectionsInCategory(categoryId: string): Promise<Section[]> {
    console.log('[@core/StoreService] fetchSectionsInCategory called');

    const result = await this.projectRef
      .collection(draftSuffix(Collection.Section, this.isDraft))
      .where(SectionFieldPath.category, '==', categoryId)
      .orderBy(SectionFieldPath.orderIndex)
      .get<Section>();

    return result.docs.filter(excludeDeletedItemsFilter);
  }

  async fetchArticlesInCategory(categoryId: string): Promise<Article[]> {
    console.log('[@core/StoreService] fetchArticlesInCategory called');

    const querySnapshot = await this.projectRef
      .collection(draftSuffix(Collection.Article, this.isDraft))
      .where(ArticleFieldPath.Category, '==', categoryId)
      .where(ArticleFieldPath.Section, '==', null)
      .orderBy(ArticleFieldPath.orderIndex)
      .get<Article>();

    return querySnapshot.docs.filter(excludeDeletedItemsFilter);
  }

  async fetchChiefArticlesBySectionId(sectionId: string | null | undefined): Promise<Article[]> {
    console.log('[@core/StoreService] fetchChiefArticlesBySectionId called');

    try {
      const result = await this.chiefArticleRef
        .where(ArticleFieldPath.Section, '==', sectionId)
        .orderBy(ArticleFieldPath.orderIndex)
        .get<Article>();
      return result.docs;
    } catch (e) {
      return [];
    }
  }

  async fetchChangelogArticlesBySectionId(sectionId: string | null | undefined): Promise<Article[]> {
    console.log('[@core/StoreService] fetchChangelogArticlesBySectionId called');

    try {
      const result = await this.changelogArticleRef
        .where(ArticleFieldPath.Section, '==', sectionId)
        .orderBy(ArticleFieldPath.orderIndex)
        .get<Article>();
      return result.docs;
    } catch (e) {
      return [];
    }
  }

  async fetchArticleListBySection(sectionId: string | null | undefined): Promise<Article[]> {
    if (this.permittedCategories?.length < 1) return [];

    const collectionPath = this.projectRef.collection(draftSuffix(Collection.Article, this.isDraft));
    const ids = [...this.permittedCategories];
    const batches = [];

    while (ids.length > 0) {
      const batch = ids.splice(0, 10);

      batches.push(
        collectionPath
          .where(ArticleFieldPath.Section, '==', sectionId)
          .where('category', 'in', [...batch])
          .orderBy(ArticleFieldPath.orderIndex)
          .get<Article>()
          .then((query) => query.docs.filter(excludeDeletedItemsFilter)),
      );
    }
    return [].concat(...(await Promise.all(batches)));
  }

  async fetchCMSUsers(): Promise<firestore.DocumentSnapshot[]> {
    return (await this.store.collection(Collection.CMSUser).get()).docs;
  }
}
