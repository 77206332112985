import React, { useContext, useEffect, useState } from 'react';
import { SubprojectContext, useCategoriesDraft, useProjectConfig } from '../../ContextProviders/AppContext';
import { PageWithSidebar } from '../PageTypes';
import { CategoryLocker } from './CategoryLocker';
import { SettingsSidebar } from './SettingsSidebar';
import { sortByOrderIndex } from '../../../util/DataHelpers';
import { Category } from '../../../Types';
import { ErrorPage } from '../Error/ErrorPage';
import { Loading } from '../Loading/Loading';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { useFirestore } from '../../ContextProviders/Firebase';
import { useLocalization } from '../../ContextProviders/LocalizationContext';

const SubAdminPermissions = () => {
  const { docs: categories, error, loading } = useCategoriesDraft();
  const activeSubproject = useContext(SubprojectContext);
  const config = useProjectConfig();
  const firestore = useFirestore();
  const localization = useLocalization();

  const [btnsAreDisabled, setBtnsAreDisabled] = useState(false);
  const [buttonLoadingStates, setButtonLoadingStates] = useState<boolean[]>(Array(categories.length).fill(false));
  const [currentCatEditPermissions, setCurrentCatEditPermissions] = useState<string[] | undefined>();

  useEffect(() => {
    setButtonLoadingStates(Array(categories.length).fill(false));
    setBtnsAreDisabled(false);
  }, [categories]);

  useEffect(() => {
    setCurrentCatEditPermissions(config.doc.categoryEditPermissions ?? []);
  }, [config.doc.categoryEditPermissions]);

  const setLoadingForButton = (id: number, state: boolean) => {
    const newLoadStates = [...buttonLoadingStates];
    newLoadStates[id] = state;
    setButtonLoadingStates(newLoadStates);
  };

  const onToggle = async (index: number, category: Category) => {
    setBtnsAreDisabled(true);
    setLoadingForButton(index, true);
    let temp = currentCatEditPermissions;

    if (!temp) {
      temp = [category.fId];
    } else if (temp.includes(category.fId)) {
      temp = temp.filter((el) => el !== category.fId);
    } else {
      temp.push(category.fId);
    }

    try {
      await updateDoc(doc(firestore, `project/${activeSubproject.id}`), {
        categoryEditPermissions: temp,
        lastUpdated: serverTimestamp(),
      });

      await updateDoc(doc(firestore, `project/${activeSubproject.id}/configuration/draft`), {
        categoryEditPermissions: temp,
        lastUpdated: serverTimestamp(),
      });
      await updateDoc(doc(firestore, `project/${activeSubproject.id}/configuration/published`), {
        categoryEditPermissions: temp,
        lastUpdated: serverTimestamp(),
      });
      setBtnsAreDisabled(false);
      setLoadingForButton(index, false);
    } catch (exception) {
      setBtnsAreDisabled(false);
      setLoadingForButton(index, false);
    }
  };

  if (error) return <ErrorPage error={error} />;
  if (loading) return <Loading waitingFor={'Waiting for categories'} />;

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.subprojectAdmin.title}</h1>
          <p style={{ color: 'var(--grayscale-2)' }}>{localization.strings.settings.subprojectAdmin.hint}</p>
          <hr />
          <div className="categories shadow-sm acc-mng-module">
            {[...categories].sort(sortByOrderIndex).map((c, index) => (
              <React.Fragment key={c.fId}>
                {'content' in c ? (
                  <div className="name-with-sub">
                    <div>{c.name}</div>
                    {'content' in c && <div className="sub">{'External Ci=ontent'}</div>}
                  </div>
                ) : (
                  <div className="name">
                    <div>{c.name}</div>
                  </div>
                )}
                <div className="toggle">
                  <CategoryLocker
                    canRead={currentCatEditPermissions ? currentCatEditPermissions?.includes(c.fId) : false}
                    isDisabled={btnsAreDisabled}
                    isLoading={buttonLoadingStates[index]}
                    //   eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onToggle={() => onToggle(index, c)}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </main>
    </PageWithSidebar>
  );
};

export default SubAdminPermissions;
