import { firestore } from '../../typings/firestore';

export interface CMSUser extends firestore.DocumentSnapshot {
  data: () => CMSUserData;
}

export enum UserRole {
  SUPER_ADMIN = 'superadmin',
  ADMIN = 'admin',
  STAFF = 'staff',
  NONE = 'none',
  SUBPROJECT_ADMIN = 'subprojectAdmin',
}

export type InvitationPayload = {
  email: string;
  role: UserRole;
  projectId: string;
  status: InvitationStatus;
  invitationUrl: string;
  roleTire: string | null;
  sender: string;
};

export enum InvitationStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
}

export interface CMSUserData {
  role: UserRole;
  email: string;
  categoryPermissions: string[];
  displayName: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  projectId?: string;
  status?: InvitationStatus;
  createdOn?: firestore.FieldValue | null;
  roleTire?: string | null;
}

export type NewCategoryPayload = {
  email: string;
  emailBody: string;
  project: string;
  recipientList: string;
};
