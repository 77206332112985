import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  SubprojectContext,
  useCategoriesDraft,
  useExternals,
  useProjectConfig,
} from '../../ContextProviders/AppContext';
import { ErrorPage } from '../Error/ErrorPage';
import { Loading } from '../Loading/Loading';
import { PageWithSidebar } from '../PageTypes';
import { SettingsSidebar } from './SettingsSidebar';
import { useMonofunction } from '../../ContextProviders/Firebase';
import { CategoryLocker } from './CategoryLocker';
import { sortByOrderIndex } from '../../../util/DataHelpers';
import { toasts } from '../../../shared';
import { Category, ExternalContent } from '../../../Types';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { useProjectTitle } from '../../ContextProviders/ProjectContext';
import { useAuth } from '../../ContextProviders/Auth';

export const AccessManagement = (): ReactElement => {
  const localization = useLocalization();
  const { docs: categories, error, loading } = useCategoriesDraft();
  const { docs: externals, error: errorExternals, loading: loadingExternals } = useExternals();
  const firebaseFunctions = useMonofunction();
  const title = useProjectTitle();
  const activeSubproject = useContext(SubprojectContext);
  const auth = useAuth();
  const config = useProjectConfig();
  const subprojectAdminCategories = config.doc.categoryEditPermissions ?? [];
  const allCategories = [...categories, ...externals].filter((cat) =>
    auth.isSubprojectAdmin ? subprojectAdminCategories?.includes(cat.fId) : cat,
  );

  const [buttonLoadingStates, setButtonLoadingStates] = useState<boolean[]>(
    Array(categories.length + externals.length).fill(false),
  );
  const [btnsAreDisabled, setBtnsAreDisabled] = useState(false);

  useEffect(() => {
    document.title = `${localization.strings.settings.access} | ${title}`;
  }, [title, localization.strings.settings.access]);

  useEffect(() => {
    setButtonLoadingStates(Array(categories.length + externals.length).fill(false));
    setBtnsAreDisabled(false);
  }, [categories, externals]);

  if (error) return <ErrorPage error={error} />;
  else if (errorExternals) return <ErrorPage error={errorExternals} />;
  if (loading) return <Loading waitingFor={localization.strings.settings.categories} />;
  else if (loadingExternals) <Loading waitingFor={localization.strings.external.externalContent} />;

  const setLoadingForButton = (id: number, state: boolean) => {
    const newLoadStates = [...buttonLoadingStates];
    newLoadStates[id] = state;
    setButtonLoadingStates(newLoadStates);
  };
  const onToggle = (index: number, category: Category | ExternalContent) => {
    setBtnsAreDisabled(true);
    setLoadingForButton(index, true);
    firebaseFunctions
      .SetCategoryProtection({
        categoryId: category.fId,
        isProtected: !category.isProtected,
        isExternal: 'content' in category,
        projectId: activeSubproject.id !== null ? activeSubproject.id : activeSubproject.main,
      })
      .catch((err) => {
        setBtnsAreDisabled(false);
        setLoadingForButton(index, false);
        console.log(err);
        toasts.error(localization.strings.error.unknown);
      });
  };

  return (
    <PageWithSidebar>
      <SettingsSidebar />
      <main className="padded-container">
        <div className="settings-page">
          <h1>{localization.strings.settings.access}</h1>
          <p style={{ color: 'var(--grayscale-2)' }}>{localization.strings.settings.categoryAccessHint}</p>
          <hr />
          <div className="categories shadow-sm acc-mng-module">
            {allCategories.sort(sortByOrderIndex).map((c, index) => (
              <React.Fragment key={c.fId}>
                {'content' in c ? (
                  <div className="name-with-sub">
                    <div>{c.name}</div>
                    {'content' in c && <div className="sub">{localization.strings.external.externalContent}</div>}
                  </div>
                ) : (
                  <div className="name">
                    <div>{c.name}</div>
                  </div>
                )}
                <div className="toggle">
                  <CategoryLocker
                    canRead={!c.isProtected}
                    isDisabled={btnsAreDisabled}
                    isLoading={buttonLoadingStates[index]}
                    onToggle={() => onToggle(index, c)}
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      </main>
    </PageWithSidebar>
  );
};
